import React, { useState, FC, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
} from "@mui/material";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import useReleaseNotesLogic from "../../lookup/release-notes/release-notes.logic";
import { useDecodeJWT } from "../../hook/use-decodeJWT";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

// Define the prop types for the MyDialog component
interface MyDialogProps {
  open: boolean;
  handleClose: () => void;
}

const ReleaseNotesDialog: FC<MyDialogProps> = ({ open, handleClose }) => {
  const { releaseNotes, updateReleaseNotes, releaseNotesContents } =
    useReleaseNotesLogic();
  const token = useDecodeJWT();
  const [releaseNotesData, setReleaseNotesData] = useState<any>();
  const [releaseContentsNotesData, setReleaseContentsNotesData] =
    useState<any>();

  const getReleaseNots = async () => {
    try {
      const param = {
        isPopup: false,
      };

      const response = await releaseNotes(param);
      if (response.data && response.data.data) {
        setReleaseNotesData(response.data.data);
        updateReleaseNotesData(response.data.data);
      }
    } catch (err) {
      setReleaseNotesData([]);
    }
  };

  function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  const processReleaseNoteImages = (
    imageContents: any[],
    releaseNote: string
  ) => {
    let updatedReleaseNote = releaseNote;

    imageContents.forEach((image) => {
      const { imageName, imageContent } = image;

      // Convert base64 content to a proper data URL
      const base64Image = `data:image/png;base64,${imageContent}`;

      // Replace the image src in releaseNote HTML content
      const escapedImageName = escapeRegExp(imageName);
      updatedReleaseNote = updatedReleaseNote.replace(
        new RegExp(escapedImageName, "g"),
        base64Image
      );
    });

    return updatedReleaseNote;
  };

  const getReleaseNotesContents = async (techRelNoteGUID: string) => {
    try {
      const param = {
        techRelNoteGUID: techRelNoteGUID,
      };

      const response = await releaseNotesContents(param);
      if (response.data && response.data.data) {
        const _notesContents = response.data.data;

        if (_notesContents?.imageContents) {
          const updatedData = processReleaseNoteImages(
            _notesContents?.imageContents,
            _notesContents?.releaseNote
          );

          setReleaseContentsNotesData(updatedData);
        } else {
          setReleaseContentsNotesData(_notesContents?.releaseNote);
        }
      }
    } catch (err) {
      setReleaseContentsNotesData([]);
    }
  };

  useEffect(() => {
    if (open) {
      getReleaseNots();
    }
    // eslint-disable-next-line
  }, [open]);

  const updateReleaseNotesData = async (_data: any) => {
    try {
      const param = {
        userId: Number(token.UserId),
        validFrom: _data?.validFrom,
        validTo: _data?.validTo,
      };

      const response = await updateReleaseNotes(param);
      if (response.data && response.data.data) {
      }
    } catch (err) {}
  };

  const [expanded, setExpanded] = useState<number | false>(false);

  const handleAccordionChange = (index: number, techRelNoteGUID: string) => {
    setReleaseContentsNotesData(null);
    setExpanded((prevIndex) => (prevIndex === index ? false : index));
    getReleaseNotesContents(techRelNoteGUID);
  };

  return (
    <Dialog
      className="release-popup"
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      sx={{
        height: "95%",
        width: { xs: "100%", sm: "60%" }, // 100% width for xs, 60% for sm and above
        display: "table",
        margin: "0 auto",
      }}
    >
      <DialogTitle
        className="Popup-header"
        id="responsive-dialog-title-notes"
        sx={{ marginBottom: "24px", paddingLeft: "16px" }}
      >
        {releaseNotesData?.title}
        {releaseNotesData?.description
          ? ` - ${releaseNotesData?.description}`
          : ""}
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          id="btnCloseReleaseNotesTop"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="Dialog-content">
        <div
          style={{
            height: "60vh",
            margin: "0 auto",
            width: "100%",
            overflowY: "auto",
          }}
        >
          {releaseNotesData?.releaseNoteDescriptionItems?.map(
            (_item: any, index: number) => (
              <Accordion
                className="accordion-section"
                key={_item?.techRelNoteGUID}
                expanded={expanded === index}
                onChange={() =>
                  handleAccordionChange(index, _item?.techRelNoteGUID)
                }
                sx={{ marginBottom: "10px" }}
              >
                <AccordionSummary
                  className="accordion-subsection"
                  expandIcon={<ArrowDropDownIcon />}
                  aria-controls={`panel-content-${index}`}
                  id={`panel-header-${index}`}
                >
                  <Typography variant="h6">{_item?.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {releaseContentsNotesData && (
                    <Typography
                      className="accordion-info"
                      dangerouslySetInnerHTML={{
                        __html: `<section>${releaseContentsNotesData}</section>`,
                      }}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            )
          )}
        </div>
      </DialogContent>
      <DialogActions
        sx={{ borderTop: "1px solid var(--divider, rgba(0, 0, 0, 0.12))" }}
      >
        <Button
          variant="contained"
          color="primary"
          className="d-flex rounded rate_btn mtb4"
          id="btnCloseReleaseNotes"
          onClick={handleClose}
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReleaseNotesDialog;
