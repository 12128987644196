import { Box, CircularProgress, Stack } from "@mui/material";
import status1 from "../../../assets/images/routepreview/status-1.svg";
import status2 from "../../../assets/images/routepreview/status-2.svg";
import status3 from "../../../assets/images/routepreview/status-3.svg";
import status4 from "../../../assets/images/routepreview/status-4.svg";
import status5 from "../../../assets/images/routepreview/status-5.svg";
import status6 from "../../../assets/images/routepreview/status-6.svg";
import status7 from "../../../assets/images/routepreview/status-7.svg";
import React, { useEffect, useState } from "react";

interface FlairLoadingProps {
  isLoading: boolean;
  serviceID?:number;
}

const loadingStates = [
  { image: status1, text: "Reviewing Order Details" },
  { image: status2, text: "Searching Available Flights" },
  { image: status3, text: "Locating Nearby Airports" },
  { image: status4, text: "Identifying Airline Preferences" },
  { image: status5, text: "Identifying Airline Station Hours" },
  { image: status6, text: "Analyzing Drive Times & Traffic" },
  { image: status7, text: "Analyzing Weather Patterns" },
];

const loadingStatesGround = [
  { image: status1, text: "Reviewing Order Details" },
  { image: status6, text: "Analyzing Drive Times & Traffic" },
  { image: status7, text: "Analyzing Weather Patterns" },
];

const FlairLoading: React.FC<FlairLoadingProps> = ({ isLoading,serviceID }) => {
  const [currentStateIndex, setCurrentStateIndex] = useState(0);
  const [preloadedImages, setPreloadedImages] = useState<HTMLImageElement[]>([]);
  const filteredLoadingStates = serviceID === 1007 ? loadingStatesGround : loadingStates;

// Preload images and store them in the state
useEffect(() => {
  const images = filteredLoadingStates.map((state) => {
    const img = new Image();
    img.src = state.image;
    return img;
  });
  setPreloadedImages(images);
  if (currentStateIndex >= filteredLoadingStates.length) {
    setCurrentStateIndex(0);
  }
}, [filteredLoadingStates,currentStateIndex]);

  useEffect(() => {
    if (!isLoading || preloadedImages.length !== filteredLoadingStates.length)
      return; // Stop rotation if not loading

    const intervalId = setInterval(() => {
      setCurrentStateIndex((prevIndex) =>
        prevIndex === filteredLoadingStates.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // 3 seconds per loading state

    return () => clearInterval(intervalId); // Cleanup on component unmount or when loading stops
  }, [isLoading, preloadedImages]);

  if (!isLoading || preloadedImages.length !== filteredLoadingStates.length) return null; // Render nothing when isLoading is false

  const { image="", text="" } = filteredLoadingStates[currentStateIndex] || {};

  return (
    <Box className="d-flex flex-column align-items-center Map-view p16 Loader-section">
      <Stack className="loader-icon" sx={{ color: "grey.500" }} spacing={2} direction="row">
        <CircularProgress color="inherit" />
      </Stack>
      <Box className="mt-16 loadtertxt d-flex align-items-center">
        <img className="mr-8" src={image} alt={text} />
        <span>{text}</span>
      </Box>
      <p className="loader_dtl">
        We’re building the best route for your order.
      </p>
    </Box>
  );
};

export default FlairLoading;
