import React, { useEffect, useState } from "react";
import { TextField, Button, Box, Typography, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo/mxweb-logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import LoadingButton from "@mui/lab/LoadingButton";
import useSupportSignupLogic from "./support-sign-in.logic";
import useUserInfoLogic from "../../shared/auth/user-info/user-info.logic";
import Loader from "../../shared/util/loader";

const SupportSignIn: React.FC = () => {
  const [customerUserID, setCustomerUserID] = useState<any>();

  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { supportSignupError, getMicrosoftLoginSupportURL, azureSupportLogin } =
    useSupportSignupLogic();
  const [submitError, setSubmitError] = useState<string>("");
  const { userInfo } = useUserInfoLogic();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const code = urlParams.get("code");
    const state = urlParams.get("state");

    if (code && state) {
      handleAzureLogin(code, state);
    }
    // eslint-disable-next-line
  }, []);

  const getUserInfo = async () => {
    try {
      const response = await userInfo();
      if (response.data && response.data.data) {
        const res = response.data.data;
        if (res.userGroupGUID) {
          localStorage.setItem("userGroupGUID", res.userGroupGUID);
        }
        localStorage.setItem("officeID", res.officeID);
        localStorage.setItem("serviceAvailType", res.serviceAvailType);
        localStorage.setItem("userEmail", res.emailAddress);
        let roles = [];
        localStorage.setItem("userEmail", res.emailAddress);
        localStorage.setItem("UseOrderNumAsTrackNum", res.shpUseOrderNumAsTrackNum);
        localStorage.setItem("orderFormType", res.orderFormType);

        if (res.roles && Array.isArray(res.roles)) {
          roles = res.roles.map((role: { roleKey: string }) => role.roleKey); 
        }
        if (res.isAdministrator) {
          roles.push("admin");
        }
        localStorage.setItem("roles", JSON.stringify(roles));

        if(roles.length > 0)
        {
          navigate("/active-orders");
        }
        else
        {
          navigate("/unAuthorized");
        }

      }
    } catch {}
  };

  const handleAzureLogin = async (code: string, state: string) => {
    try {
      setIsLoading(true);
      const response = await azureSupportLogin({
        code: code,
        state: state,
      });
      if (response) {
        if (response.data && response.data.success) {
          getUserInfo();
          setSubmitError("");
        } else if (response.message) {
          setSubmitError(response.message);
        }
      } else if (supportSignupError) {
        setSubmitError(supportSignupError);
      }
      setIsLoading(false);
    } catch (err: any) {
      setSubmitError(err);
      // setTimeout(() => {
      //   setErrorAlert(false);
      // }, 5000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSubmitError("");
    setCustomerUserID(e.target.value);
  };

  const navigate = useNavigate();

  const handlePrivacyStatementClick = () => {
    navigate("/privacy-statement");
  };

  const validate = () => {
    let errors = "";
    let isValid = true;

    if (!customerUserID) {
      errors = "You are missing required parameters.";
      isValid = false;
    }
    setSubmitError(errors);
    return isValid;
  };

  const handleSupportLogin = async () => {
    try {
      if (validate()) {
        setIsLoadingBtn(true);
        const response = await getMicrosoftLoginSupportURL(customerUserID);
        if (response && response.data) {
          if (response.data.success) {
            window.location.href = response.data.data;
            setSubmitError("");
            return;
          } else if (response.message) {
            setSubmitError(response.message);
          }
        } else if (supportSignupError) {
          setSubmitError(supportSignupError);
        }
      }
      //setIsLoadingBtn(false);
    } catch (err: any) {
      setSubmitError(err);
      // setTimeout(() => {
      //   setErrorAlert(false);
      // }, 5000);
    } finally {
      //setIsLoadingBtn(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Box className="d-flex justify-content-center align-items-center vh-100">
          <Loader />
        </Box>
      ) : (
        <div className="mlr-16">
          <Box className="login-section" maxWidth="sm" sx={{ mt: 4 }}>
            <Box className="lognin-image">
              <img src={logo} alt="Company Logo" style={{ maxWidth: "100%" }} />
            </Box>
            <Box className="Forgot-password-form-border">
              <Typography
                variant="h4"
                component="h1"
                gutterBottom
                textAlign="start"
                className="sign-in-title"
              >
                Support Sign in
              </Typography>
              <Box className="p16">
                {submitError && (
                  <Box className="mb-3">
                    <Alert variant="filled" severity="error" id="txtSsiError">
                      {submitError}
                    </Alert>
                  </Box>
                )}
                <TextField
                  id="txtCustomerUserID"
                  name="customerUserID"
                  label="Customer User ID"
                  variant="outlined"
                  className="mb-16 mt-0"
                  value={customerUserID}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
                <LoadingButton
                  className="signin_btn"
                  type="submit"
                  variant="contained"
                  color="primary"
                  id="btnSignInWithMnx"
                  fullWidth
                  onClick={handleSupportLogin}
                  loading={isLoadingBtn}
                >
                  SIGN IN WITH MNX
                </LoadingButton>
              </Box>
            </Box>

            <Box>
              <Box
                className="Privacy_info Signin_btn"
                maxWidth="sm"
                sx={{ display: "flex", gap: "64px", width: "100%" }}
              >
                <Button
                  className="text"
                  onClick={handlePrivacyStatementClick}
                  style={{ whiteSpace: "nowrap" }}
                  id="btnPrivacyStatement"
                >
                  Privacy Statement
                </Button>
                <Button
                  className="text-custom"
                  onClick={() => navigate("/")}
                  style={{ whiteSpace: "nowrap" }}
                  id="btnBackToSignIn"
                >
                  Back to sign in
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      )}
    </>
  );
};

export default SupportSignIn;
