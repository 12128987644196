import React, { useState, FC, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  Paper,
  Box,
  TableRow,
  Radio,
  FormControlLabel,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../util/loader";
import useRateEstimateLogic from "../../../modules/rate-estimate/rate-estimate.logic";
import { sortAndUpdateTheData } from "../../util/rate-estimate";
import { MAX_COMPANY_ID } from "../../../config/constants";
import EnhancedTableHead from "../table-head-cell/enhanced-table-head";
import { formatDate } from "../../util/date-utils";
import { formatDateTZ } from "../../util/date-tz-utils";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import moment from "moment-timezone";
// Define the prop types for the MyDialog component
interface MyDialogProps {
  open: boolean;
  handleClose: () => void;
  formik: any;
  handleCalculate: () => void;
}

type Order = "desc" | "asc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof any;
  label: string;
  numeric: boolean;
  minWidth: string;
}

const headCells: HeadCell[] = [
  {
    id: "carrierName",
    numeric: true,
    disablePadding: false,
    label: "AIRLINE",
    minWidth: "75",
  },
  {
    id: "departTimeAirportDisplay",
    numeric: true,
    disablePadding: false,
    label: "DEPARTURE",
    minWidth: "130",
  },
  {
    id: "arriveTimeAirportDisplay",
    numeric: true,
    disablePadding: false,
    label: "ARRIVAL",
    minWidth: "130",
  },
  {
    id: "travelTimeDisplay",
    numeric: true,
    disablePadding: false,
    label: "TRAVEL TIME",
    minWidth: "101",
  },
  {
    id: "stops",
    numeric: true,
    disablePadding: false,
    label: "STOPS",
    minWidth: "103",
  },
  {
    id: "estimatedDeliverTimeDisplay",
    numeric: true,
    disablePadding: false,
    label: "EDT",
    minWidth: "133",
  },
];

const RateEstimateRouteDialog: FC<MyDialogProps> = ({
  open,
  handleClose,
  formik,
  handleCalculate,
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState("estimatedDeliverTimeDisplay");
  const notData = "No data to display";
  const [isLoading, setIsLoading] = useState(false);

  const { flightOptions } = useRateEstimateLogic();
  const [selectedFlight, setSelectedFlight] = useState<any>(null);
  const [flightOptionsData, setFlightOptionsData] = useState<any[]>([]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof any
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property as any);
  };

  useEffect(() => {
    //soth data
    const sortedData = sortAndUpdateTheData(flightOptionsData, order, orderBy);

    setFlightOptionsData(sortedData);
    // eslint-disable-next-line
  }, [order, orderBy]);
  const getFlightOptions = async () => {
    try {
      setIsLoading(true);
      const {
        pickupDropAirportID,
        pickupCountryID,
        pickupTimezoneID,
        deliveryCountryID,
        deliveryDropAirportID,
        pickupReadyTime,
        serviceID,
        deliveryDistance,
        deliveryDistanceUnit,
      } = formik.values;
      const param = {
        puCountryID: pickupCountryID || "",
        puAirportCode: pickupDropAirportID || "",
        puTimezoneID: pickupTimezoneID || "",
        delCountryID: deliveryCountryID || "",
        delAirportCode: deliveryDropAirportID || "",
        departAfter: pickupReadyTime || "",
        deliveryDistValue: deliveryDistance ? parseInt(deliveryDistance) : null,
        deliveryDistanceUOM: deliveryDistanceUnit || "",
        serviceID: parseInt(serviceID) || 0,
        companyID: MAX_COMPANY_ID,
        isCalculateClicked: false,
      };

      const response = await flightOptions(param);
      if (response.data && response.data.data) {
        const sortedData = sortAndUpdateTheData(
          response.data.data,
          order,
          orderBy
        );

        setFlightOptionsData(sortedData);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setFlightOptionsData([]);
    }
  };
  useEffect(() => {
    if (open) {
      getFlightOptions();
    }
    // eslint-disable-next-line
  }, [open, formik]);

  // Find the row with the lowest travel time
  const minTravelTimeRow = flightOptionsData.reduce((minRow, currentRow) => {
    return currentRow.travelTime < minRow.travelTime ? currentRow : minRow;
  }, flightOptionsData[0]);

  const handleFlightChange = (event: any) => {
    const slFlightData = flightOptionsData.find(
      (_item) => _item?.routeGUID === event.target.value
    );
    setSelectedFlight(slFlightData);
  };
  useEffect(() => {
    const slFlightData = flightOptionsData.find(
      (_item) => _item?.routeGUID === minTravelTimeRow?.routeGUID
    );
    setSelectedFlight(slFlightData);
    if (slFlightData) {
      // formik.setFieldValue(
      //   "requestedDeliveryTime",
      //   moment(slFlightData?.estimatedDeliveryDateTime).format(
      //     "YYYY-MM-DDTHH:mm:ss"
      //   )
      // );
    }
  }, [minTravelTimeRow]);
  return (
    <Dialog
      maxWidth={"xl"}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      style={{ height: "95%" }}
    >
      <DialogTitle className="Popup-header" id="responsive-dialog-title">
        Flight Options
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          id="btnRouteDialogClose"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="Dialog-content">
        <div className="code-form">
          <>
            <Box sx={{ width: "100%" }}>
              <Paper
                className="ActiveOrder"
                sx={{ width: "100%", mb: 0, display: "block!important" }}
              >
                <TableContainer className="addresslookup-grid">
                  <Table aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={flightOptionsData.length}
                      headCells={headCells}
                      isAllowEmptyCell={true}
                    />
                    <TableBody>
                      {isLoading ? (
                        <TableRow>
                          <TableCell className="" colSpan={12} align="center">
                            <Loader />
                          </TableCell>
                        </TableRow>
                      ) : flightOptionsData.length === 0 ? (
                        <TableRow className="Nodata_found">
                          <TableCell className="" colSpan={12} align="center">
                            {notData}
                          </TableCell>
                        </TableRow>
                      ) : (
                        flightOptionsData.map((row: any, index: any) => {
                          return (
                            <TableRow
                              hover
                              key={row.addressGUID}
                              className={
                                // eslint-disable-next-line
                                row.routeGUID == selectedFlight?.routeGUID
                                  ? "row-highlight"
                                  : ""
                              }
                            >
                              <TableCell style={{ minWidth: 75 }}>
                                <FormControlLabel
                                  key="slFlightSelectOpt"
                                  value={row.routeGUID}
                                  control={<Radio />}
                                  label=""
                                  onChange={handleFlightChange}
                                  checked={
                                    selectedFlight?.routeGUID === row.routeGUID
                                      ? true
                                      : false
                                  }
                                />
                              </TableCell>
                              <TableCell style={{ minWidth: 75 }}>
                                {row.carrierName}
                              </TableCell>
                              <TableCell style={{ minWidth: 130 }}>
                                {/* <span
                                  dangerouslySetInnerHTML={{
                                    __html: row.departTimeAirportDisplay,
                                  }}
                                /> */}
                                <span className="Del_company">
                                  {row.departTimeAirportDisplay
                                    ? row.departTimeAirportDisplay.split(
                                        "<br>"
                                      )[0]
                                    : ""}
                                </span>

                                <span className="del_company_detail">
                                  {row.departTimeAirportDisplay
                                    ? row.departTimeAirportDisplay.split(
                                        "<br>"
                                      )[1]
                                    : ""}
                                </span>
                              </TableCell>
                              <TableCell style={{ minWidth: 130 }}>
                                {/* <span
                                  dangerouslySetInnerHTML={{
                                    __html: row.arriveTimeAirportDisplay,
                                  }}
                                /> */}
                                <span className="Del_company">
                                  {row.arriveTimeAirportDisplay
                                    ? row.arriveTimeAirportDisplay.split(
                                        "<br>"
                                      )[0]
                                    : ""}
                                </span>

                                <span className="del_company_detail">
                                  {row.arriveTimeAirportDisplay
                                    ? row.arriveTimeAirportDisplay.split(
                                        "<br>"
                                      )[1]
                                    : ""}
                                </span>
                              </TableCell>
                              <TableCell style={{ minWidth: 101 }}>
                                {row.travelTimeDisplay}
                              </TableCell>
                              <TableCell style={{ minWidth: 103 }}>
                                {row.stops}
                              </TableCell>
                              <TableCell style={{ minWidth: 133 }}>
                                <Box className="delivery-date">
                                  {row.estimatedDeliverTime
                                    ? formatDate(row.estimatedDeliverTime)
                                    : ""}
                                </Box>
                                <Box className="pod_datetime">
                                  {row.estimatedDeliverTime
                                    ? formatDateTZ(row.estimatedDeliverTime)
                                    : ""}
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Box>
          </>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          className="d-flex rounded gap8 pb-16"
          id="btnRouteDialogCancel"
        >
          CANCEL
        </Button>
        <Button
          onClick={() => {
            if (selectedFlight && selectedFlight?.estimatedDeliverTime) {
              formik.setFieldValue(
                "requestedDeliveryTime",
                moment(selectedFlight?.estimatedDeliverTime).format(
                  "YYYY-MM-DDTHH:mm:ss"
                )
              );
            }
            handleClose();
            handleCalculate();
          }}
          variant="contained"
          color="primary"
          startIcon={<CheckCircleIcon />}
          className="d-flex rounded rate_btn"
          id="btnRouteDialogConfirmAndCalc"
        >
          CONFIRM & CALCULATE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RateEstimateRouteDialog;
