interface DownloadResult {
  success: boolean;
  error: string;
}
export const downloadAndSavePDF = async (
  response: any,
  suggestedName: string
): Promise<DownloadResult> => {
  try {
    const mimeTypes: any = {
      pdf: "application/pdf",
      rtf: "application/rtf",
      xls: "application/vnd.ms-excel",
      xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      csv: "text/csv",
      // Add other MIME types if needed
    };

    let fileExtension: any = "pdf";
    if (suggestedName || suggestedName.includes(".")) {
      fileExtension = suggestedName
        ? suggestedName.split(".")[1].toLowerCase()
        : "pdf";
    }

    // Determine the MIME type based on the file extension
    const mimeType: any =
      mimeTypes[fileExtension] || "application/octet-stream"; // Default to binary stream if MIME type is unknown

    const blob = new Blob([response], { type: mimeType });
    const urlBlob = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = urlBlob;
    a.download = suggestedName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(urlBlob);
    return { success: true, error: "" };
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};

export const downloadImportOrderTemplate = async (
  response: any,
  suggestedName: string
): Promise<DownloadResult> => {
  try {
    const blob = new Blob([response], { type: "text/csv" });

    const urlBlob = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = urlBlob;
    a.download = suggestedName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    setTimeout(() => {
      window.URL.revokeObjectURL(urlBlob);
    }, 1000);
    return { success: true, error: "" };
  } catch (error: any) {
    return { success: false, error: error.message };
  }
};
