import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../types/global";
import { account, accountForUPS } from "./accounts.reducer";
import { useLocation } from "react-router-dom";
import { useDecodeJWT } from "../../hook/use-decodeJWT";

const useAccounntsLogic = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = useDecodeJWT();
  const { loading, error, accounts } = useSelector(
    (state: RootState) => state.acconts
  );

  useEffect(() => {
    if (error) {
      // dispatch(showNotification('Account failed: ' + error, 'error'));
    }
  }, [error, dispatch]);

  const handleAccount = async (
    param: {
      userId: number;
      companyID: number;
      useIncludeAllItemrId: boolean;
    },
    fetchDataFromAPI: boolean = false
  ) => {
    const isUPSPremierAgent =
      localStorage.getItem("isUPSPremierAgent") === "true";

    try {
      //isUPSPremierAgent = false the it'll work same as it is, but if it's true then it'll use the GetUserCustomerAccounts
      //API the get the grouped accounts
      if (!isUPSPremierAgent) {
        if (!fetchDataFromAPI && accounts && accounts?.data?.data?.length > 0) {
          return accounts;
        } else {
          if (!param?.userId) {
            param.userId = Number(token.UserId);
          }
          const response = await dispatch(account(param) as any);
          return response;
        }
      } else {
        //get the group accounts for UPS = true
        const isMatchingPath =
          location.pathname.includes("/search-orders") ||
          location.pathname.includes("/export-data");
        if (!param?.userId) {
          param.userId = Number(token.UserId);
        }
        const apiParms = {
          userId: param?.userId,
          groupName: "", //get all group data
          isBillable: isMatchingPath ? false : true,
          viewOrders: isMatchingPath ? true : false,
        };
        const response = await dispatch(accountForUPS(apiParms) as any);
        return response;
      }
    } catch (err) {
      throw err;
    }
  };

  const hanleAccountForUPS = async (param: {
    userId: number;
    groupName: string;
    isBillable: boolean;
    viewOrders: boolean;
  }) => {
    try {
      if (!param?.userId) {
        param.userId = Number(token.UserId);
      }
      const response = await dispatch(accountForUPS(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    loading,
    error,
    account: handleAccount,
    accountForUPS: hanleAccountForUPS,
  };
};

export default useAccounntsLogic;
