import React, { useState, FC, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Box,
  TableSortLabel,
  TableRow,
  Grid,
  TextField,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import CloseIcon from "@mui/icons-material/Close";
import CommonPagination from "../CustomPagination";
import Loader from "../../util/loader";
import { AddressItemDto } from "../../../models/address-item-dto";
import useCustomerAddressLogic from "../../customer/address/customer-address.logic";
import { Link } from "react-router-dom";

// Define the prop types for the MyDialog component
interface MyDialogProps {
  open: boolean;
  handleClose: () => void;
  addressType: string;
  onSelectAddress: (address: AddressItemDto | null) => void; // Handler function
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof AddressItemDto;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  {
    id: "addressCode",
    numeric: true,
    disablePadding: false,
    label: "Code",
  },
  {
    id: "companyName",
    numeric: true,
    disablePadding: false,
    label: "Company",
  },
  {
    id: "address1",
    numeric: true,
    disablePadding: false,
    label: "Address",
  },
  {
    id: "cityName",
    numeric: true,
    disablePadding: false,
    label: "City",
  },
  {
    id: "stateProvID",
    numeric: true,
    disablePadding: false,
    label: "State",
  },
  {
    id: "countryID",
    numeric: true,
    disablePadding: false,
    label: "Country",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof AddressItemDto
  ) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

const CodeDialog: FC<MyDialogProps> = ({
  open,
  handleClose,
  addressType,
  onSelectAddress,
}) => {
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof AddressItemDto>("companyName");
  const [notData] = React.useState<any>("No Data Found");
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecord, setTotalRecord] = useState(0);
  // const isMobile = useMediaQuery("(max-width:600px)");
  const {  customerAddress } = useCustomerAddressLogic();
  const [addressData, setAddressData] = useState<AddressItemDto[]>([]);
  const userGroupGUID: any = localStorage.getItem("userGroupGUID");
  const [searchParams, setSearchParams] = useState({
    userGroupGUID: userGroupGUID,
    companyName: "",
    cityName: "",
    stateProvID: "",
    addressCode: "",
    countryID: "",
    postalCode: "",
    returnAllIfNoParms: true,
    orderBy: orderBy,
    sorting: order,
    pagesize: rowsPerPage === -1 ? totalRecord : rowsPerPage,
    pageNo: page + 1,
  });

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof AddressItemDto
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    setPage(0);
  };

  useEffect(() => {
    setSearchParams((prevSearchParams) => ({
      ...prevSearchParams,
      companyName: "",
      cityName: "",
      stateProvID: "",
      addressCode: "",
      countryID: "",
      postalCode: "",
    }));
    if (open) {
      getCustomerAddress();
    } else {
      setOrder("asc");
      setOrderBy("companyName");
      setRowsPerPage(10);
      setPage(0);
    }
  }, [order, orderBy, page, rowsPerPage, open]);

  function EnhancedTableHead(props: EnhancedTableProps) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler =
      (property: keyof AddressItemDto) =>
      (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={"left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchParams((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearch = () => {
    getCustomerAddress();
  };

  const onSelectCompany = (selectedCompany: any) => {
    onSelectAddress(selectedCompany);
    handleClose();
  };

  const getCustomerAddress = async () => {
    try {
      setIsLoading(true);
      const updatedSearchParams = {
        ...searchParams,
        pageNo: page + 1,
        orderBy: orderBy,
        sorting: order,
        pagesize: rowsPerPage === -1 ? totalRecord : rowsPerPage,
      };

      const response = await customerAddress(updatedSearchParams);
      if (response.data && response.data.data) {
        setAddressData(response.data.data);
        setTotalRecord(response.data.totalCount);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setAddressData([]);
    }
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (pageSize: number) => {
    setRowsPerPage(pageSize);
    setPage(0);
  };

  return (
    <Dialog
      maxWidth={"xl"}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
      style={{ height: "95%" }}
    >
      <DialogTitle className="Popup-header" id="responsive-dialog-title">
        Address Lookup
        <IconButton
          aria-label="close"
          onClick={handleClose}
          id="btnLookupClose"
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className="Dialog-content">
        <Box className="code-form_section">
          <Grid container spacing={2} className="pb-16 mt-0">
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                className="w-100"
                label="Company"
                variant="outlined"
                id="txtLookupCompanyName"
                size="small"
                name="companyName"
                value={searchParams.companyName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item lg={3} md={3} sm={3} xs={6}>
              <TextField
                className="w-100"
                label="Code"
                variant="outlined"
                id="txtLookupAddressCode"
                size="small"
                name="addressCode"
                value={searchParams.addressCode}
                onChange={handleChange}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item lg={3} md={3} sm={3} xs={12}>
              <TextField
                className="w-100"
                label="City"
                variant="outlined"
                id="txtLookupCity"
                size="small"
                name="cityName"
                value={searchParams.cityName}
                onChange={handleChange}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={7}>
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <TextField
                    className="w-100"
                    label="State"
                    variant="outlined"
                    id="txtLookupState"
                    size="small"
                    name="stateProvID"
                    value={searchParams.stateProvID}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={6}>
                  <TextField
                    className="w-100"
                    label="Country"
                    variant="outlined"
                    id="txtLookupCountry"
                    size="small"
                    name="countryID"
                    value={searchParams.countryID}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <TextField
                    className="w-100"
                    label="Zipcode"
                    variant="outlined"
                    size="small"
                    name="postalCode"
                    id="txtLookupPostalCode"
                    value={searchParams.postalCode}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={5} md={5} sm={2} xs={12}>
              <Button
                variant="contained"
                color="primary"
                id="btnLookupSearch"
                size="small"
                className="Search_btn"
                onClick={handleSearch}
              >
                SEARCH
              </Button>
            </Grid>
          </Grid>
        </Box>
        <div className="code-form">
          <>
            <Box sx={{ width: "100%" }}>
              <Paper
                className="ActiveOrder"
                sx={{ width: "100%", mb: 0, display: "block!important" }}
              >
                <TableContainer className="addresslookup-grid">
                  <Table aria-labelledby="tableTitle">
                    <EnhancedTableHead
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={addressData.length}
                    />
                    <TableBody>
                      {isLoading ? (
                        <TableRow>
                          <TableCell className="" colSpan={12} align="center">
                            <Loader />
                          </TableCell>
                        </TableRow>
                      ) : addressData.length === 0 ? (
                        <TableRow>
                          <TableCell className="" colSpan={12} align="center">
                            <div className="No_data">{notData}</div>
                          </TableCell>
                        </TableRow>
                      ) : (
                        addressData.map((row: AddressItemDto, index: any) => {
                          return (
                            <TableRow hover key={row.addressGUID}>
                              <TableCell style={{ minWidth: 100 }}>
                                {row.addressCode}
                              </TableCell>
                              <TableCell style={{ minWidth: 200 }}>
                                <Link
                                  to={""}
                                  id="hlLookupSelectCompany"
                                  className="primary"
                                  onClick={(event) => {
                                    event.preventDefault(); // Prevents the default behavior of the link
                                    onSelectCompany(row);
                                  }}
                                >
                                  {row.companyName}
                                </Link>
                              </TableCell>
                              <TableCell style={{ minWidth: 300 }}>
                                {row.address1}
                              </TableCell>
                              <TableCell style={{ minWidth: 180 }}>
                                {row.cityName}
                              </TableCell>
                              <TableCell style={{ minWidth: 100 }}>
                                {row.stateProvID}
                              </TableCell>
                              <TableCell style={{ minWidth: 100 }}>
                                {row.countryID}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
                <CommonPagination
                  count={totalRecord}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            </Box>
          </>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary"
          variant="outlined"
          className="d-flex rounded me-2 pb-16"
          id="btnLookupClose"
        >
          CLOSE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CodeDialog;

// Mobile card view component
// const MobileCardView: React.FC<{ row: AddressItemDto }> = ({ row }) => {
//   return (
//     <Paper
//       className="Card_view"
//       elevation={3}
//       sx={{ marginBottom: "16px", padding: "0" }}
//     >
//       <Typography variant="h6" className="Order-head Field_info">
//         <span className="field_label">Code</span>{" "}
//         <span className="Order_id Field_value">{row.addressCode}</span>
//       </Typography>
//       <Typography variant="body1" className="Field_info">
//         <span className="field_label">Company</span>
//         <span className="Field_value">
//           {" "}
//           <i className="status-info">{row.companyName}</i>
//         </span>
//       </Typography>
//       <Typography variant="body1" className="Field_info">
//         <span className="field_label">Address</span>{" "}
//         <span className="Field_value">{row.address1}</span>
//       </Typography>
//       <Typography variant="body1" className="Field_info">
//         <span className="field_label">City</span>{" "}
//         <span className="Field_value">{row.cityName}</span>
//       </Typography>
//       <Typography variant="body1" className="Field_info">
//         <span className="field_label"> State </span>
//         <span className="Field_value">{row.stateProvID}</span>
//       </Typography>
//       <Typography variant="body1" className="Field_info">
//         <span className="field_label">Country </span>
//         <span className="Field_value">{row.countryID}</span>
//       </Typography>
//     </Paper>
//   );
// };
