import { MySettingItemDto } from "../../models/long-form-item-dto";
import { PieceItemDto } from "../../models/piece-item-dto";
import { calculateDimWeight } from "./calculate-dim-weight";

export const updateDefaultDataByRateEstimate = (apiResponse: any, rateEstimateData: any) => {
    let res = apiResponse;
   
    if (rateEstimateData) {
     const  pickupReadyTime =
        new Date(rateEstimateData?.pickupReadyTime) >= new Date()
          ? rateEstimateData?.pickupReadyTime
          : null;
          const  deliveryRequestTime =
          new Date(rateEstimateData?.requestedDeliveryTime) >= new Date()
            ? rateEstimateData?.requestedDeliveryTime
            : null;     
        res = {
          ...apiResponse,
          accountNumber: rateEstimateData?.accountNumber,
          serviceGUID: rateEstimateData?.serviceGUID,
          serviceName: rateEstimateData.serviceName,
          serviceID: rateEstimateData.serviceID,
          pickupReadyTime: pickupReadyTime || null,
          deliveryRequestTime: deliveryRequestTime || null,
          deliveryWhen: "T",
          deliverWhen: "T",
          rateEstimateData: rateEstimateData,
          deliveryRequestAsap: false,
          
          pickupReadyNow: false,
          pickupAirportID: rateEstimateData.pickupAirportID,
          isDangerousGoods: rateEstimateData.isDangerousGoods,
          deliveryAddress1: rateEstimateData?.deliveryAddress1,
          deliveryAddress2: rateEstimateData?.deliveryAddress2,
          pickupAddress1: rateEstimateData?.pickupAddress1,
          pickupAddress2: rateEstimateData?.pickupAddress2,

          pickupCityName: rateEstimateData.pickupCityName,
          pickupCountryID: rateEstimateData.pickupCountryID,
          pickupPostalCode: rateEstimateData.pickupPostalCode,
          pickupStateProvID: rateEstimateData.pickupStateProvID,
          pickupTimezoneID: rateEstimateData?.pickupTimezoneID,
         

          pickupAddressCode: rateEstimateData.pickupAddressCode,
          deliveryAirportID: rateEstimateData.deliveryDropAirportID,
          deliveryAddressCode: rateEstimateData.deliveryAddressCode,
          deliveryCityName: rateEstimateData.deliveryCityName,
          deliveryCountryID: rateEstimateData.deliveryCountryID,
          deliveryPostalCode: rateEstimateData.deliveryPostalCode,
          deliveryStateProvID: rateEstimateData.deliveryStateProvID,
          deliveryTimezoneID: rateEstimateData?.deliveryTimezoneID,
          

          pieces:  rateEstimateData?.packageInfo[0]?.pieces,
          weight: rateEstimateData?.packageInfo[0]?.weight,
          weightUOM: rateEstimateData?.packageInfo[0]?.weightUOM,
          length:  rateEstimateData?.packageInfo[0]?.length,
          width:  rateEstimateData?.packageInfo[0]?.width,
          height:  rateEstimateData?.packageInfo[0]?.height,
          dimWeight: rateEstimateData?.packageInfo[0]?.dimWt,
          commodity: rateEstimateData?.packageInfo[0]?.commodity,
          sizeUOM: rateEstimateData?.packageInfo[0]?.unit,
        };

        if (rateEstimateData?.pickupAddress) {
          res = {
            ...res,
            pickupCompanyName: rateEstimateData?.pickupCompanyName,
            pickupPhoneNumber: rateEstimateData?.pickupPhoneNumber,
            pickupInstructions: rateEstimateData?.pickupInstructions,
            pickupEmailAddress: rateEstimateData?.pickupEmailAddress,
            puAddressGUID: rateEstimateData?.puAddressGUID,
           pickupTimezoneID: rateEstimateData.pickupTimezoneID,
           // timezoneID: rateEstimateData.pickupTimezoneID,
          };
        }
        if (rateEstimateData?.deliveryAddress) {
          res = {
            ...res,
            deliveryCompanyName: rateEstimateData?.deliveryCompanyName,
            dropOff: rateEstimateData?.dropOff,
            deliveryInstructions: rateEstimateData?.deliveryInstructions,
            deliveryPhoneNumber: rateEstimateData?.deliveryPhoneNumber || "",
            deliveryAttention: rateEstimateData?.deliveryAttention || "",
            deliveryFaxNumber: "",
            delAddressGUID: rateEstimateData?.adddelAddressGUIDressGUID,
            holdForPickup: rateEstimateData?.holdForPickup,
            deliveryEmailAddress:
              rateEstimateData?.deliveryEmailAddress || "",
              deliveryTimezoneID: rateEstimateData.deliveryTimezoneID,
          };
        }
      }
      
      return res;
}

export const createDeafaultPieces = (DefaultData: MySettingItemDto,  token: any, defaultPiece:PieceItemDto[] | any ) => {

    if(DefaultData?.rateEstimateData) {
        const packageInfo = DefaultData?.rateEstimateData?.packageInfo;
        let newpackageInfo = defaultPiece;
       if(packageInfo && packageInfo.length > 1) {
        packageInfo.map((_data: any, index : number) => {
          if(index !=0) {
            newpackageInfo.push(
              {
                contents: "",
                reference: "",
                commodity:_data?.commodity,
                weight: _data.weight,
                dimWeight: calculateDimWeight(
                  "US",
                  "US",
                  _data?.pieces || 1,
                  _data?.length || 0,
                  _data?.width || 0,
                  _data?.height || 0,
                  _data?.unit|| 'IN',
                  _data?.weightUOM
                ),
                weightUOM: _data.weightUOM,
                length: _data.length,
                width: _data.width,
                height: _data.height,
                sizeUOM: _data?.unit|| 'IN',
                packagingType: "",
                declaredValue:  0.0,
                declaredValueCurrencyID: "" ,
                bagGUID: "",
                bagGUID2: "",
                count: 1,
                insertUserID: parseInt(token?.UserId),
                oBagGUID: "",
                oBagGUID2: "",
                countryOfOrigin: "",
                hsCode: "",
                quantity: _data.pieces,
                
              }
            )
          }
        })
       }
       return newpackageInfo
    } else {
       return defaultPiece;
    }

}

export const accountStatussToStopProcess = ['HN','HC','PD','IN','DL']

export const getAccountStatusMessage = (accountStatus: string): string => {
  
  const messages: Record<string, string> = {
    'HN': "Your account is on hold, please contact customer service for more information.",
    'HC': "Your account is on hold, please contact customer service for more information.",
    'PD': "Your account is pending approval, please contact customer service for more information.",
    'IN': "Your account is inactive, please contact customer service for more information.",
    'DL': "Your account is closed, please contact customer service for assistance.",
  };

  return messages[accountStatus] || "Your account is inactive, please contact customer service for more information.";
};