import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { MySettingDto } from "../../../models/my-setting-dto";
import CommodityListDialog from "../commodity-list-dialog/commodity-list-dialog";
import { UnDetailsItemDto } from "../../../models/long-form-item-dto";
import useSearchUNDetailsLogic from "../../lookup/searchUn-details/searchUNDetails.logic";
import { MAX_COMPANY_ID } from "../../../config/constants";
import { handleNumericValue } from "../../util/numeric-value";
import CustomDatePicker from "../CustomDatePicker";

interface DangerousGoodsDefaultsProps {
  mySetting: MySettingDto;
  handleInputChange: (updatedData: any) => void;
  onUnNumberChange: (errors: any) => void;
}

const DangerousGoodsDefaults: React.FC<DangerousGoodsDefaultsProps> = ({
  mySetting,
  handleInputChange,
  onUnNumberChange,
}) => {
  const [isDangerousGoods, setIsDangerousGoods] = useState(false);
  const [isRedioActive, setIsRedioActive] = useState(false);
  const [open, setOpen] = useState(false);
  const { searchUNDetails } = useSearchUNDetailsLogic();

  useEffect(() => {
    if (mySetting.isDangerousGoods) {
      setIsDangerousGoods(mySetting.isDangerousGoods);
    }
    if (mySetting.dgIsRadioactive) {
      setIsRedioActive(mySetting.dgIsRadioactive);
    }
  }, [mySetting.isDangerousGoods, mySetting.dgIsRadioactive]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSelectCommodity = (commodity: UnDetailsItemDto) => {
    setIsDangerousGoods(commodity.isDangerousGoods);
    setIsRedioActive(commodity.isRadioactive);
    handleInputChange({
      target: { name: "dgUNNumber", value: commodity.unNumber || "" },
    } as any);
    handleInputChange({
      target: { name: "isDangerousGoods", value: commodity.isDangerousGoods },
    } as any);
    handleInputChange({
      target: { name: "dgIsRadioactive", value: commodity.isRadioactive },
    } as any);
    handleInputChange({
      target: { name: "dgProperName", value: commodity.properName },
    } as any);
    handleInputChange({
      target: {
        name: "dgCommodityClass",
        value: commodity.commodityClass || "",
      },
    } as any);
  };

  const handleRedioActiveToggle = (value: boolean) => {
    setIsRedioActive(value);
    handleInputChange({
      target: { name: "dgIsRadioactive", value: value },
    } as any);
  };

  const handleDangerousGoodsActiveToggle = (value: boolean) => {
    setIsDangerousGoods(value);
    handleInputChange({
      target: { name: "isDangerousGoods", value: value },
    } as any);
  };

  const checkUNDetails = async () => {
    try {
      const param = {
        companyID: MAX_COMPANY_ID,
        unNumber: mySetting.dgUNNumber || "",
      };
      const response = await searchUNDetails(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        onUnNumberChange(res);
        if (res.length > 0) {
          handleInputChange({
            target: { name: "dgUNNumber", value: res.unNumber },
          } as any);
          handleInputChange({
            target: { name: "isDangerousGoods", value: res.isDangerousGoods },
          } as any);
          handleInputChange({
            target: { name: "dgIsRadioactive", value: res.isRadioactive },
          } as any);
          handleInputChange({
            target: { name: "dgProperName", value: res.properName },
          } as any);
          handleInputChange({
            target: { name: "dgCommodityClass", value: res.commodityClass },
          } as any);
          return res[0];
        }
      }
    } catch (err) {}
  };

  const handleUnNumberKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && mySetting.dgUNNumber) {
      try {
        const res = await checkUNDetails(); // Wait for getZipCode to complete
        if (res) {
          handleInputChange({
            target: { name: "dgUNNumber", value: res.unNumber },
          } as any);
          handleInputChange({
            target: { name: "isDangerousGoods", value: res.isDangerousGoods },
          } as any);
          handleInputChange({
            target: { name: "dgIsRadioactive", value: res.isRadioactive },
          } as any);
          handleInputChange({
            target: { name: "dgProperName", value: res.properName },
          } as any);
          handleInputChange({
            target: { name: "dgCommodityClass", value: res.commodityClass },
          } as any);
        }
      } catch (error) {}
    }
  };

  const handleUNNumberFocusOut = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    try {
      if (mySetting.dgUNNumber) {
        const res = await checkUNDetails(); // Wait for getZipCode to complete
        if (res) {
          handleInputChange({
            target: { name: "dgUNNumber", value: res.unNumber },
          } as any);
          handleInputChange({
            target: { name: "isDangerousGoods", value: res.isDangerousGoods },
          } as any);
          handleInputChange({
            target: { name: "dgIsRadioactive", value: res.isRadioactive },
          } as any);
          handleInputChange({
            target: { name: "dgProperName", value: res.properName },
          } as any);
          handleInputChange({
            target: { name: "dgCommodityClass", value: res.commodityClass },
          } as any);
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <Box className="d-flex flex-column w-100 border p16 rounded">
            <Grid container>
              <Grid item lg={12}>
                <Typography variant="h6" className="Sub-header">
                  Dangerous Goods Defaults
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="d-flex w-100 mb-24">
                  <FormControl component="fieldset" className="w-100">
                    <FormLabel
                      component="legend"
                      className="font14 weight-500 mb-8"
                    >
                      Does the shipment contain dangerous goods?
                    </FormLabel>
                    <ButtonGroup className="width100px">
                      <Button
                        className="w-100 rounded-left-only mt-8"
                        variant={isDangerousGoods ? "contained" : "outlined"}
                        onClick={() => handleDangerousGoodsActiveToggle(true)}
                        id="btnIsDangerousGoodsYes"
                      >
                        YES
                      </Button>
                      <Button
                        className="w-100 rounded-right-only mt-8"
                        variant={!isDangerousGoods ? "contained" : "outlined"}
                        onClick={() => handleDangerousGoodsActiveToggle(false)}
                        id="btnIsDangerousGoodsNo"
                      >
                        NO
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="d-flex w-100 mb-24">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel
                    component="legend"
                    className="font14 weight-500 mb-0"
                  >
                    Does the shipment contain radioactive material?
                  </FormLabel>
                  <ButtonGroup className="width100px">
                    <Button
                      className="w-100 rounded-left-only mt-8"
                      variant={isRedioActive ? "contained" : "outlined"}
                      onClick={() => handleRedioActiveToggle(true)}
                      id="btnIsRedioActiveYes"
                    >
                      YES
                    </Button>
                    <Button
                      className="w-100 rounded-right-only mt-8"
                      variant={!isRedioActive ? "contained" : "outlined"}
                      onClick={() => handleRedioActiveToggle(false)}
                      id="btnIsRedioActiveNo"
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </Box>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} className="mb-24">
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    id="txtUnNumber"
                    size="small"
                    label="UN #"
                    variant="outlined"
                    name="dgUNNumber"
                    defaultValue={mySetting.dgUNNumber}
                    value={mySetting.dgUNNumber}
                    onChange={handleInputChange}
                    onKeyDown={handleUnNumberKeyDown}
                    onBlur={handleUNNumberFocusOut}
                    InputLabelProps={{ shrink: !!mySetting.dgUNNumber }}
                    className="w-100"
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickOpen} id="btnDgAdd">
                            <AddIcon />
                          </IconButton>
                          <CommodityListDialog
                            open={open}
                            handleClose={handleClose}
                            onSelectCommodity={onSelectCommodity}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    size="small"
                    label="Name"
                    id="txtDgProperName"
                    variant="outlined"
                    className="w-100"
                    name="dgProperName"
                    defaultValue={mySetting.dgProperName}
                    value={mySetting.dgProperName}
                    InputLabelProps={{ shrink: !!mySetting.dgProperName }}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={6}>
                  <TextField
                    size="small"
                    label="Class"
                    id="txtDgCommodityClass"
                    variant="outlined"
                    className="w-100"
                    name="dgCommodityClass"
                    defaultValue={mySetting.dgCommodityClass}
                    value={mySetting.dgCommodityClass}
                    onChange={handleInputChange}
                    InputLabelProps={{ shrink: !!mySetting.dgCommodityClass }}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={6}>
                  <TextField
                    size="small"
                    label="Trans IDX"
                    variant="outlined"
                    id="txtTtransportationIndex"
                    className="w-100"
                    name="dgTransportationIndex"
                    defaultValue={mySetting.dgTransportationIndex}
                    inputProps={{
                      maxLength: 10,
                    }}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={6}>
                  <FormControl size="small" className="w-100">
                    <InputLabel id="demo-select-small-label">Limits</InputLabel>
                    <Select
                      labelId="demo-select-small-label"
                      id="ddDgTransportationLimit"
                      label="Limits"
                      size="small"
                      name="dgTransportationLimit"
                      defaultValue={mySetting.dgTransportationLimit}
                      onChange={handleInputChange}
                    >
                      <MenuItem value="P">
                        Passenger And Cargo Aircraft
                      </MenuItem>
                      <MenuItem value="C">Cargo Aircraft Only</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} className="mb-24">
              <Box className="d-flex flex-column gap-2 w-100">
                {/* <FormControl component="fieldset" className="w-100">
                  <FormLabel component="legend" className="font14 weight-500">
                    Does the shipment require dry ice?
                  </FormLabel>
                  <ButtonGroup>
                    <Button className="w-100 rounded-left-only">YES</Button>
                    <Button className="w-100 rounded-right-only">NO</Button>
                  </ButtonGroup>
                </FormControl> */}

                <Box className="d-flex flex-column gap-2 w-100">
                  <TextField
                    label="Amount of dry ice"
                    id="txtDgDryIceWeight"
                    variant="outlined"
                    className="w-100 "
                    name="dgDryIceWeight"
                    defaultValue={
                      mySetting.dgDryIceWeight ? mySetting.dgDryIceWeight : null
                    }
                    onChange={handleInputChange}
                    onKeyDown={handleNumericValue}
                    size="small"
                    inputProps={{ maxLength: 9 }}
                  />
                </Box>
              </Box>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="d-flex flex-column gap-2 w-100">
                {/* <FormControl component="fieldset" className="w-100 mt-2">
                  <FormLabel component="legend" className="font14 weight-500">
                    Does the shipment require gel packs?
                  </FormLabel>
                  <ButtonGroup>
                    <Button className="w-100 rounded-left-only">YES</Button>
                    <Button className="w-100 rounded-right-only">NO</Button>
                  </ButtonGroup>
                </FormControl> */}

                {/* <Box className="d-flex flex-column gap-2 w-100">
                  <TextField
                    size="small"
                    label="Amount of gel packs"
                    variant="outlined"
                    className="w-100 "
                    name="dgGelPackCount"
                  />
                  <FormControlLabel
                    control={<Checkbox name="carrierToProvideGelPack" />}
                    label="LFS/MNX to provide"
                    className="p-2"
                  />
                </Box> */}
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className="d-flex flex-column w-100 border p16 rounded">
            <Grid item lg={12}>
              <Typography variant="h6" className="Sub-header">
                Dangerous Goods Packing Details
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    id="txtDgPackingGroup"
                    variant="outlined"
                    label="Packing Group"
                    className="w-100"
                    name="dgPackingGroup"
                    defaultValue={mySetting.dgPackingGroup}
                    onChange={handleInputChange}
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    id="txtDgQuantityAndPackType"
                    variant="outlined"
                    label="Qty & Type of Packing"
                    className="w-100"
                    name="dgQuantityAndPackType"
                    defaultValue={mySetting.dgQuantityAndPackType}
                    onChange={handleInputChange}
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                id="txtDgPackingInst"
                variant="outlined"
                label="Packing Instructions"
                placeholder="Enter any additional instructions"
                multiline
                rows={4}
                className="w-100 mt-16"
                name="dgPackingInst"
                defaultValue={mySetting.dgPackingInst}
                onChange={handleInputChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                id="txtDgHandlingInfo"
                variant="outlined"
                label="Additional Handling Instructions"
                placeholder="Enter additional handling information instructions"
                multiline
                rows={4}
                className="w-100 mt-16"
                name="dgHandlingInfo"
                defaultValue={mySetting.dgHandlingInfo}
                onChange={handleInputChange}
                inputProps={{ maxLength: 500 }}
              />
            </Grid>

            <Grid item lg={12} md={12} xs={12} className="mt-16">
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Authorization"
                    id="txtDgAuthorization"
                    className="w-100"
                    name="dgAuthorization"
                    defaultValue={mySetting.dgAuthorization}
                    onChange={handleInputChange}
                    size="small"
                    inputProps={{ maxLength: 40 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Emergency Phone #"
                    id="txtDgEmergencyPhone"
                    className="w-100"
                    name="dgEmergencyPhone"
                    defaultValue={mySetting.dgEmergencyPhone}
                    onChange={handleInputChange}
                    size="small"
                    inputProps={{ maxLength: 25 }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} className="mt-16">
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Title Signatory"
                    id="txtDgSignatory"
                    className="w-100"
                    name="dgSignatory"
                    defaultValue={mySetting.dgSignatory}
                    onChange={handleInputChange}
                    size="small"
                    inputProps={{ maxLength: 80 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <div className="small-cal-view">
                    <CustomDatePicker
                      handleDateChange={handleInputChange as any}
                      label="Date"
                      name="dgDate"
                      id="txtDgDate"
                      // value={
                      //   mySetting?.dgDate ? adjustDate(mySetting?.dgDate) : ""
                      // }
                      value={mySetting?.dgDate}
                      isClearable={true}
                    />
                  </div>
                  {/* <TextField
                    size="small"
                    type="date"
                    label="Date"
                    variant="outlined"
                    className="w-100"
                    InputLabelProps={{ shrink: true }}
                    name="dgDate"
                    defaultValue={mySetting?.dgDate ? adjustDate(mySetting?.dgDate) : ''}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                           <IconButton>
                                <img
                                  src={calendarIcon}
                                  alt="Calendar icon"
                                  style={{ maxWidth: "100%" }}
                                />
                              </IconButton> 
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DangerousGoodsDefaults;
