import {
  Box,
  TextField,
  Typography,
  Button,
  Menu,
  MenuItem,
  Paper,
  Container,
  Alert,
  Grid,
  useMediaQuery,
} from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  GoogleMap,
  MarkerF,
  OverlayView,
  useJsApiLoader,
} from "@react-google-maps/api";
import {
  API_BASE_URL,
  MAX_COMPANY_ID,
  MAX_MAP_API_KEY,
} from "../../config/constants";
import trackordericon from "../../assets/images/trackorder/img_icon_left.svg";
import attachicon from "../../assets/images/trackorder/img_attach_file.svg";
import printicon from "../../assets/images/trackorder/img_printfilled.svg";
import phoneicon from "../../assets/images/trackorder/img_phoneiphonefilled.svg";
import arrowicon from "../../assets/images/trackorder/img_icon_right.svg";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ErrorIcon from "@mui/icons-material/Error";
import React, { useEffect, useState } from "react";
import useTrackLogic from "./track-order.logic";
import PlannedItinerary from "../../shared/components/track-order/PlannedItinerary";
import RouteInfo from "../../shared/components/track-order/RouteInfo";
import Notes from "../../shared/components/track-order/Notes";
import Charges from "../../shared/components/track-order/Charges";
import ReferenceInfo from "../../shared/components/track-order/ReferenceInfo";
import Documents from "../../shared/components/track-order/Documents";
import { TrackOrderItemDto } from "../../models/track-order-item-dto";
import { format } from "date-fns";
import AttachDocsDialog from "../../shared/components/attach-docs-dialog/attach-docs-dialog";
import Loader from "../../shared/util/loader";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import axiosInstance from "../../config/axios-interceptor";
import { downloadAndSavePDF } from "../../shared/util/download-pdf";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation } from "react-router-dom";
import editicon from "../../assets/images/logo/edit-icon.svg";
import EditDangerousGoods from "../../shared/components/track-order/EditDangerousGoods";

const TrackOrder: React.FC = () => {
  const [track, setTrack] = useState<string>("");
  const [shipmentGUID, setshipmentGUID] = useState("");
  const isTablet = useMediaQuery("(max-width:905px)");
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [trackFormErrors, setTrackFormErrors] = useState<string | null>(null);
  const { trackError, trackorder } = useTrackLogic();
  const [alertVisible, setAlertVisible] = useState(!!successMessage);
  const [trackDetails, setTrackDetails] = useState<TrackOrderItemDto | null>(
    null
  );
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDgDialog, setOpenDgDialog] = useState(false);
  const token = useDecodeJWT();
  const [showInfo, setShowInfo] = useState(false);
  const location = useLocation();
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [isDisabledDgBtn, setIsDisabledDgBtn] = useState(true);
  const [isUPSLable, setIsUPSLable] = useState(false);

  useEffect(() => {
    if (isUploadSuccess && successMessage) {
      setAlertVisible(true);
      const timer = setTimeout(() => {
        setAlertVisible(false);
        setIsUploadSuccess(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [isUploadSuccess]);

  useEffect(() => {
    setTrack("");
    setTrackDetails(null);
    setshipmentGUID("");
    setIsUPSLable(false);
  }, [location]);

  // QDT
  const deliveryDate = trackDetails?.schedDeliveryDateTime
    ? new Date(trackDetails.schedDeliveryDateTime)
    : null;
  const formattedTimeQdt = deliveryDate
    ? trackDetails?.schedDeliveryDateTimeDisplay
    : "ASAP";
  const formattedDateQdt = deliveryDate
    ? format(deliveryDate, "dd-MMM yyyy").toUpperCase()
    : null;

  // Updated QDT
  const updatedDeliveryDate = trackDetails?.deliveryUpdatedDateTime
    ? new Date(trackDetails.deliveryUpdatedDateTime)
    : null;
  const formattedTime = updatedDeliveryDate
    ? trackDetails?.deliveryUpdatedTimeDisplay
    : "ASAP";
  const formattedDate = updatedDeliveryDate
    ? format(updatedDeliveryDate, "dd-MMM yyyy").toUpperCase()
    : null;

  const getTrackDetails = async (
    TrackingNumber: string,
    ShipmentGUID: string
  ) => {
    try {
      sessionStorage.setItem("SetshipmentGUID", "");
      setIsLoading(true);
      const param = {
        trackingNumber: TrackingNumber.toString(),
        shipmentGUID: ShipmentGUID,
      };
      const response = await trackorder(param);

      if (response.data && response.data.data) {
        setTrackDetails(response.data.data);
        const isActualPickupDate = response.data.data.pickupActualDateTime
          ? true
          : false;
        setIsDisabledDgBtn(isActualPickupDate);
        setIsLoading(false);
        sessionStorage.setItem(
          "SetshipmentGUID",
          response.data.data.shipmentGUID
        );
        handleClose();
      } else {
        setIsLoading(false);
        setTrackFormErrors(response.data.message);
      }
    } catch {
      setTrackFormErrors(trackError);
      setTrackDetails(null);
      setIsLoading(false);
      setIsUPSLable(false);
    }
  };

  useEffect(() => {
    setTrackDetails(trackDetails);
    const UPSDocuments = trackDetails?.documents.find(
      (x) => x.documentTypeName === "UPS Label"
    );
    if (UPSDocuments) {
      setIsUPSLable(true);
    }
  }, [trackDetails]);

  const printReport = async (ReportType: string) => {
    const SetshipmentGUID = sessionStorage.getItem("SetshipmentGUID");
    // eslint-disable-next-line
    if (SetshipmentGUID != "") {
      try {
        const param = {
          companyID: MAX_COMPANY_ID,
          userId: Number(token.UserId),
          batchType: ReportType,
          accountNumber: "",
          shipmentGUIDs: [
            {
              shipmentGuid: SetshipmentGUID,
              pickupGUID: "",
              manifestGUID: "",
            },
          ],
        };
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Report/api/PrintBatch`,
          param,
          { responseType: "arraybuffer" }
        );
        if (response.status) {
          const pdfTitle = `${ReportType}.pdf`;
          const result = await downloadAndSavePDF(response.data, pdfTitle);

          if (result.error !== "") {
          }
        }
      } catch (error: any) {}
    }
  };

  const handleChangeTrack = (e: any) => {
    setTrackFormErrors(null);
    setTrack(e.target.value);
  };

  const validateTrack = () => {
    let errors = "";
    let isValid = true;

    if (!track) {
      errors =
        "Invalid Tracking Number. Please ensure the correct tracking number is entered.";
      isValid = false;
    }

    setTrackFormErrors(errors);
    setIsDisabledDgBtn(true);
    setTrackDetails(null);
    setIsUPSLable(false);
    return isValid;
  };

  const handleSubmitTrack = () => {
    if (!validateTrack()) {
      setTrackDetails(null);
      setIsUPSLable(false);
      return;
    } else {
      handleClose();
      setTrackFormErrors(null);
      getTrackDetails(track, shipmentGUID);
      setIsUploadSuccess(false);
    }
  };

  const handleUploadSuccess = (message: string) => {
    setSuccessMessage(message); // Set success message
    setIsUploadSuccess(true);
  };
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePrintOption = async (reportType: string) => {
    if (track !== "") {
      if (trackDetails === null) {
        await getTrackDetails(track, shipmentGUID);
        printReport(reportType);
      } else {
        printReport(reportType);
      }
    } else {
      setTrackFormErrors("Please track an order before printing.");
      setTrackDetails(null);
      setIsUPSLable(false);
    }
    handleClose();
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const shipmentGUID = urlParams.get("ShipmentGUID");

    if (shipmentGUID) {
      getTrackDetails("", shipmentGUID);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (trackDetails && track === "") {
      setTrack(trackDetails.shipmentNumber);
    }
    // eslint-disable-next-line
  }, [trackDetails]);

  const handleClickOpen = async () => {
    // eslint-disable-next-line
    if (track != "") {
      // eslint-disable-next-line
      if (trackDetails == null) {
        await getTrackDetails(track, shipmentGUID);
        const SetshipmentGUID = sessionStorage.getItem("SetshipmentGUID");
        // eslint-disable-next-line
        if (SetshipmentGUID != "") {
          setOpen(true);
          handleClose();
        }
      } else {
        setOpen(true);
        handleClose();
      }
    } else {
      setTrackFormErrors("You must track an order first.");
      setTrackDetails(null);
      setIsUPSLable(false);
      handleClose();
    }
  };

  const handleDialogClose = (isReload: boolean) => {
    setOpen(false);
    if (isReload) {
      getTrackDetails(track, shipmentGUID);
    }
  };

  const handleDangerousGoodsDialog = () => {
    setOpenDgDialog(true);
  };

  const handleDGDialogClose = () => {
    setOpenDgDialog(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSubmitTrack();
    }
  };

  const containerStyle = {
    width: "100%",
    height: "368px",
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAX_MAP_API_KEY,
  });

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
  };

  const onSuccessErrorDgMessage = (message: string) => {
    if (message === "SUCCESS") {
      setSuccessMessage("Order details updated successfully.");
      setIsUploadSuccess(true);
      setTrackFormErrors(null);
    } else {
      setTrackFormErrors(message);
    }
  };

  useEffect(() => {
    if (trackDetails) {
      const urlParams = new URLSearchParams(window.location.search);
      const shipmentGUID = urlParams.get("ShipmentGUID");
      if (shipmentGUID !== trackDetails.shipmentGUID) {
        const url = new URL(window.location.href);
        url.searchParams.set("ShipmentGUID", trackDetails.shipmentGUID);

        // Update the browser's address bar
        window.history.pushState({}, "", url);
      }
    }
  }, [trackDetails]);

  return (
    <div className="container-fluid track-order Track-screen">
      <>
        <Box className="align-items-center d-flex Track-section">
          <Box className="pr-64">
            <Typography
              variant="h4"
              color="primary"
              className="d-flex Main-Header margintop16"
              style={{ whiteSpace: "nowrap" }}
            >
              TRACK AN ORDER
            </Typography>
          </Box>

          <Box className="d-flex flex-end WidthRightTrackinput">
            <TextField
              id="txtTrackingnumber"
              name="Tracking Field"
              label="Airbill #/Order #"
              variant="outlined"
              size="small"
              className="d-flex mr-8 Trackinputfield"
              value={track}
              onChange={handleChangeTrack}
              onKeyPress={handleKeyPress}
              error={!!trackFormErrors}
              // helperText={trackFormErrors}
              // style={{ width: "50%" }}
              InputProps={{
                style: {
                  color: !!trackFormErrors ? "#d32f2f" : "inherit",
                },
                endAdornment: trackFormErrors ? (
                  <ErrorIcon color="error" />
                ) : null,
              }}
            />
            <Box className="Track_btn_section">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitTrack}
                startIcon={<img src={trackordericon} alt="icon left" />}
                className="Order_btn Track_lbl_btn"
                id="btnTrackBtn"
              >
                TRACK
              </Button>{" "}
              <Box className="Border-line"></Box>
              {isTablet ? (
                <Box className="d-flex">
                  <Button
                    variant="outlined"
                    onClick={handleClick}
                    className="d-flex dottedicon"
                    id="btnMoreVertIcon"
                  >
                    <MoreVertIcon color="action" />
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={handleDangerousGoodsDialog}
                      id="btnDangerousGoods"
                    >
                      Edit Dangerous Goods
                    </MenuItem>
                    <MenuItem
                      onClick={() => handlePrintOption("AIRBILL")}
                      id="btnAirbill"
                    >
                      Print Airbill
                    </MenuItem>
                    <MenuItem
                      onClick={() => handlePrintOption("SHIPLABEL")}
                      id="btnLabel"
                    >
                      Print MNX Label
                    </MenuItem>
                    <MenuItem
                      onClick={() => handlePrintOption("UPSLABEL")}
                      id="btnLabel"
                      disabled={!isUPSLable}
                    >
                      Print UPS Label
                    </MenuItem>
                    <MenuItem
                      onClick={() => handlePrintOption("INVOICE")}
                      id="btnPrintInvoice"
                    >
                      Print Invoice
                    </MenuItem>
                    <MenuItem
                      onClick={() => handlePrintOption("DGD")}
                      id="btnPrintDangGoods"
                    >
                      Print Dangerous Goods
                    </MenuItem>
                  </Menu>
                </Box>
              ) : (
                <Box className="d-flex">
                  <Button
                    variant="outlined"
                    startIcon={
                      <img
                        src={editicon}
                        alt="icon left"
                        style={{ opacity: isDisabledDgBtn ? 0.5 : 1 }}
                      />
                    }
                    className="d-flex rounded Order_btn Attach_btn mr-8"
                    sx={{ backgroundColor: "white", color: "black" }}
                    onClick={handleDangerousGoodsDialog}
                    id="btnEditDangerousGoods"
                    disabled={isDisabledDgBtn}
                  >
                    EDIT DANGEROUS GOODS
                  </Button>
                  {/* Edit DangerousGoods */}
                  <EditDangerousGoods
                    open={openDgDialog}
                    handleClose={handleDGDialogClose}
                    shipmentGUIDProp={trackDetails?.shipmentGUID || ""}
                    onSuccessErrorDgMessage={onSuccessErrorDgMessage}
                  />
                  <Button
                    variant="outlined"
                    startIcon={<img src={attachicon} alt="icon left" />}
                    className="d-flex rounded Order_btn Attach_btn mr-8"
                    sx={{ backgroundColor: "white", color: "black" }}
                    onClick={handleClickOpen}
                    id="btnAttachDocs"
                  >
                    ATTACH DOCS
                  </Button>
                  <Button
                    variant="outlined"
                    startIcon={<img src={printicon} alt="icon left" />}
                    endIcon={<img src={arrowicon} alt="icon right" />}
                    onClick={handleClick}
                    className="d-flex rounded Order_btn Attach_btn"
                    sx={{ backgroundColor: "white", color: "black" }}
                    id="btnPrintDocs"
                  >
                    PRINT DOCS
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    <MenuItem
                      onClick={() => handlePrintOption("AIRBILL")}
                      id="btnAirbill"
                    >
                      Print Airbill
                    </MenuItem>
                    <MenuItem
                      onClick={() => handlePrintOption("SHIPLABEL")}
                      id="btnLabel"
                    >
                      Print MNX Label
                    </MenuItem>
                    <MenuItem
                      onClick={() => handlePrintOption("UPSLABEL")}
                      id="btnLabel"
                      disabled={!isUPSLable}
                    >
                      Print UPS Label
                    </MenuItem>
                    <MenuItem
                      onClick={() => handlePrintOption("INVOICE")}
                      id="btnPrintInvoice"
                    >
                      Print Invoice
                    </MenuItem>
                    <MenuItem
                      onClick={() => handlePrintOption("DGD")}
                      id="btnPrintDangGoods"
                    >
                      Print Dangerous Goods
                    </MenuItem>
                  </Menu>
                </Box>
              )}
            </Box>

            {/* <Button
              variant="outlined"
              startIcon={<img src={attachicon} alt="icon left" />}
              className="d-flex fw-bold rounded Order_btn Attach_btn mr-8"
              sx={{ backgroundColor: "white", color: "#100249" }}
              onClick={handleClickOpen}
            >
              ATTACH DOCS
            </Button> */}
            <AttachDocsDialog
              open={open}
              handleClose={handleDialogClose}
              shipmentGUID={trackDetails ? trackDetails.shipmentGUID : ""}
              onUploadSuccess={handleUploadSuccess}
            />
            {/* <Button
              variant="outlined"
              startIcon={<img src={printicon} alt="icon left" />}
              endIcon={<img src={arrowicon} alt="icon right" />}
              onClick={handleClick}
              className="d-flex fw-bold border border-dark rounded Order_btn Attach_btn"
              sx={{ backgroundColor: "white", color: "black" }}
            >
              PRINT DOCS
            </Button> */}
            {/* <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={() => handlePrintOption("AIRBILL")}>
                Print Airbill
              </MenuItem>
              <MenuItem onClick={() => handlePrintOption("SHIPLABEL")}>
                Print Label
              </MenuItem>
              <MenuItem onClick={() => handlePrintOption("INVOICE")}>
                Print Invoice
              </MenuItem>
              <MenuItem onClick={() => handlePrintOption("DGD")}>
                Print Dangerous Goods
              </MenuItem>
            </Menu> */}
          </Box>
        </Box>

        {trackFormErrors && (
          <Box className="w-100">
            <Alert severity="error" variant="filled" id="txtToTrackError">
              {trackFormErrors}
            </Alert>
          </Box>
        )}

        {isLoading ? (
          <Box className="d-flex justify-content-center align-items-center vh-100">
            <Loader />
          </Box>
        ) : (
          trackDetails && (
            <Box>
              <Box>
                <Box className="d-flex w-100 Track_info_lbl">
                  <AssignmentIcon
                    className="Assignment_icon"
                    sx={{ color: "#2A7A98" }}
                  />
                  <Typography
                    color="primary"
                    className="d-flex w-100 Status_label"
                  >
                    {trackDetails.statusDisplay}
                  </Typography>
                </Box>
                {alertVisible && successMessage && (
                  <>
                    <Box className="w-100">
                      <Alert
                        variant="filled"
                        severity="success"
                        className="trackorder-success-toast w-100"
                        id="txtToSuccess"
                      >
                        {successMessage}
                      </Alert>
                    </Box>
                  </>
                )}
                <Container className="d-flex w-100 padding0 maxwidth100">
                  {trackDetails.showMap && (
                    <>
                      <Grid container spacing={2}>
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                          <Box
                            className="d-flex w-100 Map-view"
                            sx={{ height: "100%" }}
                          >
                            {isLoaded && (
                              <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={{
                                  lat: trackDetails.mapScriptDto?.latitude,
                                  lng: trackDetails.mapScriptDto?.longitude,
                                }}
                                zoom={14}
                                options={mapOptions}
                              >
                                <MarkerF
                                  position={{
                                    lat: trackDetails.mapScriptDto?.latitude,
                                    lng: trackDetails.mapScriptDto?.longitude,
                                  }}
                                  onMouseOver={() => setShowInfo(true)}
                                  onMouseOut={() => setShowInfo(false)}
                                />
                                {showInfo && (
                                  <OverlayView
                                    position={{
                                      lat: trackDetails.mapScriptDto?.latitude,
                                      lng: trackDetails.mapScriptDto?.longitude,
                                    }}
                                    mapPaneName={
                                      OverlayView.OVERLAY_MOUSE_TARGET
                                    }
                                  >
                                    <div
                                      style={{
                                        background: "white",
                                        whiteSpace: "nowrap",
                                        padding: "5px",
                                        display: "inline-block",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <span style={{ display: "inline-block" }}>
                                        {
                                          trackDetails.mapScriptDto
                                            .pushPinDescription
                                        }
                                      </span>
                                    </div>
                                  </OverlayView>
                                )}
                              </GoogleMap>
                            )}
                          </Box>
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <Box
                            className="d-flex flex-column Map-view p16"
                            sx={{ height: "100%" }}
                          >
                            <Box
                              className="d-flex flex-column pb-16 pl-8 pr-8"
                              id="divPickup"
                            >
                              <Typography className="track_lbl">
                                PICKUP
                              </Typography>
                              <Typography className="comp_name">
                                {trackDetails.pickupCompanyName
                                  ? trackDetails.pickupCompanyName.toUpperCase()
                                  : ""}
                              </Typography>
                              {trackDetails.pickupAddress1 && (
                                <Typography className="addr_dtl">
                                  {trackDetails.pickupAddress1.toUpperCase()}
                                </Typography>
                              )}
                              {trackDetails.pickupAddress2 && (
                                <Typography className="addr_dtl">
                                  {trackDetails.pickupAddress2.toUpperCase()}
                                </Typography>
                              )}
                              {trackDetails.pickupAddress3 && (
                                <Typography className="addr_dtl">
                                  {trackDetails.pickupAddress3.toUpperCase()}
                                </Typography>
                              )}
                              <Typography className="addr_dtl">
                                {trackDetails.pickupCityName
                                  ? trackDetails.pickupCityName.toUpperCase()
                                  : ""}
                                {/* {" "} */}
                                {trackDetails.pickupStateProvID
                                  ? ", " +
                                    trackDetails.pickupStateProvID.toUpperCase()
                                  : ""}{" "}
                                {trackDetails.pickupPostalCode}
                              </Typography>
                              {trackDetails.pickupPhoneNumber && (
                                <Typography className="d-flex align-center">
                                  <img src={phoneicon} alt="Phone icon" />
                                  <span className="Phone_num">
                                    {" "}
                                    {trackDetails.pickupPhoneNumber}
                                  </span>
                                </Typography>
                              )}
                            </Box>

                            <Box
                              className="d-flex flex-column pb-16 pl-8 pr-8"
                              id="divDeliverTo"
                            >
                              <Typography variant="body2" className="track_lbl">
                                DELIVER TO
                              </Typography>
                              <Typography className="comp_name">
                                {trackDetails.deliveryCompanyName
                                  ? trackDetails.deliveryCompanyName.toUpperCase()
                                  : ""}
                              </Typography>
                              {trackDetails.deliveryAddress1 && (
                                <Typography className="other_dtl">
                                  {trackDetails.deliveryAddress1.toUpperCase()}
                                </Typography>
                              )}
                              {trackDetails.deliveryAddress2 && (
                                <Typography className="other_dtl">
                                  {trackDetails.deliveryAddress2.toUpperCase()}
                                </Typography>
                              )}
                              {trackDetails.deliveryAddress3 && (
                                <Typography className="other_dtl">
                                  {trackDetails.deliveryAddress3.toUpperCase()}
                                </Typography>
                              )}
                              <Typography className="other_dtl">
                                {trackDetails.deliveryCityName
                                  ? trackDetails.deliveryCityName.toUpperCase()
                                  : ""}
                                {/* {" "} */}
                                {trackDetails.deliveryStateProvID
                                  ? ", " +
                                    trackDetails.deliveryStateProvID.toUpperCase()
                                  : ""}{" "}
                                {trackDetails.deliveryPostalCode}
                              </Typography>
                              {trackDetails.deliveryPhoneNumber && (
                                <Typography className="d-flex align-center">
                                  <img src={phoneicon} alt="Phone icon" />
                                  <span className="Phone_num">
                                    {trackDetails.deliveryPhoneNumber}
                                  </span>
                                </Typography>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item lg={4} md={6} sm={6} xs={12}>
                          <Box
                            className="d-flex flex-column Map-view p16"
                            sx={{ height: "100%" }}
                          >
                            <Grid
                              container
                              spacing={1}
                              sx={{ paddingLeft: "8px" }}
                            >
                              <Grid item lg={3} md={3} sm={4} xs={4} id="gdQDT">
                                <Typography className="track_lbl">
                                  QDT
                                </Typography>
                                <Typography className="other_dtl">
                                  {formattedTimeQdt}
                                </Typography>
                                <Typography className="track_lbl">
                                  {formattedDateQdt}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                lg={4}
                                md={4}
                                sm={6}
                                xs={6}
                                id="gdUpdatedQDT"
                              >
                                <Typography className="track_lbl">
                                  UPDATED QDT
                                </Typography>
                                <Typography className="other_dtl">
                                  {formattedTime}
                                </Typography>
                                <Typography className="track_lbl">
                                  {formattedDate}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                lg={5}
                                md={5}
                                sm={5}
                                xs={5}
                                id="gdOrderDate"
                              >
                                <Typography className="track_lbl">
                                  ORDER DATE
                                </Typography>
                                <Typography className="other_dtl">
                                  {trackDetails.orderDateTimeDisplay}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                lg={2}
                                md={2}
                                sm={3}
                                xs={3}
                                id="gdPieces"
                              >
                                <Typography className="track_lbl">
                                  PIECES
                                </Typography>
                                <Typography className="other_dtl">
                                  {trackDetails.pieces}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                lg={2}
                                md={2}
                                sm={3}
                                xs={3}
                                id="gdWeight"
                              >
                                <Typography className="track_lbl">
                                  WEIGHT
                                </Typography>
                                <Typography className="other_dtl">
                                  {trackDetails.weight} {trackDetails.weightUOM}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                lg={8}
                                md={8}
                                sm={12}
                                xs={12}
                                id="gdServiceType"
                              >
                                <Typography className="track_lbl">
                                  SERVICE TYPE
                                </Typography>
                                <Typography className="other_dtl">
                                  {trackDetails.serviceName}
                                </Typography>
                              </Grid>
                            </Grid>

                            <Box
                              className="d-flex flex-column align-items-start pl-8 pr-8 mb-8 pt-8"
                              id="gdOrderBy"
                            >
                              <Typography className="track_lbl">
                                ORDER BY
                              </Typography>
                              <Typography className="other_dtl">
                                {trackDetails.orderByName}
                              </Typography>
                            </Box>

                            <Box
                              className="d-flex flex-column align-items-start pl-8 pr-8 mb-8"
                              id="gdRefer"
                            >
                              <Typography className="track_lbl">
                                REFER
                              </Typography>
                              <Typography className="other_dtl">
                                {trackDetails.reference}
                              </Typography>
                            </Box>

                            <Box
                              className="d-flex flex-column align-items-start pl-8 pr-8 mb-8"
                              id="gdCommodity"
                            >
                              <Typography className="track_lbl">
                                COMMODITY
                              </Typography>
                              <Typography className="other_dtl">
                                {trackDetails.commodity}
                              </Typography>
                            </Box>

                            <Box
                              className="d-flex flex-column align-items-start justify-center gap-2 pl-8 pr-8 mb-8"
                              id="gdContents"
                            >
                              <Typography className="track_lbl">
                                CONTENTS
                              </Typography>
                              <Typography className="other_dtl">
                                {trackDetails.contents}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </>
                  )}
                  {/* <Paper className="d-flex w-100 p-4">
                    <Box className="d-flex flex-column gap-3">
                      <Box className="d-flex flex-column gap-2">
                        <Box className="d-flex flex-column ">
                          <Typography className="text-black-50 mb-2">
                            PICKUP
                          </Typography>
                          <Typography className="fw-bold">
                            {trackDetails.pickupCompanyName
                              ? trackDetails.pickupCompanyName.toUpperCase()
                              : ""}
                          </Typography>
                          {trackDetails.pickupAddress1 && (
                            <Typography>
                              {trackDetails.pickupAddress1.toUpperCase()}
                            </Typography>
                          )}
                          {trackDetails.pickupAddress2 && (
                            <Typography>
                              {trackDetails.pickupAddress2.toUpperCase()}
                            </Typography>
                          )}
                          {trackDetails.pickupAddress3 && (
                            <Typography>
                              {trackDetails.pickupAddress3.toUpperCase()}
                            </Typography>
                          )}
                          <Typography>
                            {trackDetails.pickupCityName
                              ? trackDetails.pickupCityName.toUpperCase()
                              : ""}
                            ,{" "}
                            {trackDetails.pickupStateProvID
                              ? trackDetails.pickupStateProvID.toUpperCase()
                              : ""}{" "}
                            {trackDetails.pickupPostalCode}
                          </Typography>
                          {trackDetails.pickupPhoneNumber && (
                            <Typography className="text-primary">
                              <img src={phoneicon} alt="Phone icon" />
                              {trackDetails.pickupPhoneNumber}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <Box className="d-flex flex-column">
                        <Typography
                          variant="body2"
                          className="text-black-50 mb-2"
                        >
                          DELIVER TO
                        </Typography>
                        <Typography className="fw-bold">
                          {trackDetails.deliveryCompanyName
                            ? trackDetails.deliveryCompanyName.toUpperCase()
                            : ""}
                        </Typography>
                        {trackDetails.deliveryAddress1 && (
                          <Typography>
                            {trackDetails.deliveryAddress1.toUpperCase()}
                          </Typography>
                        )}
                        {trackDetails.deliveryAddress2 && (
                          <Typography>
                            {trackDetails.deliveryAddress2.toUpperCase()}
                          </Typography>
                        )}
                        {trackDetails.deliveryAddress3 && (
                          <Typography>
                            {trackDetails.deliveryAddress3.toUpperCase()}
                          </Typography>
                        )}
                        <Typography>
                          {trackDetails.deliveryCityName
                            ? trackDetails.deliveryCityName.toUpperCase()
                            : ""}
                          ,{" "}
                          {trackDetails.deliveryStateProvID
                            ? trackDetails.deliveryStateProvID.toUpperCase()
                            : ""}{" "}
                          {trackDetails.deliveryPostalCode}
                        </Typography>
                        {trackDetails.deliveryPhoneNumber && (
                          <Typography className="text-primary">
                            <img src={phoneicon} alt="Phone icon" />
                            {trackDetails.deliveryPhoneNumber}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Paper> */}

                  {/* <Paper className="d-flex w-100 p-4">
                    <Box className="d-flex flex-column align-items-start gap-2">
                      <Box className="d-flex gap-4">
                        <Box className="d-flex flex-1 flex-column align-items-start gap-2 p-1.5">
                          <Typography className="text-black-50">
                            DATE
                          </Typography>
                          <Typography style={{ whiteSpace: "nowrap" }}>
                            {trackDetails.orderDateTimeDisplay}
                          </Typography>
                        </Box>

                        <Box className="d-flex flex-column align-items-start gap-2 p-1.5">
                          <Typography className="text-black-50">
                            PIECES
                          </Typography>
                          <Typography>{trackDetails.pieces}</Typography>
                        </Box>

                        <Box className="d-flex flex-column align-items-start gap-2 p-1.5">
                          <Typography className="text-black-50">
                            WEIGHT
                          </Typography>
                          <Typography>
                            {trackDetails.weight} {trackDetails.weightUOM}
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="d-flex flex-column align-items-start gap-2 p-1.5">
                        <Typography className="ml-2 text-black-50 md:ml-0">
                          ORDER BY
                        </Typography>
                        <Typography>{trackDetails.orderByName}</Typography>
                      </Box>

                      <Box className="d-flex flex-column align-items-start gap-2 p-1.5 w-100">
                        <Typography className="text-black-50">REFER</Typography>
                        <Typography>{trackDetails.reference}</Typography>
                      </Box>

                      <Box className="d-flex flex-column align-items-start gap-2 p-1.5">
                        <Typography className="text-black-50">
                          COMMODITY
                        </Typography>
                        <Typography>{trackDetails.commodity}</Typography>
                      </Box>

                      <Box className="d-flex flex-column align-items-start justify-center gap-2">
                        <Typography className="text-black-50">
                          CONTENTS
                        </Typography>
                        <Typography>{trackDetails.contents}</Typography>
                      </Box>
                    </Box>
                  </Paper> */}
                </Container>
              </Box>

              <Box className="Route_selection mb-8 mt-8" sx={{ width: "100%" }}>
                <Paper className="p16 Track-route-grid" sx={{ width: "100%" }}>
                  <Typography variant="h6" className="sub-track-header">
                    Planned Itinerary
                  </Typography>
                  <PlannedItinerary itinerary={trackDetails.itinerary} />
                </Paper>
              </Box>

              <Box className="Route_selection mb-8" sx={{ width: "100%" }}>
                <Paper className="Track-route-grid p16" sx={{ width: "100%" }}>
                  <Typography variant="h6" className="sub-track-header">
                    Routing Information
                  </Typography>

                  <RouteInfo routeInfo={trackDetails.route} />
                </Paper>
              </Box>

              <Box className="Route_selection mb-8" sx={{ width: "100%" }}>
                <Paper className="Track-route-grid p16" sx={{ width: "100%" }}>
                  <Typography variant="h6" className="sub-track-header">
                    Notes
                  </Typography>
                  <Notes notes={trackDetails.notes} />
                </Paper>
              </Box>

              <Box className="Route_selection mb-8" sx={{ width: "100%" }}>
                <Paper className="Track-route-grid p16" sx={{ width: "100%" }}>
                  <Typography variant="h6" className="sub-track-header">
                    Charges
                  </Typography>

                  <Charges charges={trackDetails.charges} />
                </Paper>
              </Box>

              <Box className="Route_selection mb-8" sx={{ width: "100%" }}>
                <Paper className="Track-route-grid p16" sx={{ width: "100%" }}>
                  <Typography variant="h6" className="sub-track-header">
                    Reference Information
                  </Typography>

                  <ReferenceInfo reference={trackDetails.references} />
                </Paper>
              </Box>

              <Box className="Route_selection mb-8" sx={{ width: "100%" }}>
                <Paper className="Track-route-grid p16" sx={{ width: "100%" }}>
                  <Typography variant="h6" className="sub-track-header">
                    Documents
                  </Typography>

                  <Documents document={trackDetails.documents} />
                </Paper>
              </Box>
            </Box>
          )
        )}
      </>
    </div>
  );
};

export default TrackOrder;
