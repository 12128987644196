import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import CommodityListDialog from "../commodity-list-dialog/commodity-list-dialog";
import {
  ContentItemDto,
  MySettingItemDto,
  UnDetailsItemDto,
} from "../../../models/long-form-item-dto";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import { red } from "@mui/material/colors";
import useSearchUNDetailsLogic from "../../lookup/searchUn-details/searchUNDetails.logic";
import { MAX_COMPANY_ID } from "../../../config/constants";
import { handleDecimalValue } from "../../util/numeric-value";
import { Piece } from "../../../models/long-form-request-item-dto";
import CustomDatePicker from "../CustomDatePicker";
interface DangerousGoodsProps {
  DefaultData: MySettingItemDto;
  SelectedContent: ContentItemDto | null;
  onDangerousGoodDataChang: (updatedData: any) => void;
  formErrors: any;
  setFormErrors: (errors: any) => void;
  onUnNumberChange: (errors: any) => void;
  piecesData: Piece[];
  setPiecesData: (errors: any) => void;
  dangerousGoodDataProps: any;
  setUNError: (errors: any) => void;
}

interface DangerousGoodsState {
  isDangerousGoods?: boolean;
  dgIsRadioactive?: boolean;
  dgUNNumber?: any;
  dgCommodityClass?: string;
  dgProperName?: string;
  dgTransportationIndex?: string;
  dgTransportationLimit?: string;
  carrierToProvideDryIce: boolean;
  carrierToProvideGelPack: boolean;
  isDryIceRequire: boolean;
  isGelPacksRequire: boolean;
  dgDryIceWeight: number | undefined;
  dgGelPackCount: number | undefined;
  dgIsDryIceProvide: boolean;
  dgIsGelPackProvide: boolean;
  dgPackingGroup?: string;
  dgQuantityAndPackType?: string;
  dgPackingInst?: string;
  dgAuthorization?: string;
  dgEmergencyPhone?: string;
  dgSignatory?: string;
  dgDate?: any;
  dgHandlingInfo?: string;
}

const DangerousGoods: React.FC<DangerousGoodsProps> = ({
  DefaultData,
  SelectedContent,
  onDangerousGoodDataChang,
  formErrors,
  setFormErrors,
  onUnNumberChange,
  piecesData,
  setPiecesData,
  dangerousGoodDataProps,
  setUNError,
}) => {
  const [open, setOpen] = useState(false);
  const [isDangerousGoods, setIsDangerousGoods] = useState(false);
  const [isRedioActive, setIsRedioActive] = useState(false);
  const [isDryIceRequire, setIsDryIceRequire] = useState(false);
  const [isGelPacksRequire, setIsGelPacksRequire] = useState(false);
  const { searchUNDetails } = useSearchUNDetailsLogic();
  const [dangerousGoodsData, setDangerousGoodsData] =
    useState<DangerousGoodsState>({
      isDangerousGoods: false,
      dgIsRadioactive: false,
      dgUNNumber: "",
      dgCommodityClass: "",
      dgProperName: "",
      dgTransportationIndex: "",
      dgTransportationLimit: "",
      carrierToProvideDryIce: false,
      carrierToProvideGelPack: false,
      isGelPacksRequire: false,
      isDryIceRequire: false,
      dgDryIceWeight: undefined,
      dgGelPackCount: undefined,
      dgIsDryIceProvide: false,
      dgIsGelPackProvide: false,
      dgPackingGroup: "",
      dgQuantityAndPackType: "",
      dgPackingInst: "",
      dgAuthorization: "",
      dgEmergencyPhone: "",
      dgSignatory: "",
      dgDate: DefaultData.dgDate,
      dgHandlingInfo: "",
    });
  const errorControls = [
    {
      name: "dgUNNumber",
      value: "dgUNNumber",
    },
    {
      name: "dgProperName",
      value: "dgProperName",
    },
    {
      name: "dgCommodityClass",
      value: "dgCommodityClass",
    },
    {
      name: "dgTransportationLimit",
      value: "dgTransportationLimit",
    },
    {
      name: "dgEmergencyPhone",
      value: "dgEmergencyPhone",
    },
    {
      name: "dgSignatory",
      value: "dgSignatory",
    },
    {
      name: "dgDate",
      value: "dgDate",
    },
  ];

  useEffect(() => {
    if (DefaultData) {
      setDangerousGoodsData({
        isDangerousGoods: DefaultData.isDangerousGoods,
        dgIsRadioactive: DefaultData.dgIsRadioactive,
        dgUNNumber: DefaultData.dgUNNumber,
        dgCommodityClass: DefaultData.dgCommodityClass,
        dgProperName: DefaultData.dgProperName,
        dgTransportationIndex: DefaultData.dgTransportationIndex,
        dgTransportationLimit: DefaultData.dgTransportationLimit,
        carrierToProvideDryIce: false,
        carrierToProvideGelPack: false,
        isDryIceRequire: false,
        isGelPacksRequire: false,
        dgDryIceWeight: DefaultData.dgDryIceWeight,
        dgGelPackCount: undefined,
        dgIsDryIceProvide: false,
        dgIsGelPackProvide: false,
        dgPackingGroup: DefaultData.dgPackingGroup,
        dgQuantityAndPackType: DefaultData.dgQuantityAndPackType,
        dgPackingInst: DefaultData.dgPackingInst,
        dgAuthorization: DefaultData.dgAuthorization,
        dgEmergencyPhone: DefaultData.dgEmergencyPhone,
        dgSignatory: DefaultData.dgSignatory,
        dgDate: DefaultData.dgDate ? DefaultData?.dgDate : null,
        dgHandlingInfo: DefaultData.dgHandlingInfo || "",
      });
      setIsDangerousGoods(DefaultData.isDangerousGoods);
      setIsRedioActive(DefaultData.dgIsRadioactive);
      onDangerousGoodDataChang(DefaultData);
    }
  }, [DefaultData]);
  useEffect(() => {
    if (!isRedioActive) {
      const errorFields = [
        "dgUNNumber",
        "dgProperName",
        "dgCommodityClass",
        "dgTransportationLimit",
        "dgEmergencyPhone",
        "dgSignatory",
        "dgDate",
      ];
      errorFields.forEach((fieldName) => {
        let errorChange: any = errorControls.find(
          (ec) => ec.name === fieldName
        );
        if (errorChange) {
          handleError(fieldName); // Call handleError for each field with potential error
        }
      });
    }
  }, [isRedioActive]);

  useEffect(() => {
    setIsDangerousGoods(dangerousGoodDataProps?.isDangerousGoods);
    setIsRedioActive(dangerousGoodDataProps?.dgIsRadioactive);
    setDangerousGoodsData((prevState) => ({
      ...prevState,
      isDangerousGoods: dangerousGoodDataProps.isDangerousGoods,
      dgIsRadioactive: dangerousGoodDataProps.dgIsRadioactive,
    }));
  }, [dangerousGoodDataProps]);
  useEffect(() => {
    if (piecesData) {
      setDangerousGoodsData((prevState) => ({
        ...prevState,
        isDangerousGoods: piecesData[0]?.isDangerousGoods,
        dgIsRadioactive: piecesData[0]?.isRadioactive,
        dgUNNumber: piecesData[0]?.dgUNNumber,
        dgCommodityClass: piecesData[0]?.dgCommodityClass,
        dgProperName: piecesData[0]?.dgProperName,
        dgDryIceWeight: piecesData[0]?.dgDryIceWeight,
        dgGelPackCount: piecesData[0]?.dgGelPackCount,
        dgPackingGroup: piecesData[0]?.dgPackingGroup,
        dgQuantityAndPackType: piecesData[0]?.dgQuantityAndPackType,
        dgPackingInst: piecesData[0]?.dgPackingInstr,
        dgAuthorization: piecesData[0]?.dgAuthorization,
        dgIsGelPackProvide: piecesData[0]?.carrierToProvideGelPack ?? false,
        dgIsDryIceProvide: piecesData[0]?.carrierToProvideDryIce ?? false,
        isDryIceRequire: piecesData[0]?.isDryIceRequire ?? false,
        isGelPacksRequire: piecesData[0]?.isGelPacksRequire ?? false,
      }));

      errorControls.forEach((field) => {
        const fieldName = field.value as keyof Piece;
        if (piecesData[0]?.[fieldName]) {
          handleError(field.name);
        }
      });
      setIsDangerousGoods(piecesData[0]?.isDangerousGoods ?? false);
      setIsRedioActive(piecesData[0]?.isRadioactive ?? false);
      if (Number(piecesData[0]?.dgDryIceWeight) > 0) {
        setIsDryIceRequire(true);
      } else {
        setIsDryIceRequire(piecesData[0]?.isDryIceRequire ?? false);
      }
      setIsGelPacksRequire(piecesData[0]?.isGelPacksRequire ?? false);
    }
    onDangerousGoodDataChang({
      isDangerousGoods: piecesData[0]?.isDangerousGoods,
      dgIsRadioactive: piecesData[0]?.isRadioactive,
      dgUNNumber: piecesData[0]?.dgUNNumber,
      dgCommodityClass: piecesData[0]?.dgCommodityClass,
      dgProperName: piecesData[0]?.dgProperName,
      dgDryIceWeight: piecesData[0]?.dgDryIceWeight,
      dgGelPackCount: piecesData[0]?.dgGelPackCount,
      dgPackingGroup: piecesData[0]?.dgPackingGroup,
      dgQuantityAndPackType: piecesData[0]?.dgQuantityAndPackType,
      dgPackingInst: piecesData[0]?.dgPackingInstr,
      dgAuthorization: piecesData[0]?.dgAuthorization,
      dgIsGelPackProvide: piecesData[0]?.carrierToProvideGelPack ?? false,
      dgIsDryIceProvide: piecesData[0]?.carrierToProvideDryIce ?? false,
      isDryIceRequire: piecesData[0]?.isDryIceRequire ?? false,
      isGelPacksRequire: piecesData[0]?.isGelPacksRequire ?? false,
    });
  }, [piecesData]);

  const updateDangerousGoodsData = (
    updatedFields: Partial<DangerousGoodsState>
  ) => {
    const updatedData: any = { ...dangerousGoodsData, ...updatedFields };
    if (updatedFields.isDryIceRequire == false) {
      updatedData.dgDryIceWeight = null;
      updatedData.dgIsDryIceProvide = false;
    }
    if (updatedFields.isGelPacksRequire == false) {
      updatedData.dgGelPackCount = null;
      updatedData.dgIsGelPackProvide = false;
    }
    setDangerousGoodsData(updatedData);
    onDangerousGoodDataChang(updatedData);
    setIsDangerousGoods(updatedData.isDangerousGoods);
    setIsRedioActive(updatedData.dgIsRadioactive);
    setIsDryIceRequire(updatedData.isDryIceRequire);
    setIsGelPacksRequire(updatedData.isGelPacksRequire);
    setPiecesData((prevData: any) => {
      // Ensure prevData is an array and has at least one item
      if (!prevData || !Array.isArray(prevData) || prevData.length === 0) {
        return prevData; // Return unchanged data if it's invalid
      }
      // Create a copy of the previous data
      const updatedPiecesData = [...prevData];
      // Check if the first item exists
      if (!updatedPiecesData[0]) {
        return updatedPiecesData; // Return unchanged data if the first item is undefined
      }
      // Update the first item in the piecesData array with the new dangerous goods data
      updatedPiecesData[0] = {
        ...updatedPiecesData[0], // Preserve existing data
        isRadioactive: updatedData.dgIsRadioactive,
        isDangerousGoods: updatedData.isDangerousGoods,
        carrierToProvideDryIce: updatedData.dgIsDryIceProvide,
        carrierToProvideGelPack: updatedData.dgIsGelPackProvide,
        isDryIceRequire: updatedData.isDryIceRequire,
        isGelPacksRequire: updatedData.isGelPacksRequire,
        dgDryIceWeight: updatedData.dgDryIceWeight,
        dgGelPackCount: updatedData.dgGelPackCount,
        ...updatedFields, // Overwrite with new fields from dangerousGoodsData
      };
      return updatedPiecesData;
    });
  };

  useEffect(() => {
    if (SelectedContent) {
      setDangerousGoodsData((prevState) => ({
        ...prevState,
        dgUNNumber: SelectedContent.unNumber ? SelectedContent.unNumber : "",
        dgProperName: SelectedContent.properName
          ? SelectedContent.properName
          : "",
        dgCommodityClass: SelectedContent.commodityClass
          ? SelectedContent.commodityClass
          : "",
        isDangerousGoods: SelectedContent.isDangerousGoods,
        dgIsRadioactive: SelectedContent.isRadioactive,
      }));
      setIsDangerousGoods(SelectedContent.isDangerousGoods);
      setIsRedioActive(SelectedContent.isRadioactive);
      onDangerousGoodDataChang(SelectedContent);
      // Update piecesData state
      setPiecesData((prevData: any) => {
        if (!prevData || !Array.isArray(prevData) || prevData.length === 0) {
          return prevData; // Return unchanged data if it's invalid
        }
        // Create a copy of the previous data
        const updatedPiecesData = [...prevData];
        // Check if the first item exists
        if (!updatedPiecesData[0]) {
          return updatedPiecesData; // Return unchanged data if the first item is undefined
        }
        // Update the first item in the piecesData array
        updatedPiecesData[0] = {
          ...updatedPiecesData[0], // Preserve existing data
          dgUNNumber: SelectedContent.unNumber || "",
          dgProperName: SelectedContent.properName || "",
          dgCommodityClass: SelectedContent.commodityClass || "",
          isDangerousGoods: SelectedContent.isDangerousGoods,
          isDryIceRequire: SelectedContent.isRadioactive,
        };
        return updatedPiecesData;
      });
      const errorFields = [
        "dgUNNumber",
        "dgProperName",
        "dgCommodityClass",
        "isDangerousGoods",
        "dgIsRadioactive",
      ];
      errorFields.forEach((fieldName) => {
        let errorChange: any = errorControls.find(
          (ec) => ec.name === fieldName
        );
        if (errorChange) {
          handleError(fieldName); // Call handleError for each field with potential error
        }
      });
    }
  }, [SelectedContent]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDangerousGoodsData((prev) => ({ ...prev, [name]: value }));
    onDangerousGoodDataChang({ [name]: value });
    // Update piecesData if the input change affects it
    setPiecesData((prevData: any) => {
      if (!prevData || !Array.isArray(prevData) || prevData.length === 0) {
        return prevData; // Return unchanged data if it's invalid
      }
      // Create a copy of the previous data
      const updatedPiecesData = [...prevData];
      // Check if the first item exists
      if (!updatedPiecesData[0]) {
        return updatedPiecesData; // Return unchanged data if the first item is undefined
      }
      // Map fields manually from dangerousGoodsData to piecesData
      const fieldMapping: { [key: string]: string } = {
        dgUNNumber: "dgUNNumber",
        dgProperName: "dgProperName",
        dgCommodityClass: "dgCommodityClass",
        isDangerousGoods: "isDangerousGoods",
        dgIsRadioactive: "isRadioactive",
        dgTransportationIndex: "dgTransportationIndex",
        dgTransportationLimit: "dgTransportationLimit",
        dgDryIceWeight: "dgDryIceWeight",
        dgGelPackCount: "dgGelPackCount",
        dgIsDryIceProvide: "dgIsDryIceProvide",
        dgIsGelPackProvide: "dgIsGelPackProvide",
        dgPackingGroup: "dgPackingGroup",
        dgQuantityAndPackType: "dgQuantityAndPackType",
        dgPackingInst: "dgPackingInstr",
        dgAuthorization: "dgAuthorization",
        dgEmergencyPhone: "dgEmergencyPhone",
        dgSignatory: "dgSignatory",
        dgDate: "dgDate",
      };
      // Update the first item in the piecesData array with relevant fields
      const updatedPiece = { ...updatedPiecesData[0] };
      // Manually set fields based on the mapping
      for (const [sourceField, targetField] of Object.entries(fieldMapping)) {
        if (name === sourceField) {
          updatedPiece[targetField] = value;
        }
      }
      updatedPiecesData[0] = updatedPiece;
      return updatedPiecesData;
    });
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange) {
      handleError(name);
    }
  };

  const handleError = (name: string) => {
    let updatedName = name;
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange.name === updatedName) {
      updatedName = errorChange.value;
      if (formErrors[updatedName]) {
        setFormErrors((prevErrors: any) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[updatedName];
          return updatedErrors;
        });
      }
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    // Creating an HTMLInputElement-like event object
    const { name, value } = event.target;
    const syntheticEvent = {
      target: {
        name: name,
        value: value,
      },
    } as ChangeEvent<HTMLInputElement>;
    handleInputChange(syntheticEvent);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setDangerousGoodsData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));

    onDangerousGoodDataChang({ [name]: checked });
    // Update piecesData state
    setPiecesData((prevData: any) => {
      if (!prevData || !Array.isArray(prevData) || prevData.length === 0) {
        return prevData;
      }
      // Create a copy of the previous data
      const updatedPiecesData = [...prevData];
      // Check if the first item exists
      if (!updatedPiecesData[0]) {
        return updatedPiecesData;
      }
      // Map checkbox fields manually to piecesData
      const updatedPiece = { ...updatedPiecesData[0] };
      // Update the field based on the checkbox name
      if (name === "dgIsDryIceProvide") {
        updatedPiece.carrierToProvideDryIce = checked;
      } else if (name === "dgIsGelPackProvide") {
        updatedPiece.carrierToProvideGelPack = checked;
      }
      // updatedPiece[name] = checked;
      // Update the first item in the piecesData array
      updatedPiecesData[0] = updatedPiece;
      // Log updated pieces data for debugging
      return updatedPiecesData;
    });
  };

  const onSelectCommodity = (commodity: UnDetailsItemDto) => {
    if (commodity.unNumber) {
      setUNError(false);
    } else {
      setUNError(true);
    }
    setDangerousGoodsData((prevState) => ({
      ...prevState,
      dgUNNumber: commodity.unNumber ? commodity.unNumber : "",
      dgProperName: commodity.properName ? commodity.properName : "",
      dgCommodityClass: commodity.commodityClass
        ? commodity.commodityClass
        : "",
      isDangerousGoods: commodity.isDangerousGoods,
      isDryIceRequire: commodity.isRadioactive,
    }));
    setIsDangerousGoods(commodity.isDangerousGoods);
    setIsRedioActive(commodity.isRadioactive);

    // Update piecesData state
    setPiecesData((prevData: any) => {
      // Check if prevData is valid
      if (!prevData || !Array.isArray(prevData) || prevData.length === 0) {
        return prevData;
      }
      // Create a copy of the previous data
      const updatedPiecesData = [...prevData];
      // Check if the first item exists
      if (!updatedPiecesData[0]) {
        return updatedPiecesData;
      }
      // Map fields manually from dangerousGoodsData to piecesData
      const updatedPiece = { ...updatedPiecesData[0] };
      // Update fields with selected commodity data
      updatedPiece.dgUNNumber = commodity.unNumber;
      updatedPiece.dgProperName = commodity.properName;
      updatedPiece.dgCommodityClass = commodity.commodityClass;
      updatedPiece.isDangerousGoods = commodity.isDangerousGoods;
      updatedPiece.isRadioactive = commodity.isRadioactive;
      // Update the first item in the piecesData array
      updatedPiecesData[0] = updatedPiece;
      // Log updated pieces data for debugging
      return updatedPiecesData;
    });
    const errorFields = [
      "dgUNNumber",
      "dgProperName",
      "dgCommodityClass",
      "isDangerousGoods",
      "dgIsRadioactive",
    ];
    errorFields.forEach((fieldName) => {
      let errorChange: any = errorControls.find((ec) => ec.name === fieldName);
      if (errorChange) {
        handleError(fieldName); // Call handleError for each field with potential error
      }
    });
    onDangerousGoodDataChang((prevData: any) => ({
      ...prevData,
      dgProperName: commodity.properName,
      dgUNNumber: commodity.unNumber,
      dgCommodityClass: commodity.commodityClass,
      isDangerousGoods: commodity.isDangerousGoods,
      dgIsRadioactive: commodity.isRadioactive,
    }));
  };

  const checkUNDetails = async () => {
    try {
      const param = {
        companyID: MAX_COMPANY_ID,
        unNumber: dangerousGoodsData.dgUNNumber,
      };
      const response = await searchUNDetails(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        onUnNumberChange(res);
        if (res.length > 0) {
          setDangerousGoodsData((prevLongFormData) => ({
            ...prevLongFormData,
            dgProperName: res[0].properName,
            dgUNNumber: res[0].unNumber,
            dgCommodityClass: res[0].commodityClass,
            isDangerousGoods: res[0].isDangerousGoods,
            dgIsRadioactive: res[0].isRadioactive,
          }));
          setIsDangerousGoods(res[0].isDangerousGoods);
          setIsRedioActive(res[0].isRadioactive);
          onDangerousGoodDataChang((prevLongFormData: any) => ({
            ...prevLongFormData,
            dgProperName: res[0].properName,
            dgUNNumber: res[0].unNumber,
            dgCommodityClass: res[0].commodityClass,
            isDangerousGoods: res[0].isDangerousGoods,
            dgIsRadioactive: res[0].isRadioactive,
          }));
          return res[0];
        }
      }
    } catch (err) {}
  };

  const handleUnNumberKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && dangerousGoodsData.dgUNNumber) {
      try {
        const res = await checkUNDetails(); // Wait for getZipCode to complete
        if (res) {
          onDangerousGoodDataChang({
            ...dangerousGoodsData,
            dgProperName: res.properName,
            dgUNNumber: res.unNumber,
            dgCommodityClass: res.commodityClass,
            isDangerousGoods: res.isDangerousGoods,
            dgIsRadioactive: res.isRadioactive,
          });
          setIsDangerousGoods(res.isDangerousGoods);
          setIsRedioActive(res.isRadioactive);
          setPiecesData((prevData: any) => {
            // Check if prevData is valid
            if (
              !prevData ||
              !Array.isArray(prevData) ||
              prevData.length === 0
            ) {
              return prevData;
            }
            // Create a copy of the previous data
            const updatedPiecesData = [...prevData];
            // Check if the first item exists
            if (!updatedPiecesData[0]) {
              return updatedPiecesData;
            }
            // Map fields manually from dangerousGoodsData to piecesData
            const updatedPiece = { ...updatedPiecesData[0] };
            updatedPiece.dgProperName = res.properName;
            updatedPiece.dgUNNumber = res.unNumber;
            updatedPiece.dgCommodityClass = res.commodityClass;
            updatedPiece.isDangerousGoods = res.isDangerousGoods;
            updatedPiece.isRadioactive = res.isRadioactive;
            // Update the first item in the piecesData array
            updatedPiecesData[0] = updatedPiece;
            // Log updated pieces data for debugging
            return updatedPiecesData;
          });
        }
      } catch (error) {}
    }
  };

  const handleUNNumberFocusOut = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    try {
      if (dangerousGoodsData.dgUNNumber) {
        const res = await checkUNDetails(); // Wait for getZipCode to complete
        if (res) {
          onDangerousGoodDataChang({
            ...dangerousGoodsData,
            dgProperName: res.properName,
            dgUNNumber: res.unNumber,
            dgCommodityClass: res.commodityClass,
            isDangerousGoods: res.isDangerousGoods,
            dgIsRadioactive: res.isRadioactive,
          });
          setIsDangerousGoods(res.isDangerousGoods);
          setIsRedioActive(res.isRadioactive);
          setPiecesData((prevData: any) => {
            // Check if prevData is valid
            if (
              !prevData ||
              !Array.isArray(prevData) ||
              prevData.length === 0
            ) {
              return prevData;
            }
            // Create a copy of the previous data
            const updatedPiecesData = [...prevData];
            // Check if the first item exists
            if (!updatedPiecesData[0]) {
              return updatedPiecesData;
            }
            // Map fields manually from dangerousGoodsData to piecesData
            const updatedPiece = { ...updatedPiecesData[0] };
            updatedPiece.dgProperName = res.properName;
            updatedPiece.dgUNNumber = res.unNumber;
            updatedPiece.dgCommodityClass = res.commodityClass;
            updatedPiece.isDangerousGoods = res.isDangerousGoods;
            updatedPiece.isRadioactive = res.isRadioactive;

            // Update the first item in the piecesData array
            updatedPiecesData[0] = updatedPiece;
            // Log updated pieces data for debugging
            return updatedPiecesData;
          });
        }
      }
    } catch (error) {}
  };

  return (
    <Box className="d-flex gap-16 mt-3 w-100 Divider pb-32 align-flex-start flex-col">
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <Box className="d-flex flex-column w-100 border p16 rounded">
            <Grid container>
              <Grid item lg={12}>
                <Typography variant="h6" className="Sub-header">
                  Dangerous Goods
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="d-flex w-100 mb-24">
                  <FormControl component="fieldset" className="w-100">
                    <FormLabel
                      component="legend"
                      className="font14 weight-500 mb-8"
                    >
                      Does the shipment contain dangerous goods?
                    </FormLabel>
                    <ButtonGroup className="width100px">
                      <Button
                        id="btnIsDangerousGoodsYes"
                        variant={isDangerousGoods ? "contained" : "outlined"}
                        className="w-100 rounded-left-only mt-8"
                        onClick={() =>
                          updateDangerousGoodsData({ isDangerousGoods: true })
                        }
                      >
                        YES
                      </Button>
                      <Button
                        id="btnIsDangerousGoodsNo"
                        variant={!isDangerousGoods ? "contained" : "outlined"}
                        className="w-100 rounded-right-only mt-8"
                        onClick={() =>
                          updateDangerousGoodsData({ isDangerousGoods: false })
                        }
                      >
                        NO
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="d-flex w-100 mb-24">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel
                    component="legend"
                    className="font14 weight-500 mb-0"
                  >
                    Does the shipment contain radioactive material?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      variant={isRedioActive ? "contained" : "outlined"}
                      className="w-100 rounded-left-only mt-2"
                      id="btnIsRadioActiveYes"
                      onClick={() =>
                        updateDangerousGoodsData({ dgIsRadioactive: true })
                      }
                    >
                      YES
                    </Button>
                    <Button
                      variant={!isRedioActive ? "contained" : "outlined"}
                      className="w-100 rounded-right-only mt-2"
                      id="btnIsRadioActiveNo"
                      onClick={() =>
                        updateDangerousGoodsData({ dgIsRadioactive: false })
                      }
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </Box>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} className="mb-24">
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label="UN #"
                    variant="outlined"
                    name="dgUNNumber"
                    id="txtUnNumber"
                    value={dangerousGoodsData.dgUNNumber}
                    onChange={handleInputChange}
                    onKeyDown={handleUnNumberKeyDown}
                    onBlur={handleUNNumberFocusOut}
                    className="w-100"
                    error={!!formErrors.dgUNNumber}
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickOpen} id="btnDgAdd">
                            <AddIcon />
                          </IconButton>
                          <CommodityListDialog
                            open={open}
                            handleClose={handleClose}
                            onSelectCommodity={onSelectCommodity}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    className="w-100"
                    name="dgProperName"
                    id="txtDgProperName"
                    value={dangerousGoodsData.dgProperName}
                    onChange={handleInputChange}
                    error={!!formErrors.dgProperName}
                    inputProps={{ maxLength: 100 }}
                    InputProps={{
                      endAdornment: !!formErrors.dgProperName && (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}
                        >
                          <InfoRoundedIcon
                            style={{ color: red[500] }}
                            fontSize="small"
                          ></InfoRoundedIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={6}>
                  <TextField
                    label="Class"
                    variant="outlined"
                    className="w-100"
                    id="txtDgCommodityClass"
                    name="dgCommodityClass"
                    value={dangerousGoodsData.dgCommodityClass}
                    onChange={handleInputChange}
                    error={!!formErrors.dgCommodityClass}
                    inputProps={{ maxLength: 10 }}
                    InputProps={{
                      endAdornment: !!formErrors.dgCommodityClass && (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}
                        >
                          <InfoRoundedIcon
                            style={{ color: red[500] }}
                            fontSize="small"
                          ></InfoRoundedIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={6}>
                  <TextField
                    label="Trans IDX"
                    variant="outlined"
                    className="w-100"
                    id="txtTtransportationIndex"
                    name="dgTransportationIndex"
                    value={dangerousGoodsData.dgTransportationIndex}
                    inputProps={{
                      maxLength: 10,
                    }}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={6}>
                  <FormControl className="w-100">
                    <InputLabel>Limits</InputLabel>
                    <Select
                      label="Limits"
                      variant="outlined"
                      className="w-100"
                      id="ddDgTransportationLimit"
                      name="dgTransportationLimit"
                      value={dangerousGoodsData.dgTransportationLimit}
                      onChange={handleSelectChange}
                      error={!!formErrors.dgTransportationLimit}
                    >
                      <MenuItem value="P">
                        Passenger And Cargo Aircraft
                      </MenuItem>
                      <MenuItem value="C">Cargo Aircraft Only</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} className="mb-24">
              <Box className="d-flex flex-column gap-2 w-100">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel component="legend" className="font14 weight-500">
                    Does the shipment require dry ice?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      id="btnIsDryIceRequireYes"
                      variant={isDryIceRequire ? "contained" : "outlined"}
                      className="w-100 rounded-left-only"
                      onClick={() => {
                        setIsDryIceRequire(true);
                        updateDangerousGoodsData({ isDryIceRequire: true });
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      variant={!isDryIceRequire ? "contained" : "outlined"}
                      className="w-100 rounded-right-only"
                      id="btnIsDryIceRequireNo"
                      onClick={() => {
                        setIsDryIceRequire(false);
                        updateDangerousGoodsData({ isDryIceRequire: false });
                      }}
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                </FormControl>
                {isDryIceRequire && (
                  <Box className="d-flex flex-column gap-2 w-100">
                    <TextField
                      label="Amount of dry ice"
                      variant="outlined"
                      className="w-100 mt-3"
                      name="dgDryIceWeight"
                      id="txtDgDryIceWeight"
                      value={
                        dangerousGoodsData.dgDryIceWeight === null ||
                        dangerousGoodsData.dgDryIceWeight === 0
                          ? undefined
                          : dangerousGoodsData.dgDryIceWeight
                      }
                      onChange={handleInputChange}
                      onKeyDown={handleDecimalValue}
                      inputProps={{ maxLength: 9 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="dgIsDryIceProvide"
                          id="cbIsDryIceProvide"
                          checked={dangerousGoodsData.dgIsDryIceProvide}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="LFS/MNX to provide"
                      className="p-2"
                    />
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="d-flex flex-column gap-2 w-100">
                <FormControl component="fieldset" className="w-100 mt-2">
                  <FormLabel component="legend" className="font14 weight-500">
                    Does the shipment require gel packs?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      variant={isGelPacksRequire ? "contained" : "outlined"}
                      className="w-100 rounded-left-only"
                      id="btnIsGelPacksRequireYes"
                      onClick={() => {
                        setIsGelPacksRequire(true);
                        updateDangerousGoodsData({ isGelPacksRequire: true });
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      variant={!isGelPacksRequire ? "contained" : "outlined"}
                      className="w-100 rounded-right-only"
                      id="btnIsGelPacksRequireNo"
                      onClick={() => {
                        setIsGelPacksRequire(false);
                        updateDangerousGoodsData({ isGelPacksRequire: false });
                      }}
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                </FormControl>
                {isGelPacksRequire && (
                  <Box className="d-flex flex-column gap-2 w-100">
                    <TextField
                      label="Amount of gel packs"
                      variant="outlined"
                      className="w-100 mt-3"
                      name="dgGelPackCount"
                      id="txtDgGelPackCount"
                      value={dangerousGoodsData.dgGelPackCount}
                      InputLabelProps={{
                        shrink: !!dangerousGoodsData.dgGelPackCount,
                      }}
                      onChange={handleInputChange}
                      onKeyDown={handleDecimalValue}
                      inputProps={{ maxLength: 4 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="dgIsGelPackProvide"
                          id="cbIsGelPackProvide"
                          checked={dangerousGoodsData.dgIsGelPackProvide}
                          onChange={handleCheckboxChange}
                        />
                      }
                      label="LFS/MNX to provide"
                      className="p-2"
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className="d-flex flex-column w-100 border p16 rounded">
            <Grid item lg={12}>
              <Typography variant="h6" className="Sub-header">
                Dangerous Goods Packing Details
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Packing Group"
                    id="txtDgPackingGroup"
                    className="w-100"
                    name="dgPackingGroup"
                    value={dangerousGoodsData.dgPackingGroup}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 300 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Qty & Type of Packing"
                    id="txtDgQuantityAndPackType"
                    className="w-100"
                    name="dgQuantityAndPackType"
                    value={dangerousGoodsData.dgQuantityAndPackType}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 300 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                variant="outlined"
                label="Packing Instructions"
                placeholder="Enter any additional instructions"
                multiline
                id="txtDgPackingInst"
                rows={4}
                className="w-100 mt-16"
                name="dgPackingInst"
                value={dangerousGoodsData.dgPackingInst}
                onChange={handleInputChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                variant="outlined"
                label="Additional Handling Information"
                placeholder="Enter additional handling information instructions"
                multiline
                id="txtDgHandlingInfo"
                rows={4}
                className="w-100 mt-16"
                name="dgHandlingInfo"
                value={dangerousGoodsData.dgHandlingInfo}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 500,
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12} className="mt-16">
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Authorization"
                    className="w-100"
                    name="dgAuthorization"
                    id="txtDgAuthorization"
                    value={dangerousGoodsData.dgAuthorization}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 40 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Emergency Phone #"
                    className="w-100"
                    name="dgEmergencyPhone"
                    id="txtDgEmergencyPhone"
                    value={dangerousGoodsData.dgEmergencyPhone}
                    onChange={handleInputChange}
                    error={!!formErrors.dgEmergencyPhone}
                    inputProps={{ maxLength: 25 }}
                    InputProps={{
                      endAdornment: !!formErrors.dgEmergencyPhone && (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}
                        >
                          <InfoRoundedIcon
                            style={{ color: red[500] }}
                            fontSize="small"
                          ></InfoRoundedIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} className="mt-16">
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Title Signatory"
                    className="w-100"
                    name="dgSignatory"
                    id="txtDgSignatory"
                    value={dangerousGoodsData.dgSignatory}
                    onChange={handleInputChange}
                    error={!!formErrors.dgSignatory}
                    inputProps={{ maxLength: 80 }}
                    InputProps={{
                      endAdornment: !!formErrors.dgSignatory && (
                        <InputAdornment
                          position="end"
                          disablePointerEvents={true}
                        >
                          <InfoRoundedIcon
                            style={{ color: red[500] }}
                            fontSize="small"
                          ></InfoRoundedIcon>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <CustomDatePicker
                    handleDateChange={handleInputChange as any}
                    label="Date"
                    name="dgDate"
                    id="txtDgDate"
                    value={dangerousGoodsData.dgDate}
                    isClearable={true}
                    showError={!!formErrors.dgDate}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DangerousGoods;
