import React, { useEffect, useMemo, useRef, useState } from "react";
import { FormControl, FormControlLabel, Checkbox } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import arrowicon from "../../assets/images/logo/img_arrowright_white_a700.svg";
import { MySettingItemDto } from "../../models/long-form-item-dto";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";
import useServiceLogic from "../../shared/lookup/service/service.logic";
import { API_BASE_URL, MAX_COMPANY_ID } from "../../config/constants";
import Alert from "@mui/material/Alert";
import Loader from "../../shared/util/loader";
import useDefaultDataLogic from "../long-form-orders/long-form-order.logic";
import useAccounntsLogic from "../../shared/lookup/accounts/accounts.logic";
import {
  MultiOrderRequestDto,
  MultiOrderResponseDto,
  crateItemDto,
} from "../../models/multi-order-item-dto";
import { ServiceItemDto } from "../../models/service-item-dto";
import useMultiOrderLogic from "./multi-form-order-create.logic";
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../config/axios-interceptor";
import { downloadAndSavePDF } from "../../shared/util/download-pdf";
import { handleDecimalValue } from "../../shared/util/numeric-value";
import useCountryLogic from "../../shared/lookup/country/country.logic";
import { CountryItemDto } from "../../models/country-item-dto";
import CustomDatePicker from "../../shared/components/CustomDatePicker";
import moment from "moment";
import useZipCodeLogic from "../../shared/lookup/zip-code/zip-code.logic";
import {
  getCurrentDateByAbbr,
  getCurrentDateTimeByAbbr,
} from "../../shared/util/timezones";
import DeliveryDetails from "../../shared/components/multiple-order/DeliveryDetails";
import {
  checkStringHasCommentCharecter,
  validateMsgStringHasCommentCharecter,
} from "../../shared/util/common";
import {
  accountStatussToStopProcess,
  getAccountStatusMessage,
} from "../../shared/util/long-form ";

const MultiOrder: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { defaultData } = useDefaultDataLogic();
  const [defaultDatas, setDefaultDatas] = useState<MySettingItemDto>();
  const [deliveryZipCodeError, SetDeliveryZipCodeError] =
    useState<boolean>(false);
  const { service } = useServiceLogic();
  const [serviceData, setServiceData] = useState<ServiceItemDto[]>([]);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const { account } = useAccounntsLogic();
  const [accounts, setAccounts] = useState<any>([]);
  const [multiOrderData, setMultiOrderData] = useState<MultiOrderRequestDto>(
    {}
  );
  const token = useDecodeJWT();
  const { MultiOrderError, multiOrder } = useMultiOrderLogic();
  //const [open, setOpen] = React.useState(false);
  const [asap, setAsap] = useState(false);
  const [readyNow, setReadyNow] = useState<boolean>(false);
  const [holdFor, setHoldFor] = useState<boolean>(false);
  const [errorCode, setErrorCode] = useState<any>();
  // const [hasReadyDateTimeInteracted, setHasReadyDateTimeInteracted] =
  //   useState(false);
  // const [hasDeliveryDateTimeInteracted, setHasDeliveryDateTimeInteracted] =
  //   useState(false);
  const [zipCodeError, setZipCodeError] = useState<boolean>(false);
  // const [localError, setLocalError] = useState<string | null>(null);
  // const [deliveryLocalError, setDeliveryLocalError] = useState<string | null>(
  //   null
  // );
  const [multiOrderSubmitError, setMultiOrderSubmitError] = useState<any>({});
  const [helperError, setHelperError] = useState<any>();
  const [helperDelError, setHelperDelError] = useState<any>();
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  //const [isValid, setIsValid] = useState(true);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const childRef = useRef<any>(null);
  const errorRef: any = useRef(null);
  const navigate = useNavigate();
  const [createdOrderDetails, setCreatedOrderDetails] = useState<
    MultiOrderResponseDto[]
  >([]);
  const [crateDetails, setCrateDetails] = useState<crateItemDto[]>([
    { reference: "", weight: null, reference2: "", order: null },
    { reference: "", weight: null, reference2: "", order: null },
    { reference: "", weight: null, reference2: "", order: null },
    { reference: "", weight: null, reference2: "", order: null },
    { reference: "", weight: null, reference2: "", order: null },
  ]);
  const [countryData, setCountryData] = useState<CountryItemDto[]>([]);
  // const serviceAvailType = localStorage.getItem("serviceAvailType");
  const [deliveryData, setDeliveryData] = useState({
    deliveryAddress1: "",
    deliveryAddress2: "",
    deliveryAddress3: "",
    deliveryCityName: "",

    deliveryPostalCode: "",
    deliveryCountryID: "",
    deliveryStateProvID: "",
    deliveryCompanyName: "",
    deliveryInstructions: "",
    deliveryAirportID: "",
    deliveryEmailAddress: "",
    addressCode: "",
    deliveryPhoneNumber: "",
    deliveryAttention: "",
    deliveryRequestAsap: false,
    delKeep: false,
    deliveryFaxNumber: "",
    pickupReadyDay: "",
    deliveryRequestDay: "",
    requestedDeliveryTime: "",
    pickupReadyNow: false,
    serviceGUID: "",
  });
  const { country } = useCountryLogic();
  const [pickupReadyTime, setPickupReadyTime] = useState<any>();
  const [deliveryReadyTime, setDeliveryReadyTime] = useState<any>();
  const [servicesDependancyFinished, setServicesDependancyFinished] = useState({
    settingsAPI: false,
    pickUpPostalCode: true,
    deliveryPostalCode: true,
  });
  const { zipCode } = useZipCodeLogic();
  const [pickupStandardAbbrID, setPickupStandardAbbrID] = useState("");
  const getCountry = async () => {
    try {
      setIsLoading(true);
      const param = {};
      const response = await country(param);
      if (response.data && response.data.data) {
        setCountryData(response.data.data);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setCountryData([]);
    }
  };

  // Ready Now checkbox
  useEffect(() => {
    if (multiOrderData.pickupReadyNow) {
      setReadyNow(multiOrderData.pickupReadyNow);
    }
  }, [multiOrderData.pickupReadyNow]);

  // ASAP Checkbox
  useEffect(() => {
    if (multiOrderData.deliveryRequestAsap) {
      setAsap(multiOrderData.deliveryRequestAsap);
    }
  }, [multiOrderData.deliveryRequestAsap]);

  // Holdfor pickup
  useEffect(() => {
    if (multiOrderData.holdForPickup) {
      setHoldFor(multiOrderData.holdForPickup);
    }
  }, [multiOrderData.holdForPickup]);

  useEffect(() => {
    if (defaultDatas) {
      const serviceGUID = defaultDatas.serviceGUID;
      const service = serviceData.find((s) => s.serviceGUID === serviceGUID);
      setMultiOrderData((prevLongFormData) => ({
        ...prevLongFormData,
        shipperName: defaultDatas?.firstName + " " + defaultDatas?.lastName,
        shipperPhone: defaultDatas?.officeNumber,
        shipperEmail: defaultDatas?.emailAddress,
        serviceGUID: defaultDatas?.serviceGUID,
        serviceID: service ? service.serviceID : 0,
        weightUOM: defaultDatas?.weightUOM,
        sizeUOM: defaultDatas?.sizeUOM,
        distanceUOM: defaultDatas?.distanceUOM,
        accountNumber: defaultDatas?.accountNumber,
        contents: defaultDatas?.contents,
        puAddressCode: "",
        puCompanyName: defaultDatas?.pickupCompanyName,
        puAddress1: defaultDatas?.pickupAddress1,
        puAddress2: defaultDatas?.pickupAddress2,
        puAddress3: defaultDatas?.pickupAddress3,
        puCityName: defaultDatas?.pickupCityName,
        puStateProvID: defaultDatas?.pickupStateProvID,
        puPostalCode: defaultDatas?.pickupPostalCode,
        puCountryID: defaultDatas?.pickupCountryID,
        puPhoneNumber: defaultDatas?.pickupPhoneNumber,
        puEmailAddress: defaultDatas?.pickupEmailAddress,
        puFaxNumber: defaultDatas?.pickupFaxNumber,
        puPersonToSee: defaultDatas?.pickupPersonToSee,
        puInstructions: defaultDatas?.pickupInstructions,
        puResidential: false,
        delAddressCode: "",
        delCompanyName: defaultDatas?.deliveryCompanyName,
        delAddress1: defaultDatas?.deliveryAddress1,
        delAddress2: defaultDatas?.deliveryAddress2,
        delAddress3: defaultDatas?.deliveryAddress3,
        delCityName: defaultDatas?.deliveryCityName,
        deliveryCityName: defaultDatas?.deliveryCityName,
        delStateProvID: defaultDatas?.deliveryStateProvID,
        delPostalCode: defaultDatas?.deliveryPostalCode,
        delCountryID: defaultDatas?.deliveryCountryID,
        delPhoneNumber: defaultDatas?.deliveryPhoneNumber,
        delEmailAddress: defaultDatas?.deliveryEmailAddress,
        delFaxNumber: defaultDatas?.deliveryFaxNumber,
        delAttention: defaultDatas?.deliveryAttention,
        delInstructions: defaultDatas?.deliveryInstructions,
        delAirportID: defaultDatas.deliveryAirportID,
        delResidential: false,
        // readyDate: defaultDatas.pickupReadyTime,
        // readyTime: defaultDatas.pickupReadyTime
        //   ? null
        //   : defaultDatas.pickupReadyTime,
        dropOff: false,
        pickupReadyNow: defaultDatas.pickupReadyNow,
        deliveryRequestAsap: defaultDatas.deliveryRequestAsap,
        requestedDeliveryDate: defaultDatas.deliveryRequestTime,
        // requestedDeliveryTime: defaultDatas.deliveryRequestTime,
        deliverWhen: defaultDatas.deliverWhen,
        consigneeOnly: false,
        okLeave: false,
        holdForPickup: defaultDatas.holdForPickup,
        weight_References: [],
        insertUserID: Number(token.UserId),
        referenceTypeID: defaultDatas.referenceTypeID2,
        username: "",
        pickupReadyDay: defaultDatas.pickupReadyDay,
        deliveryRequestDay: defaultDatas.deliveryRequestDay,
      }));
      setDeliveryData((prevLongFormData) => ({
        ...prevLongFormData,
        deliveryAddress1: defaultDatas.deliveryAddress1,
        deliveryAddress2: defaultDatas.deliveryAddress2,
        deliveryAddress3: defaultDatas.deliveryAddress3,
        deliveryCityName: defaultDatas.deliveryCityName,
        deliveryPostalCode: defaultDatas.deliveryPostalCode,
        deliveryCountryID: defaultDatas.deliveryCountryID,
        deliveryStateProvID: defaultDatas.deliveryStateProvID,
        deliveryCompanyName: defaultDatas.deliveryCompanyName,
        deliveryInstructions: defaultDatas.deliveryInstructions,
        deliveryAirportID: defaultDatas.deliveryAirportID,
        delCityName: defaultDatas?.deliveryCityName,
        deliveryEmailAddress: defaultDatas.deliveryEmailAddress,
        addressCode: "",
        deliveryPhoneNumber: defaultDatas.deliveryPhoneNumber,
        deliveryAttention: defaultDatas.deliveryAttention,
        deliveryRequestAsap: defaultDatas.deliveryRequestAsap,
        delKeep: false,
        deliveryFaxNumber: defaultDatas.deliveryFaxNumber,
        pickupReadyNow: defaultDatas.pickupReadyNow,

        // requestedDeliveryDate: defaultDatas.deliveryRequestTime,
        // requestedDeliveryTime: defaultDatas.deliveryRequestTime,
      }));
      setReadyNow(defaultDatas?.pickupReadyNow);

      setHoldFor(defaultDatas?.holdForPickup);
      setAsap(defaultDatas?.deliveryRequestAsap);
      getDefaultZipCode(
        defaultDatas?.pickupPostalCode,
        defaultDatas?.pickupCountryID,
        defaultDatas?.pickupCityName
      );
    }
  }, [defaultDatas]);

  const handleDeliveryDataChange = (updatedData: any) => {
    const newData = {
      ...updatedData,
      // asap: updatedData.hasOwnProperty("asap") ? updatedData.asap : false,
    };
    setDeliveryData((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  useEffect(() => {
    if (deliveryData) {
      const serviceGUID = deliveryData.serviceGUID;
      const service = serviceData.find((s) => s.serviceGUID === serviceGUID);
      setMultiOrderData((prevLongFormData) => ({
        ...prevLongFormData,
        delAddressCode: deliveryData.addressCode,
        delCompanyName: deliveryData.deliveryCompanyName,
        delAddress1: deliveryData.deliveryAddress1,
        delAddress2: deliveryData.deliveryAddress2,
        delAddress3: deliveryData.deliveryAddress3,
        // requestedDeliveryDate: deliveryData.requestedDeliveryTime,
        // requestedDeliveryTime: deliveryData.requestedDeliveryTime,
        delCityName: deliveryData.deliveryCityName,
        deliveryCityName: deliveryData?.deliveryCityName,
        delStateProvID: deliveryData.deliveryStateProvID,
        delPostalCode: deliveryData.deliveryPostalCode,
        delCountryID: deliveryData.deliveryCountryID,
        delPhoneNumber: deliveryData.deliveryPhoneNumber,
        delEmailAddress: deliveryData.deliveryEmailAddress,
        delAttention: deliveryData.deliveryAttention,
        delInstructions: deliveryData.deliveryInstructions,
        delFaxNumber: deliveryData.deliveryFaxNumber,
        delAirportID: deliveryData.deliveryAirportID,
        pickupReadyDay: deliveryData.pickupReadyDay,
        deliveryRequestDay: deliveryData.deliveryRequestDay,
        pickupReadyNow: deliveryData?.pickupReadyNow,
        deliveryRequestAsap: deliveryData?.deliveryRequestAsap,
        serviceGUID: deliveryData?.serviceGUID,
        serviceID: service ? service.serviceID : 0,
      }));
    }
  }, [deliveryData]);

  useEffect(() => {
    // const filteredCrateDetails = crateDetails.filter(
    //   (crate) => crate.weight !== null && crate.reference2 !== ""
    // );

    const filteredCrateDetails = crateDetails.filter(
      (crate) => crate.reference2 !== ""
    );

    setMultiOrderData((prevLongFormData) => ({
      ...prevLongFormData,
      weight_References: filteredCrateDetails,
    }));
  }, [crateDetails]);

  // useEffect(() => {
  //   setMultiOrderData((prevLongFormData) => ({
  //     ...prevLongFormData,
  //     de: asap,
  //   }));
  // }, [asap]);

  useEffect(() => {
    getDefaultData();
    // getService();
    getAccounts();
    getCountry();
  }, []);

  useEffect(() => {
    //areAllServicesDepFinished:: Needed for stop the multiple api call onLoad
    const areAllServicesDepFinished = Object.values(
      servicesDependancyFinished
    ).every((value) => value === true);
    let debounceTimeout: NodeJS.Timeout;
    if (areAllServicesDepFinished) {
      debounceTimeout = setTimeout(() => {
        getService();
      }, 500); // 500ms delay
    }

    // Clear the timeout if one of the dependencies changes before the timeout finishes
    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [
    defaultDatas?.pickupCountryID,
    defaultDatas?.pickupCityName,
    defaultDatas?.pickupStateProvID,
    defaultDatas?.pickupPostalCode,
    defaultDatas?.pickupAirportID,
    deliveryData.deliveryCountryID,
    deliveryData.deliveryCityName,
    deliveryData.deliveryStateProvID,
    deliveryData.deliveryPostalCode,
    deliveryData.deliveryAirportID,
  ]);

  const handleServiceChange = (e: any) => {
    const serviceGUID = e.target.value;
    const service = serviceData.find((s) => s.serviceGUID === serviceGUID);
    if (service) {
      setMultiOrderSubmitError((prevErrors: any) => ({
        ...prevErrors,
        serviceGUID: null,
      }));
      setMultiOrderData((prevLongFormData) => ({
        ...prevLongFormData,
        serviceGUID: service.serviceGUID,
        serviceID: service.serviceID,
      }));
    }
  };

  const getService = async () => {
    try {
      const param: any = {
        companyID: MAX_COMPANY_ID,
        userGUID: token.UserGUID,
        serviceAvailType: "U",
        baseDateTime: new Date(),
        origCountryID: defaultDatas?.pickupCountryID,
        origCityName: defaultDatas?.pickupCityName,
        origStateProvID: defaultDatas?.pickupStateProvID,
        origPostalCode: defaultDatas?.pickupPostalCode,
        origAirportID: defaultDatas?.pickupAirportID,
        destCountryID: deliveryData.deliveryCountryID,
        destCityName: deliveryData.deliveryCityName,
        destStateProvID: deliveryData.deliveryStateProvID,
        destPostalCode: deliveryData.deliveryPostalCode,
        destAirportID: deliveryData.deliveryAirportID,
      };
      const response = await service(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        setServiceData(res);
      } else {
        setServiceData([]);
      }
    } catch (err) {
      setServiceData([]);
    }
  };

  const getAccounts = async () => {
    try {
      const param = {
        userId: Number(token.UserId),
        companyID: 0,
        useIncludeAllItemrId: false,
      };
      const response = await account(param);
      if (response.data && response.data.data) {
        setAccounts(response.data.data);
      } else {
        setAccounts([]);
      }
    } catch {
      setAccounts([]);
    }
  };

  const getDefaultData = async () => {
    try {
      setIsLoading(true);
      const param = {
        companyID: MAX_COMPANY_ID,
      };
      const response = await defaultData(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        setDefaultDatas(res);
      }
      setIsLoading(false);
      setServicesDependancyFinished((_prev) => ({
        ..._prev,
        settingsAPI: true,
      }));
    } catch {
      setDefaultDatas(undefined);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (errorCode) {
      scrollToTop();
      setErrorCode(errorCode);
    }
  }, [errorCode]);

  const getDefaultZipCode = async (
    selectedZipCode: string,
    selectedCountry: string,
    cityName: any
  ) => {
    try {
      if (selectedZipCode) {
        const param = {
          postalCode: selectedZipCode,
          countryID: selectedCountry,
        };
        const response = await zipCode(param);
        if (response.data && response.data.data) {
          const res = response.data.data;

          const timezone = res.find(
            (x: any) => x.cityName.toLowerCase() === cityName.toLowerCase()
          );
          setPickupStandardAbbrID(timezone?.standardAbbr);
        }
      }
    } catch (err) {}
  };

  const handleSubmit = async () => {
    if (errorCode) {
      // Optional: Scroll to errorRef if necessary
      errorRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        top: 50,
      });
      return; // Exit early if there is an errorCode
    }

    try {
      setIsLoading(true);
      const validationErrors = validateForm(multiOrderData, defaultDatas);

      setMultiOrderSubmitError((prevErrors: any) => ({
        ...prevErrors,
        ...validationErrors,
      }));

      if (Object.keys(validationErrors).length > 0) {
        scrollToTop();
      } else {
        if (helperDelError || helperError) {
          return;
        }
        setIsLoadingBtn(true);
        if (multiOrderData.pickupReadyNow) {
          const currentDateTime = new Date();
          multiOrderData.readyDate = currentDateTime;
          multiOrderData.readyTime = currentDateTime;
        }
        if (multiOrderData.deliveryRequestAsap) {
          const currentDateTime = new Date();
          multiOrderData.requestedDeliveryDate = currentDateTime;
          multiOrderData.requestedDeliveryTime = currentDateTime;
        }
        const params = {
          ...multiOrderData,
          readyDate: moment(multiOrderData.readyTime).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          readyTime: moment(multiOrderData.readyTime).format(
            "YYYY-MM-DDTHH:mm:ss"
          ),
          requestedDeliveryDate: moment(
            multiOrderData.requestedDeliveryDate
          ).format("YYYY-MM-DDTHH:mm:ss"),
          requestedDeliveryTime: moment(
            multiOrderData.requestedDeliveryDate
          ).format("YYYY-MM-DDTHH:mm:ss"),
        };

        const selectedAcc = accounts.find(
          (_acc: any) => _acc.accountNumber == multiOrderData?.accountNumber
        );

        // stop the submit process if selected customer account is inactive
        if (
          selectedAcc &&
          accountStatussToStopProcess.includes(selectedAcc?.accountStatus)
        ) {
          scrollToTop();
          const erroMsg = getAccountStatusMessage(selectedAcc?.accountStatus);
          setMultiOrderSubmitError([erroMsg]);
          return;
        }

        const response = await multiOrder(params);
        if (response.data && response.data.data) {
          const res = response.data;
          // setOpen(true);
          setIsSubmitted(true);
          setSuccessMessage(res.message);
          setCreatedOrderDetails(res.data);
          setDeliveryData((prevLongFormData: any) => ({
            ...prevLongFormData,
            deliveryInstructions: res.data[0].delInstructions,
          }));
        }
        if (response.message) {
        }
        setIsLoadingBtn(false);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setIsLoadingBtn(false);
    } finally {
      setIsLoadingBtn(false);
    }
    scrollToTop();
  };

  const isPastDate = (dateStr: string | any) => {
    const selectedDate = new Date(dateStr);
    const now = new Date();
    return selectedDate < now;
  };

  const validateForm = (data: any, defaultDatas: any) => {
    let errors: any = {};

    if (!data.delCompanyName) {
      errors.delCompanyName = "Please enter a Delivery Company Name.";
    }
    if (!data.delPostalCode) {
      errors.delPostalCode = "Please enter a Delivery ZIP Code.";
    }
    if (!data.deliveryCityName) {
      errors.delCityName = "Please select a Delivery City.";
    }
    const selectedDateTime = moment(data?.readyTime).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    const currentDateTime = getCurrentDateTimeByAbbr(pickupStandardAbbrID);

    if (selectedDateTime < currentDateTime) {
      errors.readyDate = "The ready time cannot be in the past.";
    }

    if (
      !data.readyTime &&
      errors.readyDate != "The ready time cannot be in the past."
    ) {
      if (!data.pickupReadyNow) {
        errors.readyDate = "Please specify a Shipment Ready Time.";
      }
    }
    if (!asap && !data.requestedDeliveryDate && helperDelError == null) {
      errors.requestedDeliveryDate =
        "Please specify a Requested Delivery Time.";
    }
    if (!data.serviceGUID) {
      errors.serviceGUID = "Please specify a Shipment Service Level.";
    }
    if (!data.shipperName) {
      errors.shipperName = "Please enter a Shipper Name.";
    }
    if (!data.shipperEmail) {
      errors.shipperEmail = "Please enter a Shipper Email Address.";
    }
    if (data.weight_References.length === 0) {
      errors.weight_References = "Please provide a weight for each crate.";
    } else if (data.weight_References.length > 0) {
      const invalidEntries = data.weight_References.some(
        (entry: any) => entry.reference2 && !entry.weight
      );

      if (invalidEntries) {
        errors.weight_References = "Please provide a weight for each crate.";
      }
    }

    // if (!data.delCompanyName) {
    //   errors.delCompanyName = "Please enter a Delivery Company Name.";
    // }
    // if (!data.puCompanyName) {
    //   errors.puCompanyName = "Please enter a Pickup Company Name.";
    // }
    // if (!data.puCityName) {
    //   errors.puCityName = "Please select a Pickup City.";
    // }
    // if (!data.puPostalCode) {
    //   errors.puPostalCode = "Please enter a Pickup Postal Code.";
    // }
    // if (!data.delPostalCode) {
    //   errors.delPostalCode = "Please enter a Delivery Postal Code.";
    // }
    // if (!data.delCityName) {
    //   errors.delCityName = "Please select a Delivery City.";
    // }
    // if (!data.serviceGUID) {
    //   errors.serviceGUID = "Please specify a Shipment Service Level.";
    // }
    // if(!data.puReadyTime){
    //   errors.puReadyTime="Please specify a Shipment Ready Time."
    // }
    // if(!data.delRequestTime){
    //   errors.delRequestTime="Please specify a Shipment Delivery Time."
    // }
    // if (!data.weight) {
    //   errors.weight = "Please enter a package weight.";
    // }
    // if (!data.commodity) {
    //   errors.commodity = "commodity is required.";
    // }
    // if (!data.textPhoneNumber) {
    //   errors.textPhoneNumber = "Phone Number is required.";
    // }

    // Pickup Validation
    const missingFields = [];
    if (!defaultDatas?.pickupCompanyName) missingFields.push("Company Name");
    if (!defaultDatas?.pickupAddress1) missingFields.push("Address");
    if (!defaultDatas?.pickupCityName) missingFields.push("City");
    if (!defaultDatas?.pickupStateProvID) missingFields.push("State");
    if (!defaultDatas?.pickupPostalCode) missingFields.push("ZIP Code");
    if (!defaultDatas?.pickupCountryID) missingFields.push("Country");

    if (missingFields.length > 0) {
      errors.pickupDetails = `A Pickup Address is required to place your order. Please go to the "My Settings" page and enter a Pickup Address.`;
    }

    if (data?.delInstructions) {
      const isError = checkStringHasCommentCharecter(data?.delInstructions);
      if (isError) {
        errors.deliveryInstructions = validateMsgStringHasCommentCharecter;
      }
    }

    if (childRef.current) {
      // const isValid = childRef.current.validate(); // Call validate function in ChildComponent
      // setIsValid(isValid);
    }

    // if(errors){
    //   setIsValid(true);
    //   setIsSubmitted(true);
    // }

    return errors;
  };

  useEffect(() => {
    const updatedCrateDetails = crateDetails.map((item, index) => {
      if (item.weight !== null) {
        item.order = createdOrderDetails[index]?.shipmentNumber ?? null;
      }
      return item;
    });

    setCrateDetails(updatedCrateDetails);
  }, [createdOrderDetails]);

  const handleAddMore = () => {
    const newCrate = {
      reference: "",
      weight: null,
      reference2: "",
    };
    setCrateDetails([...crateDetails, newCrate]);
  };

  // ASAP Checkbox
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //setDeliveryLocalError(null);
    const { checked } = event.target;
    if (multiOrderSubmitError.requestedDeliveryDate) {
      setMultiOrderSubmitError((prevErrors: any) => ({
        ...prevErrors,
        requestedDeliveryDate: null,
      }));
    }
    setAsap(checked);
    if (checked) {
      setMultiOrderData((prevData) => ({
        ...prevData,
        requestedDeliveryDate: "",
        requestedDeliveryTime: "",
        deliveryRequestAsap: true,
      }));
      setDeliveryData((prevData) => ({
        ...prevData,
        requestedDeliveryDate: "",
        requestedDeliveryTime: "",
        deliveryRequestAsap: true,
      }));
      setHelperDelError(null);
    } else {
      setMultiOrderData((prevData) => ({
        ...prevData,
        requestedDeliveryDate: "",
        requestedDeliveryTime: "",
        deliveryRequestAsap: false,
      }));
      setDeliveryData((prevData) => ({
        ...prevData,
        requestedDeliveryDate: "",
        requestedDeliveryTime: "",
        deliveryRequestAsap: false,
      }));
    }
  };

  // Hold for Pickup Checkbox
  const handleCheckboxHoldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //setDeliveryLocalError(null);
    const { name, checked } = e.target;
    setHoldFor(checked);
    if (checked) {
      setMultiOrderData((prevLongFormData) => ({
        ...prevLongFormData,
        [name]: checked,
      }));
    }
  };

  // Ready Now Checkbox
  const handleCheckboxReadyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = event.target;
    if (multiOrderSubmitError.readyDate) {
      setMultiOrderSubmitError((prevErrors: any) => ({
        ...prevErrors,
        readyDate: null,
      }));
    }

    // setLocalError(null);
    setReadyNow(checked);
    setPickupReadyTime(null);
    if (checked) {
      setMultiOrderData((prevData) => ({
        ...prevData,
        readyDate: null,
        readyTime: null,
        pickupReadyNow: true,
      }));
      setDeliveryData((prevData) => ({
        ...prevData,
        readyDate: null,
        readyTime: null,
        pickupReadyNow: true,
      }));
      setHelperError(null);
    } else {
      setMultiOrderData((prevData) => ({
        ...prevData,
        readyDate: null,
        readyTime: null,
        pickupReadyNow: false,
      }));
      setDeliveryData((prevData) => ({
        ...prevData,
        readyDate: null,
        readyTime: null,
        pickupReadyNow: false,
      }));
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "readyDate") {
      const selectedDateTime = new Date(value);
      const currentDateTime = new Date();
      if (selectedDateTime < currentDateTime) {
        // setHelperError("The ready time cannot be in the past.");
        setMultiOrderSubmitError((prevErrors: any) => ({
          ...prevErrors,
          readyDate: null,
        }));
        setMultiOrderData((prevLongFormData) => ({
          ...prevLongFormData,
          readyTime: value,
          readyDate: value,
        }));
      } else {
        setMultiOrderSubmitError((prevErrors: any) => ({
          ...prevErrors,
          readyDate: null,
        }));
        setHelperError(null);
        setMultiOrderData((prevLongFormData) => ({
          ...prevLongFormData,
          readyTime: value,
          readyDate: value,
        }));
      }
    }
    if (name === "requestedDeliveryDate") {
      const selectedDateTime = new Date(value);
      const currentDateTime = new Date();
      setMultiOrderSubmitError((prevErrors: any) => ({
        ...prevErrors,
        requestedDeliveryDate: null,
      }));
      if (selectedDateTime < currentDateTime && value) {
        // setDeliveryLocalError("The delivery time cannot be in the past.");
        setHelperDelError("The delivery time cannot be in the past.");
      } else {
        setHelperDelError(null);
        setMultiOrderData((prevLongFormData) => ({
          ...prevLongFormData,
          requestedDeliveryTime: value,
          requestedDeliveryDate: value,
        }));
      }
    }
    // if (name === "requestedDeliveryDate") {
    //   setMultiOrderData((prevLongFormData) => ({
    //     ...prevLongFormData,
    //     requestedDeliveryTime: value,
    //   }));
    // }
    setMultiOrderData((prevLongFormData) => ({
      ...prevLongFormData,
      [name]: value,
    }));
  };

  const handleCrateChange = (index: number, e: any) => {
    const { name, value } = e.target;
    const newCrateDetails = [...crateDetails];

    if (name === "reference2") {
      newCrateDetails[index].reference2 = value;
      setMultiOrderSubmitError((prevErrors: any) => ({
        ...prevErrors,
        weight_References: null,
      }));
    } else if (name === "weight") {
      newCrateDetails[index].weight = value;
      setMultiOrderSubmitError((prevErrors: any) => ({
        ...prevErrors,
        weight_References: null,
      }));
    }

    setCrateDetails(newCrateDetails);
  };

  const handleDecimalValueBlur = (
    event: React.FocusEvent<HTMLInputElement> | any,
    index: number = 0
  ) => {
    const { name, value } = event.target;
    let weight = value;
    if (
      weight.startsWith(".") ||
      weight.endsWith(".") ||
      weight.includes(".")
    ) {
      weight = parseFloat(weight);
      const syntheticEvent = {
        target: {
          name: name,
          value: weight ? weight : 0,
        },
      } as any;
      handleCrateChange(index, syntheticEvent);
    }
  };

  useEffect(() => {
    if (!isLoading && defaultDatas) {
      const missingFields: string[] = [];
      if (!defaultDatas.pickupCompanyName) missingFields.push("Company Name");
      if (!defaultDatas.pickupAddress1) missingFields.push("Address");
      if (!defaultDatas.pickupCityName) missingFields.push("city");
      if (!defaultDatas.pickupStateProvID) missingFields.push("state");
      if (!defaultDatas.pickupPostalCode) missingFields.push("zipcode");
      if (!defaultDatas.pickupCountryID) missingFields.push("country");

      if (missingFields.length > 0) {
        setMultiOrderSubmitError((prev: any) => ({
          ...prev,
          pickupDetails: `A Pickup Address is required to place your order. Please go to the "My Settings" page and enter a Pickup Address.`,
        }));
      } else {
        setMultiOrderSubmitError((prev: any) => ({
          ...prev,
          pickupDetails: null,
        }));
      }
    }
  }, [defaultDatas, isLoading]);

  const handleZipCodeDataChange = (data: any) => {
    if (data.length > 0) {
      SetDeliveryZipCodeError(false);
    } else {
      SetDeliveryZipCodeError(true);
    }
  };

  useEffect(() => {
    if (MultiOrderError) {
      setMultiOrderSubmitError({ errorMessage: MultiOrderError });
    }
  }, [MultiOrderError]);

  const scrollToTop = () => {
    errorRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      top: 50,
    });
  };

  const printReport = async () => {
    if (createdOrderDetails.length > 0) {
      try {
        const shipmentGUIDs = createdOrderDetails.map((order) => ({
          shipmentGuid: order.shipmentGUID,
          pickupGUID: "",
          manifestGUID: "",
        }));

        const param = {
          companyID: MAX_COMPANY_ID,
          userId: Number(token.UserId),
          batchType: "SHIPLABEL",
          shipmentGUIDs: shipmentGUIDs,
        };
        const response = await axiosInstance.post(
          `${API_BASE_URL}/api/Report/api/PrintBatch`,
          param,
          { responseType: "arraybuffer" }
        );
        if (response.status) {
          const pdfTitle = `SHIPLABEL.pdf`;
          const result = await downloadAndSavePDF(response.data, pdfTitle);

          if (result.error !== "") {
          }
        }
      } catch (error: any) {}
    }
  };

  useEffect(() => {
    if (isSubmitted) {
      setMultiOrderSubmitError({});
      setZipCodeError(false);
    }
  }, [isSubmitted]);

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage("");
      }, 5000);
    }
  }, [successMessage]);

  const addDaysToDate = (date: Date, days: number) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + days);
    return newDate;
  };

  // Shipment Ready Time
  const calculateDisplayDate = () => {
    if (
      !multiOrderData.readyDate ||
      isNaN(Date.parse(multiOrderData.readyDate))
    )
      return "";
    const pickupReadyTime = new Date(multiOrderData.readyDate);
    // const daysToAdd = Number(multiOrderData.pickupReadyDay) || 0;
    // const newDate = addDaysToDate(pickupReadyTime, daysToAdd);
    return pickupReadyTime;
  };

  const isPastDateBasedOnTimezone = (
    readyDate: any,
    pickupStandardAbbrID: string
  ) => {
    const selectedDateTime = moment(readyDate).format("YYYY-MM-DD HH:mm:ss");
    const currentDateTime = getCurrentDateTimeByAbbr(pickupStandardAbbrID);

    let returnValue = false;

    if (selectedDateTime < currentDateTime) {
      returnValue = true;
    }
    return returnValue;
  };
  useEffect(() => {
    // const displayValue = readyNow
    //   ? ""
    //   : isPastDateBasedOnTimezone(
    //       multiOrderData.readyDate,
    //       pickupStandardAbbrID
    //     )
    //   ? null
    //   : calculateDisplayDate();

    const displayDeliveryValue = asap
      ? ""
      : isPastDate(multiOrderData.requestedDeliveryDate)
      ? ""
      : calculateDisplayDeliveryDate();

    // setPickupReadyTime(displayValue);
    setDeliveryReadyTime(displayDeliveryValue);

    setMultiOrderData((prevLongFormData) => ({
      ...prevLongFormData,
      // readyDate: displayValue,
      requestedDeliveryDate: displayDeliveryValue,
    }));
  }, [
    //multiOrderData.readyDate,
    multiOrderData.requestedDeliveryDate,
  ]);

  // const displayValue = readyNow
  //   ? ""
  //   : isPastDate(multiOrderData.readyDate)
  //   ? ""
  //   : calculateDisplayDate();

  // Delivery Request Time
  const calculateDisplayDeliveryDate = () => {
    if (
      !multiOrderData.requestedDeliveryDate ||
      isNaN(Date.parse(multiOrderData.requestedDeliveryDate))
    )
      return "";
    const deliveryDate = new Date(multiOrderData.requestedDeliveryDate);
    // const daysToAdd = Number(multiOrderData.deliveryRequestDay) || 0;
    // const newDate = addDaysToDate(deliveryDate, daysToAdd);
    return deliveryDate;
  };

  // const displayDeliveryValue = asap
  //   ? ""
  //   : isPastDate(multiOrderData.requestedDeliveryDate)
  //   ? ""
  //   : calculateDisplayDeliveryDate();

  const handleRequestDeliveryTimeChange = (data: any) => {
    setAsap(false);
    setReadyNow(false);

    setDeliveryData((prevData) => ({
      ...prevData,
      pickupReadyNow: false,
      deliveryRequestAsap: false,
    }));
    const {
      pickupReadyTime,
      pickupReadyDay,
      deliveryRequestTime,
      deliveryRequestDay,
    } = data;

    //const currentDateTime = getCurrentDateTimeByAbbr(pickupStandardAbbrID);
    const processDate = (timeStr: string, dayOffset: number) => {
      const [hours, minutes] = timeStr.split("T")[1].split(":").map(Number);
      const baseDate = new Date();
      baseDate.setHours(hours, minutes, 0, 0);
      const finalDate = addDaysToDate(baseDate, dayOffset); // Add days to the current base date
      return formatDateToCustomString(finalDate); // Ensure custom formatting is used
    };

    if (pickupReadyTime || pickupReadyDay) {
      const combinedDateTime = getCurrentDateByAbbr(
        pickupReadyTime,
        pickupStandardAbbrID,
        pickupReadyDay
      );

      const displayValue = isPastDateBasedOnTimezone(
        combinedDateTime,
        pickupStandardAbbrID
      )
        ? null
        : combinedDateTime;

      setPickupReadyTime(displayValue);
      setMultiOrderData((prevLongFormData) => ({
        ...prevLongFormData,
        readyTime: displayValue,
        readyDate: displayValue,
      }));

      // Reset errors
      setMultiOrderSubmitError((prevErrors: any) => ({
        ...prevErrors,
        readyDate: null,
      }));
      setHelperError(null);
    } else {
      const displayValue = readyNow
        ? ""
        : isPastDate(multiOrderData.readyDate)
        ? ""
        : calculateDisplayDate();

      setPickupReadyTime(displayValue || null);
    }

    // For pickup ready time
    // if (pickupReadyTime && pickupReadyDay) {
    //   const processedPickupReadyTime = processDate(
    //     pickupReadyTime,
    //     pickupReadyDay
    //   );
    //   // Set states and update multiOrderData
    //   setPickupReadyTime(processedPickupReadyTime);
    //   setMultiOrderData((prevLongFormData) => ({
    //     ...prevLongFormData,
    //     readyTime: processedPickupReadyTime,
    //     readyDate: processedPickupReadyTime,
    //   }));

    //   // Reset errors
    //   setMultiOrderSubmitError((prevErrors: any) => ({
    //     ...prevErrors,
    //     readyDate: null,
    //   }));
    //   setHelperError(null);
    // } else {
    //   const displayValue = readyNow
    //     ? ""
    //     : isPastDate(multiOrderData.readyDate)
    //     ? ""
    //     : calculateDisplayDate();

    //   setPickupReadyTime(displayValue || null);
    // }

    // For delivery request time
    if (deliveryRequestTime || deliveryRequestDay) {
      const processedDeliveryReadyTime = processDate(
        deliveryRequestTime,
        deliveryRequestDay
      );

      setDeliveryReadyTime(processedDeliveryReadyTime);
      setMultiOrderData((prevLongFormData) => ({
        ...prevLongFormData,
        requestedDeliveryDate: processedDeliveryReadyTime,
        requestedDeliveryTime: processedDeliveryReadyTime,
      }));

      // Reset errors
      setMultiOrderSubmitError((prevErrors: any) => ({
        ...prevErrors,
        requestedDeliveryDate: null,
      }));
      setHelperDelError(null);
    } else {
      const displayDeliveryValue = asap
        ? ""
        : isPastDate(multiOrderData.requestedDeliveryDate)
        ? ""
        : calculateDisplayDeliveryDate();

      setDeliveryReadyTime(displayDeliveryValue || null);
    }
  };
  // interface MenuStyles {
  //   maxHeight: string;
  // }

  // const [menuStyles, setMenuStyles] = useState<MenuStyles>({
  //   maxHeight: "200px", // Default max height for desktop
  // });

  const menuStyles = {
    maxHeight: "200px", // Default max height for desktop
  };
  // Helper function to format date
  const formatDateToCustomString = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  };

  const isClearDateValue = useMemo(() => {
    return readyNow || !pickupReadyTime;
  }, [readyNow, pickupReadyTime]);

  return (
    <>
      {/* main content section */}
      {/* <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar> */}
      <div className="order-form Multi-Order-form">
        <Box className="d-flex flex-column w-100 " ref={errorRef}>
          <Box className="d-flex">
            {isSubmitted ? (
              <Typography
                variant="h4"
                color="primary"
                className="d-flex font48 main-header"
                style={{ whiteSpace: "nowrap" }}
              >
                {`PICKUP #${createdOrderDetails[0].pickupNumber}`}
              </Typography>
            ) : (
              <Typography
                variant="h4"
                color="primary"
                className="d-flex font48 main-header"
                style={{ whiteSpace: "nowrap" }}
              >
                New Multi Order
              </Typography>
            )}
            {isSubmitted && (
              <Box className="d-flex flex-end gap-16 WidthRightTrackinput">
                <Button
                  variant="outlined"
                  color="primary"
                  className="fw-bold"
                  onClick={() => navigate("/active-orders")}
                  id="btnViewActiveOrders"
                >
                  VIEW ACTIVE ORDERS
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<DownloadIcon />}
                  className="fw-bold"
                  onClick={printReport}
                  id="btnDownloadLabels"
                >
                  DOWNLOAD LABELS
                </Button>
              </Box>
            )}
          </Box>
          {successMessage && (
            <>
              {/* <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  variant="filled"
                  sx={{ width: "100%" }}
                >
                  {"Multiple order created successfully."}
                </Alert>
              </Snackbar> */}

              <Box className="w-100">
                <Alert variant="filled" severity="success" id="txtMfSuccess">
                  {"Order created successfully."}
                </Alert>
              </Box>
            </>
          )}
          {(deliveryZipCodeError ||
            zipCodeError ||
            errorCode ||
            Object.keys(multiOrderSubmitError).filter(
              (key) => multiOrderSubmitError[key] !== null
            ).length > 0) && (
            <Box className="w-100">
              <Alert variant="filled" severity="error" id="txtMfValidation">
                {Object.keys(multiOrderSubmitError)
                  .filter((key) => multiOrderSubmitError[key] !== null)
                  .map((key) => (
                    <div key={key}>{multiOrderSubmitError[key]}</div>
                  ))}
                {errorCode && <div>Invalid Address Code entered.</div>}
                {deliveryZipCodeError && (
                  <div>
                    You have entered an invalid Delivery Zip Code. Please ensure
                    you have entered the proper Zip Code. If you continue to
                    experience issues, please contact MNX.
                  </div>
                )}
              </Alert>
            </Box>
          )}

          {/* pickup delivery section */}
          {defaultDatas ? (
            <Box className="d-flex flex-column align-items-start">
              <Typography
                variant="h5"
                color="primary"
                className="pickup_section"
              >
                1. Pickup & Delivery
              </Typography>

              <Box className="d-flex gap-16 mt-3 w-100 flex-col align-items-start pb-32 Divider">
                <div className="width50">
                  <DeliveryDetails
                    onDeliveryDataChange={handleDeliveryDataChange}
                    DefaultData={defaultDatas}
                    DeliveryData={deliveryData}
                    formErrors={multiOrderSubmitError}
                    setFormErrors={setMultiOrderSubmitError}
                    componentCalledFrom="MultiOrder"
                    onZipCodeDataChange={handleZipCodeDataChange}
                    deliveryZipCodeError={deliveryZipCodeError}
                    SetDeliveryZipeCodeError={SetDeliveryZipCodeError}
                    countryData={countryData}
                    errorCode={errorCode}
                    setErrorCode={setErrorCode}
                    onRequestTimeChange={handleRequestDeliveryTimeChange}
                  />
                </div>
                <div className="d-flex flex-column-view width50">
                  <div className="flex-column-view gap-16 d-flex Flex-row">
                    <Box className="d-flex flex-column border rounded p16 Flex-50">
                      <Typography variant="h6" className="Sub-header">
                        Shipment Ready Time
                      </Typography>

                      <CustomDatePicker
                        id="txtReadyDate"
                        handleDateChange={handleChange}
                        label="Ready Date"
                        name="readyDate"
                        value={readyNow ? null : pickupReadyTime}
                        isClearable={false}
                        isClearValue={isClearDateValue}
                        isDisable={readyNow}
                        helperText={helperError}
                        showError={
                          multiOrderSubmitError.readyDate || helperError
                        }
                        showTimeSelect={true}
                        minDate={new Date()}
                        isMultiOrderScreen={true}
                      />
                      <Box className="d-flex gap-2 mt-2 h-42 order_chkbx">
                        <FormControlLabel
                          className="chk_bx_50"
                          control={
                            <Checkbox
                              name="readyNow"
                              checked={readyNow}
                              onChange={handleCheckboxReadyChange}
                              id="cbReadyNow"
                            />
                          }
                          label="Ready Now"
                        />
                      </Box>
                      {/* <TextField
                        id="datetime-local"
                        label="Ready Date"
                        type="datetime-local"
                        variant="outlined"
                        name="readyDate"
                        InputLabelProps={{ shrink: true }}
                        value={displayValue}  // Clear the value if readyNow is true
                        error={!!localError || multiOrderSubmitError.readyDate && !!!multiOrderData.readyDate}
                        disabled={readyNow}
                        helperText={localError}
                        inputProps={{
                          min: multiOrderData.readyDate || new Date().toISOString().slice(0, 16), // Disable past date and time if readyDate is provided
                        }}
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <IconButton>
                                <img
                                  src={calendarIcon}
                                  alt="Calendar icon"
                                  style={{ maxWidth: "100%" }}
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                              style: {
                                color: localError || multiOrderSubmitError.readyDate ? "#d32f2f" : "inherit",
                              },
                        }}
                      /> */}
                    </Box>
                    <Box className="Flex-50">
                      <Box className="d-flex flex-column border rounded p16">
                        <Typography variant="h6" className="Sub-header">
                          Requested Delivery Time
                        </Typography>
                        <Box className="d-flex gap-2">
                          <Grid container spacing={2}>
                            <Grid item lg={6} sm={12} md={12} xs={12}>
                              <Select
                                id="ddDeliveryWhen"
                                variant="outlined"
                                defaultValue="B"
                                className="w-100"
                                name="deliveryWhen"
                                disabled={asap}
                                value={multiOrderData.deliverWhen}
                                onChange={(e) =>
                                  setMultiOrderData((prevLongFormData) => ({
                                    ...prevLongFormData,
                                    deliverWhen: e.target.value,
                                  }))
                                }
                              >
                                <MenuItem value="B" id="liMfBefore">
                                  Before
                                </MenuItem>
                                <MenuItem value="A" id="liMfAfter">
                                  After
                                </MenuItem>
                                <MenuItem value="T" id="liMfAt">
                                  At
                                </MenuItem>
                              </Select>
                            </Grid>
                            <Grid item lg={6} sm={12} md={12} xs={12}>
                              <CustomDatePicker
                                handleDateChange={handleChange}
                                label="Requested Delivery Time"
                                id="txtDeliveryDate"
                                name="requestedDeliveryDate"
                                value={deliveryReadyTime}
                                isClearable={
                                  !deliveryReadyTime ||
                                  deliveryReadyTime == null
                                }
                                isClearValue={!deliveryReadyTime}
                                helperText={helperDelError}
                                showError={
                                  (multiOrderSubmitError.requestedDeliveryDate &&
                                    !asap &&
                                    !!!multiOrderData.requestedDeliveryDate) ||
                                  helperDelError
                                }
                                showTimeSelect={true}
                                isDisable={asap}
                                minDate={new Date().toISOString().slice(0, 16)}
                              />
                            </Grid>
                          </Grid>

                          {/* <TextField
                            id="datetime-local"
                            label="Requested Delivery Time"
                            type="datetime-local"
                            className="w-100"
                            name="requestedDeliveryDate"
                            InputLabelProps={{ shrink: true }}
                            value={displayDeliveryValue}
                            error={!!deliveryLocalError ||
                              multiOrderSubmitError.requestedDeliveryDate &&
                              !asap &&
                              !!!multiOrderData.requestedDeliveryDate
                            }
                            helperText={deliveryLocalError}
                            onChange={handleChange}
                            disabled={asap}
                            inputProps={{
                              min: multiOrderData.requestedDeliveryDate || new Date().toISOString().slice(0, 16), // Disable past date and time if requestedDeliveryDate is provided
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <IconButton>
                                    <img
                                      src={calendarIcon}
                                      alt="Calendar icon"
                                      style={{ maxWidth: "100%" }}
                                    />
                                  </IconButton>
                                </InputAdornment>
                              ),
                              style: {
                                color: deliveryLocalError || multiOrderSubmitError.requestedDeliveryDate ? "#d32f2f" : "inherit",
                              },
                            }}
                          /> */}
                        </Box>
                        <Box className="d-flex gap-2 mt-2">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={asap}
                                name="deliveryRequestAsap"
                                onChange={handleCheckboxChange}
                                id="cbAsap"
                              />
                            }
                            label="ASAP"
                            className="p-2"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={holdFor}
                                name="holdForPickup"
                                onChange={handleCheckboxHoldChange}
                                id="cbHoldForPu"
                              />
                            }
                            label="Hold for PU"
                            className="p-2"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </div>
                  <div className="flex-column gap-16 d-flex width-100 mt-16">
                    <Typography variant="h5" color="primary" className="ml-8">
                      2. Billing & Service
                    </Typography>

                    <Box className="flex-column-view gap-16 d-flex Flex-row">
                      <Box className="d-flex flex-column Flex-50 border p16 rounded">
                        <Typography variant="h6" className="Sub-header">
                          Billing
                        </Typography>
                        <FormControl size="medium" fullWidth>
                          <Select
                            id="ddAccount"
                            labelId="demo-select-small-label"
                            onChange={(e) =>
                              setMultiOrderData((prevLongFormData) => ({
                                ...prevLongFormData,
                                accountNumber: e.target.value,
                              }))
                            }
                            name="accountNumber"
                            defaultValue={defaultDatas.accountNumber}
                            fullWidth
                            className="w-100 mt-2"
                            MenuProps={{
                              anchorOrigin: {
                                vertical: "bottom",
                                horizontal: "left",
                              },
                              transformOrigin: {
                                vertical: "top",
                                horizontal: "left",
                              },
                              PaperProps: {
                                style: menuStyles,
                              },
                            }}
                          >
                            {accounts.length > 0 &&
                              accounts.map((account: any) => (
                                <MenuItem
                                  key={account.userAccountRowGUID}
                                  value={account.accountNumber}
                                >
                                  {account.displayName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box className="d-flex flex-column Flex-50 border p16 rounded">
                        <Typography variant="h6" className="Sub-header">
                          Select a Service
                        </Typography>
                        <Select
                          id="ddService"
                          variant="outlined"
                          name="serviceGUID"
                          value={
                            multiOrderData.serviceGUID &&
                            multiOrderData.serviceGUID != "null"
                              ? multiOrderData.serviceGUID
                              : ""
                          }
                          error={
                            multiOrderSubmitError.serviceGUID &&
                            !!!multiOrderData.serviceGUID
                          }
                          defaultValue={
                            defaultDatas.serviceGUID &&
                            defaultDatas.serviceGUID != "null"
                              ? defaultDatas.serviceGUID
                              : ""
                          }
                          onChange={handleServiceChange}
                          MenuProps={{
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                            PaperProps: {
                              style: menuStyles,
                            },
                          }}
                        >
                          {serviceData.length > 0 &&
                            serviceData.map((service: any) => (
                              <MenuItem
                                key={service.serviceGUID}
                                value={service.serviceGUID}
                              >
                                {service.name}
                              </MenuItem>
                            ))}
                        </Select>
                      </Box>
                    </Box>
                  </div>
                </div>
              </Box>

              <Box className="d-flex gap-16 mt-3 w-100 flex-col align-items-start pb-32">
                <div className="width50">
                  <Typography
                    variant="h5"
                    color="primary"
                    className="ml-8 mt-4"
                  >
                    3. Package Information
                  </Typography>

                  {/* package information section */}

                  <Box className="d-flex flex-column mt-4 w-100 border p16 rounded">
                    <Typography variant="h6" className="Sub-header">
                      Crate Details
                    </Typography>
                    <Grid container spacing={2}>
                      {crateDetails.map((crate: any, index: any) => (
                        <React.Fragment key={index}>
                          <Grid
                            item
                            xs={isSubmitted ? 4.5 : 6}
                            md={isSubmitted ? 4.5 : 6}
                          >
                            <TextField
                              id="txtMfCrate"
                              className="Margin0"
                              label="Crate #"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              name="reference2"
                              value={crate.reference2}
                              onChange={(e) => handleCrateChange(index, e)}
                              inputProps={{ maxLength: 100 }}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={isSubmitted ? 3 : 6}
                            md={isSubmitted ? 3 : 6}
                          >
                            <TextField
                              id="txtMfWeight"
                              className="Margin0"
                              label="Weight"
                              variant="outlined"
                              fullWidth
                              margin="normal"
                              name="weight"
                              value={crate.weight}
                              onKeyDown={handleDecimalValue}
                              onBlur={(e) => handleDecimalValueBlur(e, index)}
                              onChange={(e) => handleCrateChange(index, e)}
                              inputProps={{ maxLength: 4 }}
                            />
                          </Grid>
                          {isSubmitted && (
                            <Grid item xs={4.5} md={4.5}>
                              <TextField
                                id="txtMfOrder"
                                className="Margin0"
                                label="Order #"
                                variant="outlined"
                                fullWidth
                                margin="normal"
                                name="order"
                                value={crate.order}
                              />
                            </Grid>
                          )}
                        </React.Fragment>
                      ))}
                    </Grid>

                    {!isSubmitted && (
                      <div className="">
                        <Button
                          id="btnAddCrate"
                          variant="outlined"
                          className="d-flex rounded mt-16 pull-right"
                          onClick={handleAddMore}
                          disabled={crateDetails.length >= 24}
                        >
                          ADD CRATE
                          <span className="pl-8">
                            <AddIcon className="Add-icon" />
                          </span>
                        </Button>
                      </div>
                    )}
                  </Box>
                </div>
                {/* <Divider className="mt-5 w-100" /> */}
                <div className="width50">
                  <Typography
                    variant="h5"
                    color="primary"
                    className="ml-8 mt-4 mb-24"
                  >
                    4. Shipper Information
                  </Typography>
                  <Box className="d-flex flex-column w-100 border p16 rounded">
                    <Typography variant="h6" className="Sub-header">
                      Shipper Information
                    </Typography>
                    <Grid spacing={2} className="d-flex w-100 gap-3">
                      <Grid item xs={6} md={4} className="d-flex w-100">
                        <TextField
                          id="txtShipperName"
                          className="Margin0"
                          label="Shipper Information"
                          variant="outlined"
                          name="shipperName"
                          fullWidth
                          margin="normal"
                          error={
                            multiOrderSubmitError.shipperName &&
                            !!!multiOrderData.shipperName
                          }
                          value={multiOrderData.shipperName}
                          onChange={handleChange}
                          inputProps={{ maxLength: 50 }}
                        />
                      </Grid>
                      <Grid item xs={6} md={4} className="d-flex w-100">
                        <TextField
                          className="Margin0"
                          label="Phone Number"
                          id="txtShipperPhonenumber"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          name="shipperPhone"
                          value={multiOrderData.shipperPhone}
                          onChange={handleChange}
                          inputProps={{ maxLength: 25 }}
                        />
                      </Grid>
                    </Grid>
                    <Grid className="mt-16">
                      <Grid item xs={6} md={4} className="d-flex w-100">
                        <TextField
                          className="Margin0"
                          label="Email"
                          id="txtShipperEmail"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          name="shipperEmail"
                          error={
                            multiOrderSubmitError.shipperEmail &&
                            !!!multiOrderData.shipperEmail
                          }
                          value={multiOrderData.shipperEmail}
                          onChange={handleChange}
                          inputProps={{ maxLength: 100 }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </div>
              </Box>
            </Box>
          ) : (
            <Box>
              <Loader />
            </Box>
          )}
        </Box>
      </div>
      {!isSubmitted && (
        <div className="container-fluid">
          <Box className="d-flex justify-content-end gap-2 w-100 Divider-top pt-16 pb-16">
            <LoadingButton
              id="btnBookOrders"
              variant="contained"
              color="primary"
              loading={isLoadingBtn}
              loadingPosition="end"
              className="d-flex rounded me-2 width-min-100"
              endIcon={<img src={arrowicon} alt="icon right" />}
              onClick={handleSubmit}
            >
              BOOK ORDERS
            </LoadingButton>
          </Box>
        </div>
      )}
    </>
  );
};

export default MultiOrder;
