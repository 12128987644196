import React, { useCallback, useEffect, useState } from "react";
import logo from "../../assets/images/logo/mxweb-logo.svg";
import { Button, Container, Box, Typography } from "@mui/material";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import useTermsAndConditionsLogic from "./terms-and-conditions.logic";
import { useDecodeJWT } from "../../shared/hook/use-decodeJWT";

type OutletContextType = [(show: boolean) => void];

const TermsAndConditions: React.FC = () => {
  const navigate = useNavigate();
  // const [isLoading, setIsLoading] = useState(false);
  const { termsAndConditionsError, getTermsAndConditions, agreedToTerms } =
    useTermsAndConditionsLogic();
  //const [errors, setErrors] = useState<string>("");
  const [termsAndConditionsData, setTermsAndConditionsData] = useState<{
    htmlData: string;
    revisionDate: any;
  }>({ htmlData: "", revisionDate: "" });
  const token = useDecodeJWT();
  // const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [setShowHeader] = useOutletContext<OutletContextType>();

  useEffect(() => {
    setShowHeader(false);
    return () => setShowHeader(true); // Reset header visibility on unmount
  }, [setShowHeader]);

  const getTermsAndConditionsData = useCallback(async () => {
    try {
      // setIsLoading(true);
      const response = await getTermsAndConditions();
      if (response) {
        if (response.data && response.data.success) {
          setTermsAndConditionsData({
            htmlData: response.data.data.htmlData.replace(
              "<h2>CONDITIONS OF CONTRACT</h2>",
              ""
            ),
            revisionDate: response.data.data.revisionDate,
          });
          // setErrors("");
        } else if (response.message) {
          // setErrors(response.message);
        }
      } else if (termsAndConditionsError) {
        // setErrors(termsAndConditionsError);
      }
      // setIsLoading(false);
    } catch (err: any) {
      // setErrors(err);
      // setTimeout(() => {
      //   setErrorAlert(false);
      // }, 5000);
    } finally {
      // setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    getTermsAndConditionsData();
  }, []);

  const handleDoNotAgreeButton = () => {
    localStorage.clear();
    // navigate("/");
    window.location.href = "/";
  };

  const handleAgreeButton = async () => {
    try {
      const now = new Date();
      const param = {
        userID: Number(token.UserId),
        revisionDate: now.toISOString(),
      };
      // setIsLoadingBtn(true);
      const response = await agreedToTerms(param);
      if (response && response.data) {
        navigate("/active-orders");
      } else if (termsAndConditionsError) {
        // setErrors(termsAndConditionsError);
      }
    } catch (err: any) {
      //setIsLoadingBtn(false);
      // setErrors(err);
      // setTimeout(() => {
      //   setErrorAlert(false);
      // }, 5000);
    } finally {
      //setIsLoadingBtn(false);
    }
  };

  // const handlePrivacyStatementClick = () => {
  //   navigate("/privacy-statement");
  // };

  // const handleSupportSignIn = () => {
  //   navigate("/support-sign-in");
  // };

  const setDateTimeFormat = (datetime: string) => {
    const date = new Date(datetime);

    // Array of weekday and month names
    const weekdays = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const months = [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ];

    // Extract the day, month, date, and year
    const weekday = weekdays[date.getDay()];
    const day = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    // Format the date
    const formattedDate = `${weekday} ${day}-${month}-${year}`;
    return formattedDate;
  };

  return (
    <div
      className="mlr-16"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <Container className="login-section" maxWidth="md" sx={{ mt: 4 }}>
        {/* Logo */}
        <Box className="lognin-image">
          <img src={logo} alt="Company Logo" style={{ maxWidth: "100%" }} />
        </Box>

        <Box>
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            textAlign="start"
            className="terms-and-conditions-statement p-3"
          >
            You must agree to the following terms and conditions to continue.
            Please read the terms and conditions, and press the “I Agree” or “I
            Do Not Agree” button at the bottom of the page.
          </Typography>
        </Box>

        <Box className="Forgot-password-form-border mb-16">
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            textAlign="start"
            className="sign-in-title"
          >
            Conditions of Contract
          </Typography>

          <Box className="p-2 m-3 border-grey rounded overflow-auto condition-content">
            <Typography
              variant="h5"
              component="h1"
              gutterBottom
              textAlign="start"
            >
              {termsAndConditionsData.htmlData && (
                <Box
                  className="terms-content"
                  dangerouslySetInnerHTML={{
                    __html: termsAndConditionsData.htmlData,
                  }}
                  sx={{ fontSize: "16px" }}
                ></Box>
              )}
              {termsAndConditionsData.revisionDate && (
                <Box
                  dangerouslySetInnerHTML={{
                    __html: setDateTimeFormat(
                      termsAndConditionsData.revisionDate
                    ),
                  }}
                  sx={{ fontSize: "16px" }}
                ></Box>
              )}
            </Typography>
          </Box>

          <Box className="d-flex m-3">
            <Button
              onClick={handleDoNotAgreeButton}
              variant="outlined"
              color="primary"
              className="mr-16 btn-50 blue-btn rounded-3"
              style={{ whiteSpace: "nowrap" }}
            >
              I DO NOT AGREE
            </Button>
            <Button
              onClick={handleAgreeButton}
              variant="contained"
              className="btn-50 blue-btn rounded-3"
              color="primary"
              style={{ whiteSpace: "nowrap" }}
            >
              I AGREE
            </Button>
          </Box>
        </Box>
        {/* Other links */}
        {/* <Box>
          <Box
            className="Privacy_info forgot_btn"
            maxWidth="md"
            sx={{
              display: "flex",
              gap: "64px",
              width: "100%",
              marginTop: "auto",
            }}
          >
            <Button className="text" onClick={handlePrivacyStatementClick}>
              Privacy Statement
            </Button>
            <Button className="text-custom" onClick={handleSupportSignIn}>
              Support login
            </Button>
          </Box>
        </Box> */}
      </Container>
    </div>
  );
};

export default TermsAndConditions;
