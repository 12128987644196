import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import logo from "../../assets/images/logo/mnx_logo_login.svg";
import { Link, useNavigate } from "react-router-dom";


interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
}

// const drawerWidth = 240;
const navItems = ["SIGN IN"];

const AuthHeader: React.FC = (props: Props) => {
  // const { window } = props;
  // const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const toPascalCase = (str: any) => {
    return str
      .replace(
        /\w\S*/g,
        (word: any) =>
          word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
      )
      .replace(/\s+/g, "");
  };

  // const handleDrawerToggle = () => {
  //   setMobileOpen((prevState) => !prevState);
  // };

  // const handleLogout = () => {
  //   localStorage.removeItem("token");
  //   navigate("/");
  // };

  // const drawer = (
  //   <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
  //     <Box className="lognin-image left-align loginlogo">
  //       <img
  //         src={logo}
  //         alt="Company Logo"
  //         style={{ width: "80px", margin: "10px" }}
  //       />
  //     </Box>
  //     <Divider />
  //     <List>
  //       {navItems.map((item) => (
  //         <ListItem key={item} disablePadding>
  //           <ListItemButton sx={{ textAlign: "center" }}>
  //             <ListItemText primary={item} />
  //           </ListItemButton>
  //         </ListItem>
  //       ))}
  //     </List>
  //   </Box>
  // );

  // const container =
  //   window !== undefined ? () => window().document.body : undefined;

  return (
    <div className="Top-nav Header-bar">
      <CssBaseline />
      <AppBar component="nav" className="header">
        <Toolbar className="login-header">
          {/* <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton> */}
          <Box
            className="lognin-image left-align loginlogo"
            component="div"
            sx={{ flexGrow: 1, display: { sm: "block" } }}
          >
            <Link to="/">
              <img src={logo} alt="Company Logo" />
            </Link>
          </Box>
          <Box sx={{ display: { sm: "block" } }}>
            {navItems.map((item) => (
              <Button
                className="Menu-item nav_btn"
                key={item}
                onClick={() => navigate("/")}
                id={`btn${toPascalCase(item)}`}
              >
                {item}
              </Button>
            ))}
            {/* <Button onClick={handleLogout}>LOGOUT</Button> */}
          </Box>
          <Button
            className="Registerbtn"
            onClick={() => navigate("/sign-up")}
            id="btnRegister"
          >
            Register
          </Button>
          {/* <IconButton size="large" aria-label="search" color="inherit">
            <SearchIcon />
          </IconButton> */}
        </Toolbar>
      </AppBar>
      {/* <nav className="header" style={{ borderBottom: "1px #046386 solid" }}>
        <Drawer
          container={container}
          color="white"
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav> */}
    </div>
  );
};

export default AuthHeader;
