import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../types/global";
import {
  searchOrders,
  deleteSavedSearch,
  saveSearch,
  savedSearchList,
} from "./search-orders.reducer";
import { saveSearchRequestDTO } from "../../models/search-order-item-dto";

const useSearchOrdersLogic = () => {
  const dispatch = useDispatch();
  const { searchOrderLoading, searchOrderError } = useSelector(
    (state: RootState) => state.searchOrders
  );

  useEffect(() => {
    if (searchOrderError) {
      // dispatch(showNotification('Active Order failed: ' + error, 'error'));
    }
  }, [searchOrderError, dispatch]);

  const handleSearchOrders = async (param: {
    accountNumber: string;
    startDate?: string | null;
    endDate?: string | null;
    trackingNumber?: string;
    contents?: string;
    pickupCityName?: string;
    pickupCountryId?: string;
    deliveryCityName?: string;
    deliveryCountryId?: string;
    reference?: string;
    reference2?: string;
    reference3?: string;
    reference4?: string;
    reference5?: string;
    referenceTypeId?: any;
    referenceTypeId2?: any;
    referenceTypeId3?: any;
    referenceTypeId4?: any;
    referenceTypeId5?: any;
    invoiceNumber?: any;
    orderByName?: string;
    orderStatus?: string;
    pickupPostalCode?: string;
    pickupStateProvId?: string;
    deliveryPostalCode?: string;
    deliveryStateProvId?: string;
    referenceTypeId6?: any;
    referenceTypeId7?: any;
    referenceTypeId8?: any;
    referenceTypeId9?: any;
    orderBy?: string;
    sorting?: string;
    pageSize?: any;
    pageNo?: any;
    userId?: any;
    userGUID?: any;
  }) => {
    try {
      const response = await dispatch(searchOrders(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  const handleDeleteSavedSearch = async (param: {
    CriteriaGUID: string | undefined;
  }) => {
    try {
      const response = await dispatch(deleteSavedSearch(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };
  const handleSaveSearch = async (param: saveSearchRequestDTO) => {
    try {
      const response = await dispatch(saveSearch(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };
  const handleSavedSearchList = async (param: any) => {
    try {
      const response = await dispatch(savedSearchList(param) as any);
      return response;
    } catch (err) {
      throw err;
    }
  };

  return {
    searchOrderLoading,
    searchOrderError,
    searchOrders: handleSearchOrders,
    deleteSavedSearch: handleDeleteSavedSearch,
    saveSearch: handleSaveSearch,
    savedSearchList: handleSavedSearchList,
  };
};

export default useSearchOrdersLogic;
