import { Box, Button,  TextField, Typography } from "@mui/material";
import React from "react";

function TrackActiveOrder() {
  return (
    <div className="container-fluid active-order">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          <Typography variant="h4" color={"primary"}>
            TRACK AN ORDER
          </Typography>
        </Box>
        <Box>
          <div className="d-flex justify-content-center align-item-center">
            <TextField
              id="txtUserId"
              name="LogonName"
              label="Tracking Number"
              variant="outlined"
              size="small"
              className="Txtbx"
              fullWidth
              margin="normal"
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              TRACK
            </Button>
          </div>
        </Box>
      </Box>
    </div>
  );
}

export default TrackActiveOrder;
