import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState, useCallback } from "react";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import CommodityListDialog from "../commodity-list-dialog/commodity-list-dialog";
import { UnDetailsItemDto } from "../../../models/long-form-item-dto";

import useSearchUNDetailsLogic from "../../lookup/searchUn-details/searchUNDetails.logic";
import { MAX_COMPANY_ID } from "../../../config/constants";
import { handleNumericValue } from "../../util/numeric-value";
import CustomDatePicker from "../CustomDatePicker";
interface DangerousGoodsProps {
  onDangerousGoodDataChang: (updatedData: any) => void;
  formErrors: any;
  setFormErrors: (errors: any) => void;
  onUnNumberChange: (errors: any) => void;
  handleInputChange: (e: any) => void;
  setPiecesData: (errors: any) => void;
  editWillCallOrderData: any;
  dangerousGoodDataProps: any;
  piecesData?: any;
}

interface DangerousGoodsState {
  isDangerousGoods: boolean;
  dgIsRadioactive: boolean;
  dgUNNumber?: any;
  dgCommodityClass?: string;
  dgProperName?: string;
  dgTransportationIndex?: string;
  dgTransportationLimit?: string;
  carrierToProvideDryIce: boolean;
  carrierToProvideGelPack: boolean;
  dgDryIceWeight: number | undefined;
  dgGelPackCount: number | undefined;
  dgIsDryIceProvide: boolean;
  dgPackingGroup?: string;
  dgQuantityAndPackType?: string;
  dgPackingInst?: string;
  dgAuthorization?: string;
  dgEmergencyPhone?: string;
  dgSignatory?: string;
  dgDate?: any;
  dgHandlingInfo?: string;
}

const DangerousGoods: React.FC<DangerousGoodsProps> = ({
  onDangerousGoodDataChang,
  formErrors,
  setFormErrors,
  onUnNumberChange,
  handleInputChange: handleInputChangeCallBack,
  setPiecesData,
  editWillCallOrderData,
  dangerousGoodDataProps,
  piecesData,
}) => {
  const [open, setOpen] = useState(false);
  const [isDangerousGoods, setIsDangerousGoods] = useState(false);
  const [isRedioActive, setIsRedioActive] = useState(false);
  const [isDryIceRequire, setIsDryIceRequire] = useState(false);
  const [isGelPacksRequire, setIsGelPacksRequire] = useState(false);
  const { searchUNDetails } = useSearchUNDetailsLogic();
  const [dangerousGoodsData, setDangerousGoodsData] =
    useState<DangerousGoodsState>({
      isDangerousGoods: false,
      dgIsRadioactive: false,
      dgUNNumber: "",
      dgCommodityClass: "",
      dgProperName: "",
      dgTransportationIndex: "",
      dgTransportationLimit: "",
      carrierToProvideDryIce: false,
      carrierToProvideGelPack: false,
      dgDryIceWeight: undefined,
      dgGelPackCount: undefined,
      dgIsDryIceProvide: false,
      dgPackingGroup: "",
      dgQuantityAndPackType: "",
      dgPackingInst: "",
      dgAuthorization: "",
      dgEmergencyPhone: "",
      dgSignatory: "",
      dgDate: "",
      dgHandlingInfo: "",
    });
  const errorControls = [
    {
      name: "dgEmergencyPhone",
      value: "dgEmergencyPhone",
    },
  ];

  useEffect(() => {
    if (editWillCallOrderData) {
      setDangerousGoodsData({
        isDangerousGoods: editWillCallOrderData.isDangerousGoods,
        dgIsRadioactive: editWillCallOrderData.dgIsRadioactive,
        dgUNNumber: editWillCallOrderData.dgUNNumber,
        dgCommodityClass: editWillCallOrderData.dgCommodityClass,
        dgProperName: editWillCallOrderData.dgProperName,
        dgTransportationIndex: editWillCallOrderData.dgTransportationIndex,
        dgTransportationLimit: editWillCallOrderData.dgTransportationLimit,
        dgIsDryIceProvide: editWillCallOrderData.carrierToProvideDryIce,
        carrierToProvideGelPack: editWillCallOrderData.carrierToProvideGelPack,
        dgDryIceWeight: editWillCallOrderData.dgDryIceWeight
          ? editWillCallOrderData.dgDryIceWeight
          : null,
        dgGelPackCount: editWillCallOrderData.dgGelPackCount,
        carrierToProvideDryIce: editWillCallOrderData.carrierToProvideDryIce,
        dgPackingGroup: editWillCallOrderData.dgPackingGroup,
        dgQuantityAndPackType: editWillCallOrderData.dgQuantityAndPackType,
        dgPackingInst: editWillCallOrderData.dgPackingInst,
        dgAuthorization: editWillCallOrderData.dgAuthorization,
        dgEmergencyPhone: editWillCallOrderData.dgEmergencyPhone,
        dgSignatory: editWillCallOrderData.dgSignatory,
        dgDate: editWillCallOrderData.dgDate,
        dgHandlingInfo: editWillCallOrderData.dgHandlingInfo,
      });

      setIsDangerousGoods(editWillCallOrderData.isDangerousGoods);
      setIsRedioActive(editWillCallOrderData.dgIsRadioactive);
      setIsDryIceRequire(editWillCallOrderData.carrierToProvideDryIce);
      setIsGelPacksRequire(editWillCallOrderData.carrierToProvideGelPack);
    }
  }, [editWillCallOrderData]);

  useEffect(() => {
    if (dangerousGoodDataProps) {
      setIsDangerousGoods(
        dangerousGoodDataProps.isDangerousGoods != undefined
          ? dangerousGoodDataProps.isDangerousGoods
          : dangerousGoodsData.isDangerousGoods
      );
      setIsRedioActive(
        dangerousGoodDataProps.isRadioactive != undefined
          ? dangerousGoodDataProps.isRadioactive
          : dangerousGoodsData.dgIsRadioactive
      );
      setDangerousGoodsData((prevState) => ({
        ...prevState,

        isDangerousGoods:
          dangerousGoodDataProps.isDangerousGoods != undefined
            ? dangerousGoodDataProps.isDangerousGoods
            : dangerousGoodsData.isDangerousGoods,
        dgIsRadioactive:
          dangerousGoodDataProps.isRadioactive != undefined
            ? dangerousGoodDataProps.isRadioactive
            : dangerousGoodsData.dgIsRadioactive,
      }));
    }
  }, [dangerousGoodDataProps]);

  useCallback(() => {
    if (piecesData) {
      setDangerousGoodsData((prevState) => ({
        ...prevState,
        isDangerousGoods: piecesData[0]?.isDangerousGoods,
        dgIsRadioactive: piecesData[0]?.isRadioactive,
        dgUNNumber: piecesData[0]?.dgUNNumber,
        dgCommodityClass: piecesData[0]?.dgCommodityClass,
        dgProperName: piecesData[0]?.dgProperName,
        dgDryIceWeight: piecesData[0]?.dgDryIceWeight,
        dgGelPackCount: piecesData[0]?.dgGelPackCount,
        dgPackingGroup: piecesData[0]?.dgPackingGroup,
        dgQuantityAndPackType: piecesData[0]?.dgQuantityAndPackType,
        dgPackingInst: piecesData[0]?.dgPackingInstr,
        dgAuthorization: piecesData[0]?.dgAuthorization,
        dgIsGelPackProvide: piecesData[0]?.carrierToProvideGelPack ?? false,
        carrierToProvideDryIce: piecesData[0]?.carrierToProvideDryIce ?? false,
        isDryIceRequire: piecesData[0]?.isDryIceRequire ?? false,
        isGelPacksRequire: piecesData[0]?.isGelPacksRequire ?? false,
      }));
      setIsDangerousGoods(piecesData[0]?.isDangerousGoods ?? false);
      setIsRedioActive(piecesData[0]?.isRadioactive ?? false);
      setIsDryIceRequire(piecesData[0]?.isDryIceRequire ?? false);
      setIsGelPacksRequire(piecesData[0]?.isGelPacksRequire ?? false);
    }
  }, [piecesData]);
  const updateDangerousGoodsData = (
    updatedFields: Partial<DangerousGoodsState>
  ) => {
    const updatedData: any = { ...dangerousGoodsData, ...updatedFields };
    setDangerousGoodsData(updatedData);
    onDangerousGoodDataChang(updatedData);
    setIsDangerousGoods(updatedData.isDangerousGoods);
    setIsRedioActive(updatedData.dgIsRadioactive);
    setIsDryIceRequire(updatedData.carrierToProvideDryIce);
    setIsGelPacksRequire(updatedData.carrierToProvideGelPack);
    setPiecesData((prevData: any) => {
      // Ensure prevData is an array and has at least one item
      if (!prevData || !Array.isArray(prevData) || prevData.length === 0) {
        return prevData; // Return unchanged data if it's invalid
      }
      // Create a copy of the previous data
      const updatedPiecesData = [...prevData];
      // Check if the first item exists
      if (!updatedPiecesData[0]) {
        return updatedPiecesData; // Return unchanged data if the first item is undefined
      }
      // Update the first item in the piecesData array with the new dangerous goods data
      updatedPiecesData[0] = {
        ...updatedPiecesData[0], // Preserve existing data
        isRadioactive: updatedData.dgIsRadioactive,
        isDangerousGoods: updatedData.isDangerousGoods,
        carrierToProvideDryIce: updatedData.dgIsDryIceProvide,
        carrierToProvideGelPack: updatedData.dgIsGelPackProvide,
        isDryIceRequire: updatedData.isDryIceRequire,
        isGelPacksRequire: updatedData.isGelPacksRequire,
        ...updatedFields, // Overwrite with new fields from dangerousGoodsData
      };
      return updatedPiecesData;
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDangerousGoodsData((prev) => ({ ...prev, [name]: value }));
    //onDangerousGoodDataChang({ [name]: value });
    onDangerousGoodDataChang((prev: any) => ({
      ...prev,
      [name]: value,
      isDangerousGoods: dangerousGoodsData.isDangerousGoods,
      dgIsRadioactive: dangerousGoodsData.dgIsRadioactive,
    }));
    handleInputChangeCallBack(e);
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange) {
      handleError(name);
    }
  };

  const handleError = (name: string) => {
    let updatedName = name;
    let errorChange: any = errorControls.find((ec) => ec.name === name);
    if (errorChange.name === updatedName) {
      updatedName = errorChange.value;
      if (formErrors[updatedName]) {
        setFormErrors((prevErrors: any) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[updatedName];
          return updatedErrors;
        });
      }
    }
  };

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    // Creating an HTMLInputElement-like event object
    const { name, value } = event.target;
    const syntheticEvent = {
      target: {
        name: name,
        value: value,
      },
    } as ChangeEvent<HTMLInputElement>;
    handleInputChange(syntheticEvent);
    handleInputChangeCallBack(event);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setDangerousGoodsData((prevState) => ({
      ...prevState,
      [name]: checked,
    }));
    //onDangerousGoodDataChang({ [name]: checked });
    onDangerousGoodDataChang((prev: any) => ({
      ...prev,
      [name]: checked,
      isDangerousGoods: dangerousGoodsData.isDangerousGoods,
      dgIsRadioactive: dangerousGoodsData.dgIsRadioactive,
    }));
    handleInputChangeCallBack(event);
  };

  const onSelectCommodity = (commodity: UnDetailsItemDto) => {
    setDangerousGoodsData((prevState) => ({
      ...prevState,
      dgUNNumber: commodity.unNumber ? commodity.unNumber : "",
      dgProperName: commodity.properName ? commodity.properName : "",
      dgCommodityClass: commodity.commodityClass
        ? commodity.commodityClass
        : "",
      isDangerousGoods: commodity.isDangerousGoods,
      dgIsRadioactive: commodity.isRadioactive,
    }));
    setIsDangerousGoods(commodity.isDangerousGoods);
    setIsRedioActive(commodity.isRadioactive);

    onDangerousGoodDataChang({
      dgProperName: commodity.properName ? commodity.properName : "",
      dgUNNumber: commodity.unNumber ? commodity.unNumber : "",
      dgCommodityClass: commodity.commodityClass
        ? commodity.commodityClass
        : "",
      isDangerousGoods: commodity.isDangerousGoods,
      dgIsRadioactive: commodity.isRadioactive,
    });
  };

  const checkUNDetails = async () => {
    try {
      const param = {
        companyID: MAX_COMPANY_ID,
        unNumber: dangerousGoodsData.dgUNNumber,
      };
      const response = await searchUNDetails(param);
      if (response.data && response.data.data) {
        const res = response.data.data;
        onUnNumberChange(res);
        if (res.length > 0) {
          setDangerousGoodsData((prevLongFormData) => ({
            ...prevLongFormData,
            dgProperName: res[0].properName,
          }));
          onDangerousGoodDataChang((prevLongFormData: any) => ({
            ...prevLongFormData,
            dgProperName: res[0].properName,
          }));
          return res[0];
        }
      }
    } catch {}
  };

  const handleUnNumberKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && dangerousGoodsData.dgUNNumber) {
      try {
        const res = await checkUNDetails(); // Wait for getZipCode to complete
        if (res) {
          onDangerousGoodDataChang({
            ...dangerousGoodsData,
            dgProperName: res.properName,
          });
        }
      } catch {}
    }
  };

  const handleUNNumberFocusOut = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    try {
      if (dangerousGoodsData.dgUNNumber) {
        const res = await checkUNDetails(); // Wait for getZipCode to complete
        if (res) {
          onDangerousGoodDataChang({
            ...dangerousGoodsData,
            dgProperName: res.properName,
          });
        }
      }
    } catch {}
  };

  return (
    <Box className="d-flex gap-16 mt-3 w-100 Divider pb-32 align-flex-start flex-col">
      <Grid container spacing={2}>
        <Grid item lg={6}>
          <Box className="d-flex flex-column w-100 border p16 rounded">
            <Grid container>
              <Grid item lg={12}>
                <Typography variant="h6" className="Sub-header">
                  Dangerous Goods
                </Typography>
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className="d-flex w-100 mb-24">
                  <FormControl component="fieldset" className="w-100">
                    <FormLabel
                      component="legend"
                      className="font14 weight-500 mb-8"
                    >
                      Does the shipment contain dangerous goods?
                    </FormLabel>
                    <ButtonGroup className="width100px">
                      <Button
                        variant={isDangerousGoods ? "contained" : "outlined"}
                        className="w-100 rounded-left-only mt-8"
                        onClick={() =>
                          updateDangerousGoodsData({ isDangerousGoods: true })
                        }
                        id="btnIsDangerousGoodsYes"
                      >
                        YES
                      </Button>
                      <Button
                        variant={!isDangerousGoods ? "contained" : "outlined"}
                        className="w-100 rounded-right-only mt-8"
                        onClick={() =>
                          updateDangerousGoodsData({ isDangerousGoods: false })
                        }
                        id="btnIsDangerousGoodsNo"
                      >
                        NO
                      </Button>
                    </ButtonGroup>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="d-flex w-100 mb-24">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel
                    component="legend"
                    className="font14 weight-500 mb-0"
                  >
                    Does the shipment contain radioactive material?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      variant={isRedioActive ? "contained" : "outlined"}
                      className="w-100 rounded-left-only mt-2"
                      onClick={() =>
                        updateDangerousGoodsData({ dgIsRadioactive: true })
                      }
                      id="btnIsRedioActiveYes"
                    >
                      YES
                    </Button>
                    <Button
                      variant={!isRedioActive ? "contained" : "outlined"}
                      className="w-100 rounded-right-only mt-2"
                      onClick={() =>
                        updateDangerousGoodsData({ dgIsRadioactive: false })
                      }
                      id="btnIsRedioActiveNo"
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                </FormControl>
              </Box>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} className="mb-24">
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label="UN #"
                    variant="outlined"
                    name="dgUNNumber"
                    value={dangerousGoodsData.dgUNNumber}
                    onChange={handleInputChange}
                    onKeyDown={handleUnNumberKeyDown}
                    onBlur={handleUNNumberFocusOut}
                    id="txtUnNumber"
                    inputProps={{ maxLength: 10 }}
                    className="w-100"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleClickOpen} id="btnDgAdd">
                            <AddIcon />
                          </IconButton>
                          <CommodityListDialog
                            open={open}
                            handleClose={handleClose}
                            onSelectCommodity={onSelectCommodity}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    className="w-100"
                    name="dgProperName"
                    id="txtPropername"
                    value={dangerousGoodsData.dgProperName}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={6}>
                  <TextField
                    label="Class"
                    variant="outlined"
                    className="w-100"
                    id="txtCommodityClass"
                    name="dgCommodityClass"
                    value={dangerousGoodsData.dgCommodityClass}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 10 }}
                  />
                </Grid>
                <Grid item lg={4} md={4} xs={6}>
                  <TextField
                    label="Trans IDX"
                    variant="outlined"
                    className="w-100"
                    id="txtTtransportationIndex"
                    name="dgTransportationIndex"
                    value={dangerousGoodsData.dgTransportationIndex}
                    inputProps={{
                      maxLength: 10,
                    }}
                    onChange={handleInputChange}
                  />
                </Grid>

                <Grid item lg={4} md={4} sm={6} xs={6}>
                  <FormControl className="w-100">
                    <InputLabel>Limits</InputLabel>
                    <Select
                      label="Limits"
                      variant="outlined"
                      className="w-100"
                      id="ddTransportationLimit"
                      name="dgTransportationLimit"
                      value={dangerousGoodsData.dgTransportationLimit}
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="P" id="liDgPassengerAndCargo">
                        Passenger And Cargo Aircraft
                      </MenuItem>
                      <MenuItem value="C" id="liDgCargo">
                        Cargo Aircraft Only
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12} className="mb-24">
              <Box className="d-flex flex-column gap-2 w-100">
                <FormControl component="fieldset" className="w-100">
                  <FormLabel component="legend" className="font14 weight-500">
                    Does the shipment require dry ice?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      variant={isDryIceRequire ? "contained" : "outlined"}
                      className="w-100 rounded-left-only"
                      id="btnIsDryIceRequireYes"
                      onClick={() => {
                        setIsDryIceRequire(true);
                        updateDangerousGoodsData({
                          carrierToProvideDryIce: true,
                        });
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      variant={!isDryIceRequire ? "contained" : "outlined"}
                      className="w-100 rounded-right-only"
                      id="btnIsDryIceRequireNo"
                      onClick={() => {
                        setIsDryIceRequire(false);
                        updateDangerousGoodsData({
                          carrierToProvideDryIce: false,
                        });
                      }}
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                </FormControl>
                {isDryIceRequire && (
                  <Box className="d-flex flex-column gap-2 w-100">
                    <TextField
                      label="Amount of Dry ice"
                      variant="outlined"
                      className="w-100"
                      name="dgDryIceWeight"
                      id="txtDgDryIceWeight"
                      value={
                        dangerousGoodsData.dgDryIceWeight
                          ? dangerousGoodsData.dgDryIceWeight
                          : null
                      }
                      onChange={handleInputChange}
                      onKeyDown={handleNumericValue}
                      inputProps={{ maxLength: 9 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="carrierToProvideDryIce"
                          checked={dangerousGoodsData.carrierToProvideDryIce}
                          onChange={handleCheckboxChange}
                          id="cbCarrierToProvideDryIce"
                        />
                      }
                      label="LFS/MNX to provide"
                      className="p-2"
                    />
                  </Box>
                )}
              </Box>
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Box className="d-flex flex-column gap-2 w-100">
                <FormControl component="fieldset" className="w-100 mt-2">
                  <FormLabel component="legend" className="font14 weight-500">
                    Does the shipment require gel packs?
                  </FormLabel>
                  <ButtonGroup>
                    <Button
                      variant={isGelPacksRequire ? "contained" : "outlined"}
                      className="w-100 rounded-left-only"
                      id="btnIsGelPacksRequireYes"
                      onClick={() => {
                        setIsGelPacksRequire(true);
                        updateDangerousGoodsData({
                          carrierToProvideGelPack: true,
                        });
                      }}
                    >
                      YES
                    </Button>
                    <Button
                      variant={!isGelPacksRequire ? "contained" : "outlined"}
                      className="w-100 rounded-right-only"
                      id="btnIsGelPacksRequireNo"
                      onClick={() => {
                        setIsGelPacksRequire(false);
                        updateDangerousGoodsData({
                          carrierToProvideGelPack: false,
                        });
                      }}
                    >
                      NO
                    </Button>
                  </ButtonGroup>
                </FormControl>
                {isGelPacksRequire && (
                  <Box className="d-flex flex-column gap-2 w-100">
                    <TextField
                      label="Amount of Gel packs"
                      variant="outlined"
                      className="w-100 mt-3"
                      name="dgGelPackCount"
                      id="txtDgGelPackCount"
                      value={dangerousGoodsData.dgGelPackCount}
                      onChange={handleInputChange}
                      onKeyDown={handleNumericValue}
                      inputProps={{ maxLength: 4 }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="carrierToProvideGelPack"
                          checked={dangerousGoodsData.carrierToProvideGelPack}
                          onChange={handleCheckboxChange}
                          id="cbCarrierToProvideGelPack"
                        />
                      }
                      label="LFS/MNX to provide"
                      className="p-2"
                    />
                  </Box>
                )}
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Grid item lg={6} md={6} sm={12} xs={12}>
          <Box className="d-flex flex-column w-100 border p16 rounded">
            <Grid item lg={12}>
              <Typography variant="h6" className="Sub-header">
                Dangerous Goods Packing Details
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Packing Group"
                    className="w-100"
                    id="txtDgPackingGroup"
                    name="dgPackingGroup"
                    value={dangerousGoodsData.dgPackingGroup}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 300 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Qty & Type of Packing"
                    className="w-100"
                    id="txtDgQuantityAndPackType"
                    name="dgQuantityAndPackType"
                    value={dangerousGoodsData.dgQuantityAndPackType}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 300 }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                variant="outlined"
                label="Packing Instructions"
                placeholder="Enter any additional instructions"
                multiline
                rows={4}
                id="txtDgPackingInst"
                className="w-100 mt-16"
                name="dgPackingInst"
                value={dangerousGoodsData.dgPackingInst}
                onChange={handleInputChange}
                inputProps={{ maxLength: 100 }}
              />
            </Grid>

            <Grid item lg={12} md={12} sm={12} xs={12}>
              <TextField
                variant="outlined"
                label="Additional Handling Information"
                placeholder="Enter additional handling information instructions"
                multiline
                id="txtDgHandlingInfo"
                rows={4}
                className="w-100 mt-16"
                name="dgHandlingInfo"
                value={dangerousGoodsData.dgHandlingInfo}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 500,
                }}
              />
            </Grid>

            <Grid item lg={12} md={12} xs={12} className="mt-16">
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Authorization"
                    className="w-100"
                    name="dgAuthorization"
                    id="txtDgAuthorization"
                    value={dangerousGoodsData.dgAuthorization}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 40 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Emergency Phone #"
                    className="w-100"
                    name="dgEmergencyPhone"
                    id="txtDgEmergencyPhone"
                    value={dangerousGoodsData.dgEmergencyPhone}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 25 }}
                    // error={!!formErrors.dgEmergencyPhone}
                    // InputProps={{
                    //   endAdornment: !!formErrors.dgEmergencyPhone && (
                    //     <InputAdornment position="end" disablePointerEvents={true}>
                    //       <InfoRoundedIcon
                    //         style={{ color: red[500] }}
                    //         fontSize="small"
                    //       ></InfoRoundedIcon>
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={12} sm={12} xs={12} className="mt-16">
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <TextField
                    variant="outlined"
                    label="Title Signatory"
                    className="w-100"
                    name="dgSignatory"
                    id="txtDgSignatory"
                    value={dangerousGoodsData.dgSignatory}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 80 }}
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <CustomDatePicker
                    handleDateChange={handleInputChange as any}
                    label="Date"
                    id="txtDgDate"
                    name="dgDate"
                    value={dangerousGoodsData.dgDate}
                    isClearable={true}
                  />
                  {/* <TextField
                    type="date"
                    label="Date"
                    variant="outlined"
                    className="w-100"
                    InputLabelProps={{ shrink: true }}
                    name="dgDate"
                    value={dangerousGoodsData.dgDate}
                    onChange={handleInputChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton>
                            <img
                              src={calendarIcon}
                              alt="Calendar icon"
                              style={{ maxWidth: "100%" }}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  /> */}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DangerousGoods;
